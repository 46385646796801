import React, { useState, useEffect } from "react";
import { Stack, useMediaQuery } from "@mui/material";
import { FbAuth } from "../authentication/firebase";
import LoadingView from "./LoadingView";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { ensureAuthentication, rtmGetProfile } from "../core/api";

export default function PageSplashScreen({ children }: any) {
  const [initialized, setInitialized] = useState(false);
  const desktop = useMediaQuery("(min-width: 700px)");
  const [profile, setProfile] = useRecoilState(aProfile);

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      console.log(err);
    }
  }

  useEffect(() => {
    FbAuth.authStateReady().then(async () => {
      if (FbAuth.currentUser) {
        await ensureAuthentication();
        await loadProfile();
      }
      setInitialized(true);
    });
  }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack sx={{ height: "100vh", width: "100%", alignItems: "center" }}>
      <LoadingView />
    </Stack>
  );
}
