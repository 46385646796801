import {
  Button,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import DualHeading from "../../components/DualHeading";
import {
  IC_CRYPTO,
  IC_CUSTOMIZE,
  IC_DASHBOARD,
  IC_PAYMENT,
  IC_SHOPPING,
  IC_STRIPE,
  IC_SUBSCRIPTION,
  IC_TRIALS,
  IC_UNLOCK,
} from "../../assets/landing";
import { motion, useScroll } from "framer-motion";
import { IMG_AFFILIATION, IMG_BROADCAST } from "../../assets/ui";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router";

function FeatureContainer(props: any) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["center end", "start center"],
  });

  return (
    <motion.div
      ref={ref}
      style={{
        opacity: scrollYProgress,
      }}
    >
      {props.children}
    </motion.div>
  );
}

export default function Features() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const nav = useNavigate();

  return (
    <Stack
      id="features"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        minHeight: "100vh",
        pt: "140px",
        mb: "48px",
        maxWidth: "1024px",
        alignSelf: "center",
      }}
    >
      <Stack alignItems={"center"} spacing={"8px"}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <DualHeading
            component={"h2"}
            sx={{ fontSize: desktop ? "36px" : "24px" }}
            h1="Why Choose"
            h2="Cashifybot?"
            h2sx={{ color: "#48F" }}
          />
        </motion.div>
        <Typography
          sx={{
            fontSize: desktop ? 16 : 14,
            textAlign: "center",
            maxWidth: "70%",
          }}
        >
          Discover the exceptional features of Cashifybot outlined below.
        </Typography>
      </Stack>

      <Stack spacing={"96px"}>
        {/* Stripe  */}
        <Stack
          direction={desktop ? "row" : "column-reverse"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
            width: "100%",
          }}
          alignItems={"center"}
          spacing={desktop ? "48px" : "8px"}
        >
          <Stack>
            <SvgIcon
              sx={{
                width: desktop ? "128px" : "64px",
                height: desktop ? "128px" : "64px",
                p: 0,
                m: 0,
              }}
            >
              <IC_STRIPE />
            </SvgIcon>
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Powered by"
              h2="Stripe"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Cashifybot's platform is entirely powered by the stellar services
              of Stripe, particularly Stripe Connect. Users can seamlessly
              receive payments through their bank accounts, Stripe accounts, or
              various other payout methods supported by Stripe, all automated
              for your convenience.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              Cashifybot's platform doesn't retain any funds for users, and
              there's no manual withdrawal process. Once your Stripe Connected
              account is set up, you're good to go.
            </Typography>
          </Stack>
          <motion.img
            src={"https://i.imgur.com/vrhP2uF.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
            transition={{ duration: 1 }}
            whileHover={{ scale: desktop ? 1.5 : 1 }}
          />
        </Stack>

        {/* Crypto  */}
        <Stack
          direction={desktop ? "row-reverse" : "column-reverse"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
        >
          <Stack>
            <img
              src={IC_CRYPTO}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic crypto"
            />
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Accept payments in 20+"
              h2="crypto currencies"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Connected to the CryptoChill API, Cashifybot enables you to
              effortlessly accept payments globally in over 20+ major
              cryptocurrencies.
            </Typography>
            <br />
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              * Network and conversion fees apply.
            </Typography>
          </Stack>
          <motion.img
            src={"https://i.imgur.com/Wn54gY1.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
            transition={{ duration: 1 }}
            whileHover={{ scale: desktop ? 1.5 : 1 }}
          />
        </Stack>

        {/* Flexible Subscriptions  */}
        <Stack
          direction={desktop ? "row" : "column"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
          alignItems={"center"}
        >
          <motion.img
            src={"https://i.imgur.com/AqEwubD.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
          />
          <Stack>
            <img
              src={IC_SUBSCRIPTION}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic subscriptions"
            />
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Flexible"
              h2="Subscriptions"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Effortlessly design the pricing plans of your choice for end-users
              with our customizable subscription system.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              Cashifybot organizes subscription models seamlessly, generating
              dynamic Stripe subscriptions without cluttering your Stripe
              Dashboard. Enjoy the advantage of creating any number of custom
              subscription models hassle-free.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              Moreover, Cashifybot empowers you to establish one-time payment
              subscriptions effortlessly.
            </Typography>
          </Stack>
        </Stack>

        {/* Digital Assets */}
        <Stack
          direction={desktop ? "row-reverse" : "column"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
          alignItems={"center"}
        >
          <motion.img
            src={"https://i.imgur.com/WJlGeNQ.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
          />
          <Stack>
            <img
              src={IC_SHOPPING}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic shopping"
            />
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Sell Digital Assets"
              h2="Easily"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Effortlessly sell your digital assets, such as files, templates,
              even license keys, through telegram with our built-in store
              integration.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              Developers can leverage the Cashifybot's public API to integrate
              their apps and bots with the licensing features.
            </Typography>
          </Stack>
        </Stack>

        {/* Access Codes */}
        <Stack
          direction={desktop ? "row" : "column-reverse"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
          alignItems={"center"}
        >
          <Stack>
            <img
              src={IC_UNLOCK}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic codes"
            />
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Access"
              h2="Codes"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Generate personalized access codes to provide your users with free
              access to designated channels or groups.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              Cashifybot supports the creation of access codes for specific
              users, providing options to limit usage and set automatic
              expiration for enhanced control.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              Additionally, you can generate access codes that automatically
              remove users after a specified period. Utilize this feature to
              offer trial access to your users without any payment involvement.
            </Typography>
          </Stack>
          <motion.img
            src={"https://i.imgur.com/LzkcCvw.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
          />
        </Stack>

        {/* Trials */}
        <FeatureContainer>
          <Stack
            direction={desktop ? "row-reverse" : "column"}
            sx={{
              py: "12px",
              px: desktop ? 0 : "32px",
            }}
            spacing={desktop ? "48px" : "8px"}
          >
            <img
              src={IC_TRIALS}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic subscriptions"
            />
            <Stack>
              <DualHeading
                sx={{ fontSize: desktop ? "30px" : "24px" }}
                h1="Trial"
                h2="Access"
                h2sx={{ color: "#48F" }}
              />
              <Typography fontSize={desktop ? 16 : 14}>
                Offering trial access to your customers has never been easier.
              </Typography>
              <Typography fontSize={desktop ? 16 : 14}>
                Create a subscription with a trial period or generate a
                time-limited access code that automatically removes members when
                it expires. This enables your customers to easily enjoy trial
                access to your channels.
              </Typography>
            </Stack>
          </Stack>
        </FeatureContainer>

        {/* Broadcasting */}
        <Stack
          direction={desktop ? "row" : "column"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
          alignItems={"center"}
        >
          <motion.img
            src={"https://i.imgur.com/FXKJ7QK.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
            transition={{ duration: 1 }}
            whileHover={{ scale: desktop ? 1.5 : 1 }}
          />

          <Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: desktop ? "128px" : "64px",
                width: desktop ? "128px" : "64px",
              }}
            >
              <IMG_BROADCAST />
            </Stack>
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Expand Your Reach on Telegram with "
              h2="Broadcasts"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Reach out to users who have interacted with your bot and seize the
              opportunity to convert bounced users into paying customers.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              With our broadcast tools, you can precisely target subscribed
              users, individuals with access codes, or those who have engaged
              with your bot without joining your channels. Elevate your
              messaging strategy and amplify your impact with Cashifybot.
            </Typography>
          </Stack>
        </Stack>

        {/* Affiliate Tools */}
        <Stack
          direction={desktop ? "row-reverse" : "column"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
          alignItems={"center"}
        >
          <motion.img
            src={"https://i.imgur.com/hidc6Vc.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
            transition={{ duration: 1 }}
            whileHover={{ scale: desktop ? 1.5 : 1 }}
          />
          <Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: desktop ? "128px" : "64px",
                width: desktop ? "128px" : "64px",
              }}
            >
              <IMG_AFFILIATION />
            </Stack>
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="Grow your channels, "
              h2="faster!"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Empower your channel's growth with Cashifybot's powerful affiliate
              tools. Encourage your subscribers to refer new members and reward
              them for their efforts.
            </Typography>
            <br />
            <Typography fontSize={desktop ? 16 : 14}>
              With our affiliate program, you can incentivize user referrals,
              expand your channel's reach, and boost engagement like never
              before.
            </Typography>
          </Stack>
        </Stack>

        {/* Dashboard */}
        <Stack
          direction={desktop ? "row" : "column"}
          sx={{
            py: "12px",
            px: desktop ? 0 : "32px",
          }}
          spacing={desktop ? "48px" : "8px"}
          alignItems={"center"}
        >
          <motion.img
            src={"https://i.imgur.com/dMrF5L9.png"}
            style={{
              width: desktop ? "400px" : "100%",
              objectFit: "contain",
            }}
            transition={{ duration: 1 }}
            whileHover={{ scale: desktop ? 1.5 : 1 }}
          />
          <Stack>
            <img
              src={IC_DASHBOARD}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic dashboard"
            />
            <DualHeading
              sx={{ fontSize: desktop ? "30px" : "24px" }}
              h1="User-Friendly"
              h2="Dashboard"
              h2sx={{ color: "#48F" }}
            />
            <Typography fontSize={desktop ? 16 : 14}>
              Cashifybot's user-friendly dashboard makes managing your
              subscriptions and subscribers a breeze. With its intuitive design,
              you can effortlessly create custom subscription plans, monitor
              analytics, and handle payments—all in one place. The streamlined
              interface ensures you spend less time on administration and more
              time growing your business.
            </Typography>
          </Stack>
        </Stack>

        {/* Free  */}
        <FeatureContainer>
          <Stack
            direction={desktop ? "row-reverse" : "column"}
            sx={{
              py: "12px",
              px: desktop ? 0 : "32px",
            }}
            spacing={desktop ? "48px" : "8px"}
          >
            <img
              src={IC_PAYMENT}
              height={desktop ? 128 : 64}
              style={{ objectFit: "contain", alignSelf: "start" }}
              alt="ic payments"
            />
            <Stack>
              <DualHeading
                sx={{ fontSize: desktop ? "30px" : "24px" }}
                h1="Get started today, "
                h2="for free!"
                h2sx={{ color: "#48F" }}
              />
              <Typography fontSize={desktop ? 16 : 14}>
                No upfront payment is required to start using Cashifybot. We
                deduct a small percentage from your transactions, eliminating
                the need for concerns about platform fees.
              </Typography>
              <br />
              <Typography fontSize={desktop ? 16 : 14}>
                Please note that Stripe has its own fees associated with
                transfers, which will be deducted either from your final payout
                or directly from the transfers, depending on your account
                configuration.
              </Typography>
            </Stack>
          </Stack>
        </FeatureContainer>

        <Button
          endIcon={<ArrowForward />}
          variant="contained"
          onClick={() => nav("/signup")}
          size={desktop ? "large" : "small"}
          sx={{
            borderRadius: "25px",
            pointerEvents: "all",
            alignSelf: "center",
          }}
        >
          Get Started
        </Button>
      </Stack>
    </Stack>
  );
}
