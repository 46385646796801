import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_ALERT, IC_DELETE_CREDIT_CARD, IC_SETTINGS } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import { ArrowForward, Check, Close } from "@mui/icons-material";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import { useRecoilState } from "recoil";
import { aBotStatus, aProfile } from "../core/states";
import {
  rtmCreateStripeAccount,
  rtmFinishOnboarding,
  rtmGetBotStats,
  rtmGetCommand,
  rtmGetStripeProfile,
  rtmGetTelegramConfig,
  rtmSetCommand,
  rtmSetCurrency,
  rtmUnsetCommand,
  rtmUpdatePayments,
} from "../core/api/user";
import { enqueueSnackbar } from "notistack";
import { rtmGetProfile } from "../core/api";
import { TelegramConfig } from "../types/TelegramConfig";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";
import YouTubePlayer from "react-player/youtube";
import PFMInput from "../components/PFMInput";
import ReactPlayer from "react-player";
const whyChooseUs = [
  "Get paid quickly and securely with our Stripe and Crypto payments integration.",
  "Accept card payments in over 40+ countries, and crypto payments worldwide.",
  "Easy to use with powerful, user-friendly dashboard.",
  "Create unlimited subscriptions for any number of channels.",
  "You are charged per transactions only. No fixed charges for the starters. ",
  "Partner with Cashifybot and earn more through its Partnership Program.",
  "And so much more!",
];
export default function OnboardingDialog(props: DialogProps<any>) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const [CURRENCIES] = useState<AutocompleteOption[]>([
    {
      label: "USD $",
      id: "usd",
    },
    {
      label: "GBP £",
      id: "gbp",
    },
    {
      label: "Euro €",
      id: "eur",
    },
  ]);
  const [currency, setCurrency] = useState<AutocompleteOption>();
  const [busy, setBusy] = useState(false);
  const [telegramConnected, setTelegramConnected] = useState(false);
  const [token, setToken] = useState("");
  const [botStatus, setBotStatus] = useRecoilState(aBotStatus);
  const [config, setConfig] = useState<TelegramConfig>();
  const [stripeProfile, setStripeProfile] = useState<any>();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  // crypto
  const [network, setNetwork] = useState<AutocompleteOption>();
  const [coin, setCoin] = useState<AutocompleteOption>();
  const [address, setAddress] = useState("");
  const [selectedCoins, setSelectedCoins] = useState<
    { label: string; id: string; network: string }[]
  >([]);
  const [COINS, setCOINS] = useState<AutocompleteOption[]>([]);
  const [ALLCOINS] = useState<AutocompleteOption[]>([
    {
      label: "BTC",
      id: "BTC",
      network: "bitcoin",
    },
    // Ethereum
    {
      label: "ETH",
      id: "ETH",
      network: "ethereum",
    },
    {
      label: "USDT",
      id: "ETH_USDT",
      network: "ethereum",
    },
    {
      label: "USDC",
      id: "ETH_USDC",
      network: "ethereum",
    },
    // Polygon
    {
      label: "MATIC",
      id: "MATIC",
      network: "polygon",
    },
    {
      label: "USDC-POLYGON",
      id: "USDC-POLYGON",
      network: "polygon",
    },
    {
      label: "USDT",
      id: "USDT-POLYGON",
      network: "polygon",
    },
    // TRON
    {
      label: "TRX",
      id: "TRX",
      network: "tron",
    },
    {
      label: "USDT",
      id: "USDT-TRX",
      network: "tron",
    },
    {
      label: "USDC",
      id: "USDC-TRX",
      network: "tron",
    },

    // ARBI
    {
      label: "ETH",
      id: "ETH-ARBITRUM",
      network: "arbitrum",
    },
    {
      label: "USDC",
      id: "USDC-ARBITRUM",
      network: "arbitrum",
    },
    {
      label: "USDT",
      id: "USDT-ARBITRUM",
      network: "arbitrum",
    },
    // Ripple
    {
      label: "XRP",
      id: "XRP",
      network: "ripple",
    },
    // BSC
    {
      label: "BNB",
      id: "BNB",
      network: "bsc",
    },
    {
      label: "BUSD",
      id: "BUSD",
      network: "bsc",
    },
    {
      label: "USDC",
      id: "USDC-BSC",
      network: "bsc",
    },
    {
      label: "USDT",
      id: "USDT-BSC",
      network: "bsc",
    },
  ]);
  const [NETWORKS] = useState<AutocompleteOption[]>([
    {
      label: "Bitcoin",
      id: "bitcoin",
    },
    {
      label: "Ethereum",
      id: "ethereum",
    },
    {
      label: "Tron",
      id: "tron",
    },
    {
      label: "Arbitrum",
      id: "arbitrum",
    },
    {
      label: "Polygon",
      id: "polygon",
    },
    {
      label: "Ripple",
      id: "ripple",
    },
    {
      label: "Binance Smart Chain",
      id: "bsc",
    },
  ]);
  const [refresh, setRefresh] = useState(0);

  const isCryptoActive = useMemo(() => {
    return address && network && coin;
  }, [profile, address, network, coin]);

  const telegramInstructions = [
    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      Open your telegram app and search for @BotFather.
    </Typography>,
    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      In @BotFather chat, type <span style={{ color: "#4488FF" }}>/newbot</span>
      .
    </Typography>,
    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      Type the name for your new bot in the chat and send it.
    </Typography>,
    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      Now type the username for your bot. Make sure it ends with '
      <span style={{ color: "#4488FF" }}>bot</span>'. This is required by
      telegram.
    </Typography>,
    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      If the username is available, you will get a unique bot token. Click on it
      to copy it.
    </Typography>,
    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      Enter the bot token you copied in to the text field below, and click
      <span style={{ color: "#4488FF" }}> Connect</span>.
    </Typography>,

    <Typography
      sx={{
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      }}
    >
      Once connected, add the bot to your channels and groups that you want to
      monetize, and make sure the bot is admin (
      <span style={{ color: "#F33A" }}>important!</span>).
    </Typography>,
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  async function saveCurrency(id: string) {
    try {
      await rtmSetCurrency(id);
    } catch (err: any) {
      enqueueSnackbar("Error updating currency. Please try later. ", {
        variant: "error",
      });
      console.error("Error updating user's currency on onboarding page. ", err);
    }
  }

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      if (prof.currency) {
        setCurrency(
          CURRENCIES.find((c) => c.id === prof.currency) || CURRENCIES[0]
        );
      } else {
        setCurrency(CURRENCIES[0]);
      }
      // Get Bot Status
      const stats = await rtmGetBotStats();
      setBotStatus(stats);
      // Load existing configuratio
      const cfg = await rtmGetTelegramConfig();
      if (cfg) {
        setConfig(cfg);
        setToken(cfg.token);
        if (Boolean(cfg.token)) setTelegramConnected(true);
      }
      if (profile?.business?.stripe?.accountId) {
        // Get stripe account
        const str = await rtmGetStripeProfile();
        if (str) {
          setStripeProfile(str);
        }
      }
      // crypto
      // Load network
      setAddress(prof.business?.crypto?.wallet || "");
      setNetwork(NETWORKS.find((n) => n.id === prof.business?.crypto?.network));
      setCoin(ALLCOINS.find((c) => c.id === prof.business?.crypto?.currency));
      if (
        prof.business?.crypto &&
        prof.business?.crypto?.accepted_currencies?.length > 0
      ) {
        setSelectedCoins(
          ALLCOINS.filter(
            (c) =>
              prof.business!.crypto!.accepted_currencies.filter(
                (ac) => ac?.id === c?.id
              )?.length > 0
          ) as any
        );
      } else {
        setSelectedCoins([]);
      }
      setRefresh(new Date().getTime());
      // Load current step based on progress.
      // 0. If default currency is set, and telegram bot is not, we load step 3
      if (prof.currency && !cfg.token) {
        setActiveStep(2);
      }
      // 1. If telegram bot is connected and stripe is not, we skip to stripe (step 4)
      else if (
        cfg &&
        cfg.token &&
        cfg.username &&
        !prof.business?.stripe?.accountId
      ) {
        setActiveStep(3);
      }
      // If stripe is set, and crypto is not, we skip to crypto
      else if (
        cfg &&
        cfg.token &&
        cfg.username &&
        prof.business?.stripe?.accountId
      ) {
        setActiveStep(4);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading profile. Please try again. ", {
        variant: "error",
      });
    }
  }

  async function setupTelegram() {
    try {
      // Create profile
      setBusy(true);
      // Set the command
      await rtmSetCommand("init_telegram", token);
      // Wait for command to be finished.
      while (true) {
        const cmd = await rtmGetCommand();
        if (cmd.result?.success) {
          // command succeeded
          setTelegramConnected(true);
          // Get the new cfg
          await rtmUnsetCommand();
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      enqueueSnackbar("Connected with the bot. ", {
        variant: "success",
      });
      logEvent({
        name: "connected bot",
        description: "User connected telegram bot successfully.",
        identifier: profile?.uid,
      });
      loadProfile();
      // Set telegram
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error updating data. Please try again later. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error setting up telegram bot from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  async function disconnectTelegram() {
    try {
      // Create profile
      setBusy(true);
      // Set the command
      await rtmSetCommand("reset_telegram", token);
      // Wait for command to be finished.
      while (true) {
        const cmd = await rtmGetCommand();
        if (cmd.result?.success) {
          // command succeeded
          setTelegramConnected(false);
          setConfig(undefined);
          // Get the new cfg
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      enqueueSnackbar("The bot has been disconnected. ", {
        variant: "success",
      });
      loadProfile();
      logEvent({
        name: "disconnect bot",
        description: "User disconnected telegram bot.",
        identifier: profile?.uid,
      });
      // Set telegram
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error disconnecting bot. Please try again later. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error disconnecting telegram bot from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  async function createStripeAccount() {
    try {
      // Create profile
      setBusy(true);
      const resp = await rtmCreateStripeAccount();
      if (resp) {
        window.open(resp, "_blank");
        loadProfile();
        logEvent({
          name: "create stripe",
          description: "Stripe connect account creation button. ",
          identifier: profile?.uid,
        });
      } else {
        enqueueSnackbar("The server did not sent any data. ", {
          variant: "error",
        });
        console.error(
          "Error creating stripe account for user. Server returned empty response."
        );
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error creating account. Please try again later. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error creating stripe connect account from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  async function saveCrypto() {
    try {
      if (!isCryptoActive) {
        enqueueSnackbar("Please provide valid wallet details. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      // save the details
      await rtmUpdatePayments({
        address: address,
        coins: selectedCoins,
        currency: coin!.id,
        network: network!.id,
      });
      enqueueSnackbar("Profile updated successfully.", { variant: "success" });
      logEvent({
        name: "setup crypto",
        description: "Crypto profile setup for accepting crypto payments. ",
        identifier: profile?.uid,
      });
      setRefresh(new Date().getTime());
      loadProfile();
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
      console.error(err);
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error saving crypto profile from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  async function finish() {
    try {
      // Set the boarded to true.
      setBusy(true);
      await rtmFinishOnboarding();
      // Navigate to dashboard
      props.closeHandler();
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error processing your request. Please try again. ", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    loadProfile();
    let _isIntervalBusy = false;
    let timer = setInterval(async () => {
      // Load stats of bot.
      if (_isIntervalBusy) return;
      _isIntervalBusy = true;
      try {
        const stats = await rtmGetBotStats();
        setBotStatus(stats);
      } catch (err: any) {}
      _isIntervalBusy = false;
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setCOINS([...ALLCOINS.filter((c) => c.network === network?.id)]);
    setRefresh(refresh + 1);
  }, [network]);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullScreen
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{
            px: "24px",
            [theme.breakpoints.down("md")]: {
              px: "4px",
            },
          }}
        >
          <SvgIcon>
            <IC_SETTINGS />
          </SvgIcon>
          <Stack flex={1}>
            <Typography fontSize={18} fontWeight={600}>
              Setup
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Complete your profile to start accepting payments.
            </Typography>
          </Stack>
          <IconButton onClick={props.closeHandler}>
            <SvgIcon>
              <Close />
            </SvgIcon>
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px" }}>
          <Stepper orientation="vertical" activeStep={activeStep}>
            <Step key={"welcome"}>
              <StepLabel>Welcome</StepLabel>
              <StepContent>
                <Typography
                  fontSize={32}
                  fontWeight={600}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 20,
                    },
                  }}
                >
                  Welcome to Cashifybot!
                </Typography>
                <Typography fontSize={16} sx={{ my: "8px" }}>
                  The ultimate solution to telegram subscriptions.
                </Typography>
                <Typography
                  fontSize={22}
                  sx={{
                    mt: "32px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 18,
                      mt: 0,
                    },
                  }}
                >
                  Why choose Cashifybot?
                </Typography>
                <Stack sx={{ mt: "16px" }} spacing={"8px"}>
                  {whyChooseUs.map((w) => (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing="8px"
                    >
                      <Check color="primary" />
                      <Typography
                        sx={{
                          fontSize: 14,
                          [theme.breakpoints.down("md")]: {
                            fontSize: 12,
                          },
                        }}
                      >
                        {w}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <ReactPlayer
                  url={"https://youtu.be/Ba595bCVhFU"}
                  width={mobile ? "100%" : "600px"}
                />
                <Button
                  onClick={handleNext}
                  sx={{ mt: "14px" }}
                  variant="contained"
                >
                  Next
                </Button>
              </StepContent>
            </Step>
            <Step key="currency">
              <StepLabel>Currency</StepLabel>
              <StepContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Your payments made through Crypto, and Stripe, will be tracked
                  in the selected currency of your choice from the supported
                  currencies.
                  <br />
                  <br />
                  Please choose a currency that you would like to see your
                  payments in from the options below.
                  <br />
                  <br />
                  Please note that you cannot change this later.
                </Typography>
                <Stack sx={{ width: "300px", pt: "24px" }}>
                  <PFMAutoComplete
                    key={currency as any}
                    options={CURRENCIES}
                    multiple={false}
                    important
                    value={currency}
                    onUpdate={(n) => {
                      setCurrency(n as any);
                      saveCurrency((n as any).id);
                    }}
                    label="Currency"
                    placeholder="Select your preferred currency"
                  />
                </Stack>
                <Stack direction={"row"} spacing={"8px"} sx={{ mt: "12px" }}>
                  <Button
                    onClick={handleBack}
                    sx={{ mt: "14px" }}
                    disabled={busy}
                    variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={busy}
                    onClick={handleNext}
                    sx={{ mt: "14px" }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Stack>
              </StepContent>
            </Step>
            <Step key="telegram">
              <StepLabel>Set up Telegram</StepLabel>
              <StepContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  To get most out of{" "}
                  <span style={{ color: "#4488FF" }}>Cashifybot</span>, we need
                  to connect your telegram bot with our platform.
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  <span style={{ color: "#4488FF" }}>Cashifybot</span> uses
                  Telegram Bot API to provide its services to the end users, and
                  our customers. <br />
                  Your bot is the unique identity of your Cashifybot platform.{" "}
                  <br />
                  If you do not have a bot set up already, please follow the
                  instructions given below.
                </Typography>
                <Stack sx={{ mt: "16px" }} spacing={"8px"}>
                  {telegramInstructions.map((w, i) => (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing="8px"
                    >
                      <Stack
                        sx={{
                          background: "#4488FF",
                          width: "20px",
                          height: "20px",
                          minWidth: "20px",
                          minHeight: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          fontSize: 14,
                          [theme.breakpoints.down("md")]: {
                            fontSize: 12,
                          },
                        }}
                      >
                        {i + 1}
                      </Stack>
                      {w}
                    </Stack>
                  ))}
                </Stack>
                <Typography fontSize={22} sx={{ mt: "32px" }}>
                  Video Tutorial
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Watch the video below to learn how to obtain a bot token.
                </Typography>
                <YouTubePlayer
                  width={mobile ? "100%" : "600px"}
                  controls
                  url={"https://youtu.be/XVUyzBirhiY"}
                />
                <Stack spacing={"8px"}>
                  <PFMInput
                    disabled={telegramConnected}
                    text={token}
                    onUpdate={(t) => setToken(t)}
                    label="Bot Token"
                    important
                    placeholder="Paste your bot token..."
                    sx={{ mt: "32px" }}
                  />
                  {!telegramConnected && (
                    <Button
                      disabled={
                        busy || !token || botStatus?.status !== "running"
                      }
                      onClick={setupTelegram}
                      variant="contained"
                      sx={{ alignSelf: "start", gap: "8px" }}
                    >
                      {botStatus?.status === "pending" && (
                        <>
                          Provisioning, please wait...
                          <CircularProgress size={"24px"} />
                        </>
                      )}
                      {botStatus?.status !== "pending" && <>Connect</>}
                    </Button>
                  )}
                </Stack>
                {config?.token && (
                  <Stack
                    direction={"row"}
                    spacing={"8px"}
                    alignItems={"center"}
                    sx={{ my: "8px" }}
                  >
                    <Check color="primary" />
                    <Typography>Connected: </Typography>
                    <Typography color={"primary"}>
                      @{config.username}{" "}
                    </Typography>
                    <IconButton
                      title="Disconnect"
                      onClick={disconnectTelegram}
                      disabled={busy}
                    >
                      <Close />
                    </IconButton>
                  </Stack>
                )}
                {(botStatus?.status === "failed" ||
                  botStatus?.status === "unknown" ||
                  botStatus?.status === "exited") && (
                  <Stack direction={"row"}>
                    <Alert
                      color="error"
                      icon={
                        <SvgIcon>
                          <IC_ALERT></IC_ALERT>
                        </SvgIcon>
                      }
                    >
                      <AlertTitle>Error Starting Bot</AlertTitle>
                      <Typography>
                        Failed to start a new bot instance for your account.
                        Please contact administrators.
                      </Typography>
                    </Alert>
                  </Stack>
                )}

                <Stack direction={"row"} spacing={"8px"} sx={{ mt: "12px" }}>
                  <Button
                    onClick={handleBack}
                    sx={{ mt: "14px" }}
                    disabled={busy}
                    variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={busy}
                    onClick={handleNext}
                    sx={{ mt: "14px" }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Stack>
              </StepContent>
            </Step>
            <Step key="stripe">
              <StepLabel>Stripe Payments</StepLabel>
              <StepContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Setup your stripe connected account to accept credit/debit
                  card payments from over 40+ countries.
                </Typography>
                <br />
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Cashifybot uses Stripe to get you paid quickly and keep your
                  personal and payment information secure. <br />
                  Thousands of companies around the world trust Stripe to
                  process payments for their users. <br />
                  <br />
                  Set up a Stripe account to get paid with Cashifybot.
                </Typography>

                <Button
                  disabled={busy}
                  onClick={createStripeAccount}
                  variant="contained"
                  color={stripeProfile ? "secondary" : "primary"}
                  sx={{ alignSelf: "start", mt: "12px" }}
                >
                  {stripeProfile ? "Update Information" : "Create Account"}
                </Button>

                <Stack direction={"row"} spacing={"8px"} sx={{ mt: "12px" }}>
                  <Button
                    onClick={handleBack}
                    sx={{ mt: "14px" }}
                    disabled={busy}
                    variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={busy}
                    onClick={handleNext}
                    sx={{ mt: "14px" }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Stack>
              </StepContent>
            </Step>
            <Step key={"crypto"}>
              <StepLabel>Crypto Payments</StepLabel>
              <StepContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Set up your wallet and accepted payment methods (currencies)
                  for your bot to enable crypto payments, worldwide.
                </Typography>
                <br />
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Cashifybot uses CryptoChill to get you paid instantly in
                  various major crypto currencies, such as USDT (ETH)
                  <br />
                  Please provide the payout wallet details and select your
                  preferred accepted currencies for the bot below.
                </Typography>

                <Stack
                  spacing="12px"
                  sx={{
                    maxWidth: "50%",
                    mt: "12px",
                    [theme.breakpoints.down("md")]: {
                      maxWidth: "100%",
                      width: "100%",
                    },
                  }}
                >
                  <Typography>Payout Wallet</Typography>
                  <Stack direction={"row"} spacing="8px">
                    <PFMAutoComplete
                      key={network as any}
                      options={NETWORKS}
                      multiple={false}
                      value={network}
                      onUpdate={(n) => {
                        setNetwork(n as any);
                        setCoin(undefined);
                      }}
                      fullWidth
                      label="Network"
                      placeholder="Select your preferred network..."
                    />
                    <PFMAutoComplete
                      key={refresh}
                      label="Coin"
                      placeholder="Select coin..."
                      options={COINS}
                      value={coin}
                      onUpdate={(t) => setCoin(t as any)}
                    />
                  </Stack>
                  <PFMInput
                    text={address}
                    onUpdate={(t) => setAddress(t)}
                    sx={{ width: "100%" }}
                    label="Address"
                    placeholder="Enter your wallet address..."
                  />
                </Stack>

                <Stack
                  spacing={"8px"}
                  key={selectedCoins as any}
                  sx={{ pt: "18px" }}
                >
                  <Typography>Accepted Currencies</Typography>
                  <Typography fontSize={14}>
                    You can enable or disable the specific currencies for your
                    bot by using the following buttons.
                  </Typography>

                  {/* TODO: Add coin selection buttons  */}
                  {NETWORKS.map((n) => (
                    <Stack spacing={"8px"}>
                      <Typography fontWeight={500} fontSize={14}>
                        {n.label}
                      </Typography>
                      <Stack
                        direction={"row"}
                        rowGap={"8px"}
                        columnGap={"8px"}
                        flexWrap={"wrap"}
                      >
                        {/* Add each coin of this network */}
                        {ALLCOINS.filter((c) => c.network === n.id).map((c) => (
                          <Button
                            sx={{
                              fontSize: 14,
                              [theme.breakpoints.down("md")]: {
                                fontSize: 10,
                              },
                            }}
                            onClick={() => {
                              if (
                                selectedCoins.filter((sc) => sc.id === c.id)
                                  .length > 0
                              ) {
                                // remove
                                setSelectedCoins([
                                  ...selectedCoins.filter(
                                    (sc) => sc.id !== c.id
                                  ),
                                ]);
                              } else {
                                // add
                                setSelectedCoins([
                                  ...selectedCoins,
                                  ALLCOINS.find((ac) => ac.id === c.id) as any,
                                ]);
                              }
                            }}
                            color={
                              selectedCoins.filter((sc) => sc.id === c.id)
                                .length > 0
                                ? "primary"
                                : "secondary"
                            }
                            variant="outlined"
                          >
                            {c.label} ({c.id})
                          </Button>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>

                <Button
                  disabled={busy || !isCryptoActive}
                  onClick={saveCrypto}
                  variant="contained"
                  color="primary"
                  sx={{ alignSelf: "start", mt: "32px" }}
                >
                  Save
                </Button>

                <Stack direction={"row"} spacing={"8px"} sx={{ mt: "12px" }}>
                  <Button
                    onClick={handleBack}
                    sx={{ mt: "14px" }}
                    disabled={busy}
                    variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={busy}
                    onClick={handleNext}
                    sx={{ mt: "14px" }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Stack>
              </StepContent>
            </Step>
            <Step key="explore">
              <StepLabel>Explore</StepLabel>
              <StepContent>
                <Typography
                  fontSize={32}
                  fontWeight={600}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 20,
                    },
                  }}
                >
                  Ready to go!
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Thank you for choosing Cashifybot. Click the button below to
                  finish the onboarding process and explore the dashboard.
                </Typography>

                <Typography fontSize={18} sx={{ mt: "32px" }}>
                  Dashboard Overview
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Watch the video below to learn how to navigate through the
                  Cashifybot Dashboard
                </Typography>
                <YouTubePlayer
                  width={"100%"}
                  controls
                  url={"https://youtu.be/oQEDEiOYr6k"}
                />

                <Stack direction={"row"} spacing={"8px"} sx={{ mt: "12px" }}>
                  <Button
                    onClick={handleBack}
                    sx={{ mt: "14px" }}
                    disabled={busy}
                    variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={busy}
                    onClick={finish}
                    sx={{ mt: "14px" }}
                    variant="contained"
                  >
                    Finish
                  </Button>
                </Stack>
              </StepContent>
            </Step>
          </Stepper>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
