import React, { useEffect, useState } from "react";
import { Badge, Stack, ToggleButton, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../../core/states";
import { StyledToggleButtonGroup } from "../../../components/ToggleButtonGroup";
import SectionUserSettingsProfile from "./profile";
import SectionUserSettingsConfig from "./configuration";
import SectionUserSettingsPayments from "./payments";
import { motion } from "framer-motion";
import { Role } from "../../../types/Role";

export default function SectionUserSettings() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [range, setRange] = useState<"profile" | "payments" | "configuration">(
    "profile"
  );

  useEffect(() => {
    setSection(`Settings`);
    setSectionDescription(`View or update your profile and bot settings.`);
  }, []);

  return (
    <Stack sx={{ p: "32px", overflow: "hidden" }} flex={1}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        {range === "profile" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Profile
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              View or update your user profile.
            </Typography>
          </Stack>
        )}
        {range === "configuration" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Configuration
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              Change your bot configuration, such as bot token.
            </Typography>
          </Stack>
        )}

        {range === "payments" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Payments
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              View or update your payments profile.
            </Typography>
          </Stack>
        )}

        <StyledToggleButtonGroup
          exclusive
          value={range}
          onChange={(c, e) => setRange(e || range)}
        >
          <ToggleButton value={"profile"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "profile" ? "primary" : undefined}
              ></Badge>
              <Typography>Profile</Typography>
            </Stack>
          </ToggleButton>

          <ToggleButton value={"payments"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "payments" ? "primary" : undefined}
              ></Badge>
              <Typography>Payments</Typography>
            </Stack>
          </ToggleButton>

          {profile && profile.role !== Role.Affiliate && (
            <ToggleButton value={"configuration"}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                spacing="16px"
                sx={{ px: "16px" }}
              >
                <Badge
                  variant="dot"
                  color={range === "configuration" ? "primary" : undefined}
                ></Badge>
                <Typography>Configuration</Typography>
              </Stack>
            </ToggleButton>
          )}
        </StyledToggleButtonGroup>
      </Stack>
      <Stack flex={1} sx={{ overflow: "auto", overflowX: "hidden" }}>
        {range === "profile" && (
          <motion.div
            initial={{ opacity: 0, translateX: 20 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: 20 }}
            transition={{ duration: 0.5 }}
          >
            <SectionUserSettingsProfile />
          </motion.div>
        )}
        {range === "configuration" && (
          <motion.div
            initial={{ opacity: 0, translateX: 20 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: 20 }}
            transition={{ duration: 0.5 }}
          >
            <SectionUserSettingsConfig />
          </motion.div>
        )}
        {range === "payments" && (
          <motion.div
            initial={{ opacity: 0, translateX: 20 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: 20 }}
            transition={{ duration: 0.5 }}
          >
            <SectionUserSettingsPayments />
          </motion.div>
        )}
      </Stack>
    </Stack>
  );
}
