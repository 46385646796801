import { ensureAuthentication, getRtmClient } from ".";
import { AccessCode } from "../../types/AccessCode";
import { ActiveSubscription } from "../../types/ActiveSubscription";
import { Affiliate } from "../../types/Affiliation/Affiliate";
import { Reward } from "../../types/Affiliation/Reward";
import { BotStatus } from "../../types/BotStatus";
import { Broadcast } from "../../types/Broadcast";
import { Coupon } from "../../types/Coupon";
import { CryptochillInvoice } from "../../types/Cryptochill/CryptochillInvoice";
import { Invoice } from "../../types/Invoice";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { Payout } from "../../types/Payout";
import { Product } from "../../types/Product";
import { Subscription } from "../../types/Subscription";
import { TelegramChat } from "../../types/TelegramChat";
import { TelegramConfig } from "../../types/TelegramConfig";
import { TelegramPayout } from "../../types/TelegramPayout";
import { TelegramReward } from "../../types/TelegramReward";
import { TelegramUser } from "../../types/TelegramUser";
import { Transaction } from "../../types/Transaction";

/**
 * Returns the minimum payout amount.
 * @returns
 */
export async function rtmSetCurrency(currency: string) {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<boolean>(
    "rtmSetCurrency",
    currency
  );
  return prof;
}

/**
 * Returns the minimum payout amount.
 * @returns
 */
export async function rtmGetMembershipCheckout(plan: "monthly" | "yearly") {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<string>(
    "rtmGetMembershipCheckout",
    plan
  );
  return prof;
}

/**
 * Returns the minimum payout amount.
 * @returns
 */
export async function rtmGetMembershipInvoice(
  plan: "monthly" | "yearly",
  network: string,
  currency: string
) {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<CryptochillInvoice | false>(
    "rtmGetMembershipInvoice",
    plan,
    network,
    currency
  );
  return prof;
}

/**
 * Returns the specified invoice from crypto chill
 * @param id
 * @returns
 */
export async function rtmGetCryptochillInvoice(id: string) {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<CryptochillInvoice | false>(
    "rtmGetCryptochillInvoice",
    id
  );
  return prof;
}

/**
 * Returns the user's own invoices, paginated.
 * @param pageNumber
 * @param search
 * @returns
 */
export async function rtmGetPlatformInvoices(
  pageNumber: number,
  search?: string
) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<Invoice>>(
    "rtmGetPlatformInvoices",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmGetTelegramChats() {
  await ensureAuthentication();
  const chats = await getRtmClient().CallWait<TelegramChat[]>(
    "rtmGetTelegramChats"
  );
  return chats;
}

export async function rtmGetSubscriptions() {
  await ensureAuthentication();
  const subs = await getRtmClient().CallWait<Subscription[]>(
    "rtmGetSubscriptions"
  );
  return subs;
}

export async function rtmCreateSubscription(sub: Partial<Subscription>) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<Subscription>(
    "rtmCreateSubscription",
    sub
  );
  return _sub;
}

export async function rtmEditSubscription(sub: Partial<Subscription>) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<Subscription>(
    "rtmEditSubscription",
    sub
  );
  return _sub;
}
export async function rtmGetCommand() {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<any>("rtmGetCommand");
  return cmd;
}

export async function rtmGetTelegramConfig() {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<TelegramConfig>(
    "rtmGetTelegramConfig"
  );
  return cmd;
}

export async function rtmSetCommand(kind: string, input: any) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<any>("rtmSetCommand", kind, input);
  return _cmd as boolean;
}

export async function rtmUnsetCommand() {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<any>("rtmUnsetCommand");
  return _cmd as boolean;
}

/**
 * Creates a new stripe account for the user, and, returns the account dashboard url. If an account for this user already exists, only returns the link.
 * @returns
 */
export async function rtmCreateStripeAccount() {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<string>("rtmCreateStripeAccount");
  return _cmd;
}

/**
 * Returns the dashboard link to the stripe account.
 * @returns
 */
export async function rtmGetStripeDashboard() {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<string>("rtmGetStripeDashboard");
  return _cmd;
}

/**
 * Returns the current user's stripe profile if set. Otherwise returns false.
 */
export async function rtmGetStripeProfile() {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<any | false>(
    "rtmGetStripeProfile"
  );
  return _cmd;
}

export async function rtmFinishOnboarding() {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<boolean>("rtmFinishOnboarding");
  return _cmd;
}

/**
 * Returns the list of subscribers.
 * @param pageNumber
 * @param search
 * @returns
 */
export async function rtmGetSubscribers(pageNumber: number, search?: string) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<TelegramUser>>(
    "rtmGetSubscribers",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmCancelSubscription(subId: string) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<boolean>(
    "rtmCancelSubscription",
    subId
  );
  return _cmd;
}

export async function rtmGetSubscriberDetails(userId: number) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<
    TelegramUser & { subscriptions: ActiveSubscription[] }
  >("rtmGetSubscriberDetails", userId);
  return _cmd;
}

export async function rtmGetTransactions(pageNumber: number, search?: string) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<Transaction>>(
    "rtmGetTransactions",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmGetInvoices(pageNumber: number, search?: string) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<Invoice>>(
    "rtmGetInvoices",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmUpdateBotMenu(
  mainMenu: string,
  helpMenu: string,
  aboutMenu: string,
  bio: string,
  description: string,
  privacy: string
) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<boolean>("rtmUpdateBotMenu", {
    mainMenu: mainMenu,
    helpMenu: helpMenu,
    aboutMenu: aboutMenu,
    bio: bio,
    description: description,
    privacy: privacy,
  });
  return _cmd;
}

export async function rtmGetStats() {
  await ensureAuthentication();
  const _data = await getRtmClient().CallWait<{
    subscribers: {
      total: number;
      $new: number;
    };
    subscriptions: {
      total: number;
      expiring: number;
      recent: ActiveSubscription[];
    };
    invoices: {
      paid: number;
      open: number;
      recent: Invoice[];
    };
    analytics: {
      newSubscriptions: number;
      totalSubscriptions: number;
      date: number;
    }[];
    balances: {
      pendingWithdraw: number;
    };
  }>("rtmGetStats", new Date().toISOString());
  return _data;
}

export async function rtmGetBotStats() {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<BotStatus>("rtmGetBotStats");
  return cmd;
}

export async function rtmGetCodes() {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<AccessCode[]>("rtmGetCodes");
  return cmd;
}

export async function rtmCreateAccessCode(ac: Partial<AccessCode>) {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<Boolean>("rtmCreateAccessCode", ac);
  return cmd;
}

export async function rtmEditAccessCode(ac: Partial<AccessCode>) {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<Boolean>("rtmEditAccessCode", ac);
  return cmd;
}

export async function rtmDeleteAccessCode(id: string) {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<Boolean>("rtmDeleteAccessCode", id);
  return cmd;
}

export async function rtmGetAffiliateProfile() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Affiliate | false>(
    "rtmGetAffiliateProfile"
  );
  return r;
}

export async function rtmUpdateAffiliateProfile(
  address: string,
  network: string,
  coin: string
) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmUpdateAffiliateProfile",
    {
      address,
      network,
      coin,
    }
  );
  return r;
}

export async function rtmCreateAffiliateProfile() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>("rtmCreateAffiliateProfile");
  return r;
}

export async function rtmRequestPayout(acc: "primary" | "affiliation") {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Payout>("rtmRequestPayout", acc);
  return r;
}

export async function rtmListReferrals() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<
    { user: string; registered: number; outcome: number }[]
  >("rtmListReferrals");
  return r;
}

export async function rtmListPayouts() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Payout[]>("rtmListPayouts");
  return r;
}

export async function rtmListRewards() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Reward[]>("rtmListRewards");
  return r;
}

/**
 * Updates the payment details of the user.
 * @param update
 */
export async function rtmUpdatePayments(update: {
  address: string;
  network: string;
  currency: string;
  coins: any[];
}) {
  await ensureAuthentication();
  return await getRtmClient().CallWait<boolean>("rtmUpdatePayments", update);
}

export async function rtmGetStripeBalance() {
  await ensureAuthentication();
  return await getRtmClient().CallWait<
    { currency: string; balance: number } | false
  >("rtmGetStripeBalance");
}

/**
 * Cancels the subscription at the end of the period.
 */
export async function rtmCancelUserSubscription() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>("rtmCancelUserSubscription");
  return r;
}

export async function rtmReactivateUserSubscription() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmReactivateUserSubscription"
  );
  return r;
}

/**
 * Updates the onboarding state of the user
 * @param state
 * @returns
 */
export async function rtmSetOnboardingState(state: number) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmSetOnboardingState",
    state
  );
  return r;
}

export async function rtmGetAffiliateStats() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    balance: number;
    paid: number;
    pending: number;
  }>("rtmGetAffiliateStats");
  return r;
}

export async function rtmListReferrers(page: number) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<PaginatedResponse<TelegramUser>>(
    "rtmListReferrers",
    page
  );
  return r;
}

export async function rtmListTelegramPayouts(page: number, filter?: string) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<PaginatedResponse<TelegramPayout>>(
    "rtmListTelegramPayouts",
    { page, filter }
  );
  return r;
}

export async function rtmListTelegramRewards(page: number) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<
    PaginatedResponse<
      TelegramReward & { userInfo: TelegramUser; sourceUserInfo: TelegramUser }
    >
  >("rtmListTelegramRewards", page);
  return r;
}

export async function rtmSetAffiliateProfile(profile: {
  minPayout: number;
  commission: number;
  mode: "single" | "recurring";
  enabled: boolean;
  paypal: boolean;
}) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmSetAffiliateProfile",
    profile
  );
  return r;
}

export async function rtmGeneratePayoutPreview(
  includePaypal: boolean,
  includeCrypto: boolean
) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    total: number;
    crypto: number;
    paypal: number;
    cryptoUsers: TelegramUser[];
    paypalUsers: TelegramUser[];
    totalUsers: number;
    nonConfiguredUsers: TelegramUser[];
  }>("rtmGeneratePayoutPreview", { includePaypal, includeCrypto });
  return r;
}

export async function rtmCreateManualPayout(includeCrypto: boolean) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    total: number;
    crypto: number;
    paypal: number;
    cryptoUsers: TelegramUser[];
    paypalUsers: TelegramUser[];
    totalUsers: number;
    nonConfiguredUsers: TelegramUser[];
    csvPaypal: string;
    csvCrypto: string;
    payout: TelegramPayout;
  }>("rtmCreateManualPayout", { includeCrypto });
  return r;
}

export async function rtmGetPayoutDetails(payoutId: string) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<TelegramPayout>(
    "rtmGetPayoutDetails",
    payoutId
  );
  return r;
}

export async function rtmMarkPayoutAsComplete(payoutId: string) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmMarkPayoutAsComplete",
    payoutId
  );
  return r;
}

export async function rtmCreateAutoPayout(
  payNetwork: string,
  payCurrency: string,
  includePaypal: boolean
) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    total: number;
    crypto: number;
    paypal: number;
    totalUsers: number;
    csvPaypal?: string;
    cryptoUsers: TelegramUser[];
    paypalUsers: TelegramUser[];
    cryptoPayout?: TelegramPayout; // Auto Payout
    paypalPayout?: TelegramPayout; // Manual Payout (PayPal users)
  }>("rtmCreateAutoPayout", {
    network: payNetwork,
    currency: payCurrency,
    includePaypal,
  });
  return r;
}

export async function rtmGeneratePayoutLists(payoutId: string) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    csvPaypal?: string;
    csvCrypto?: string;
  }>("rtmGeneratePayoutLists", payoutId);
  return r;
}

export async function rtmGetFeatureFlag(feature: string) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmGetFeatureFlag",
    feature
  );
  return r;
}

export async function rtmCreateBroadcast(bc: Partial<Broadcast>) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>("rtmCreateBroadcast", bc);
  return r;
}

export async function rtmGetBroadcasts(page: number) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<PaginatedResponse<Broadcast>>(
    "rtmGetBroadcasts",
    page
  );
  return r;
}

export async function rtmStopBroadcast(broadcastId: string) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>(
    "rtmStopBroadcast",
    broadcastId
  );
  return r;
}

export async function rtmGetBroadcastStats() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    codeUsers: number;
    subscribedUsers: number;
    bouncedUsers: number;
  }>("rtmGetBroadcastStats");
  return r;
}

export async function rtmGetProducts() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Product[]>("rtmGetProducts");
  return r;
}

export async function rtmCreateProduct(prod: Partial<Product>) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Product>("rtmCreateProduct", prod);
  return r;
}

export async function rtmUpdateProduct(prod: Partial<Product>) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<Product>("rtmUpdateProduct", prod);
  return r;
}

export async function rtmRegenerateApiKey() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<string>("rtmRegenerateApiKey");
  return r;
}

export async function rtmSignout() {
  const _rtm = await getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmSignout");
  return _result;
}

export async function rtmGetCoupons() {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<Coupon[]>("rtmGetCoupons");
  return cmd;
}
export async function rtmCreateCoupon(coupon: Partial<Coupon>) {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<boolean>("rtmCreateCoupon", coupon);
  return cmd;
}
export async function rtmDeleteCoupon(coupon: Coupon) {
  await ensureAuthentication();
  const cmd = await getRtmClient().CallWait<boolean>("rtmDeleteCoupon", coupon);
  return cmd;
}
