import React, { useState } from "react";
import {
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DualHeading from "../../components/DualHeading";
import { IC_AFFILIATE } from "../../assets/landing";
import { IC_CANCEL, IC_CHECKMARK } from "../../assets/ui";
import { motion } from "framer-motion";
import { FbAuth } from "../../authentication/firebase";
import { useNavigate } from "react-router";
import { ChangeCircle, Refresh } from "@mui/icons-material";
export default function Pricing() {
  const desktop = useMediaQuery("(min-width: 700px)");

  const nav = useNavigate();

  function loginOrGoToBilling() {
    const user = FbAuth.currentUser;
    if (user && user.emailVerified) {
      nav("/dashboard/billing");
    } else {
      nav("/signup");
    }
  }

  return (
    <Stack
      id="pricing"
      alignItems={"center"}
      spacing={"32px"}
      sx={{ py: "48px" }}
    >
      <DualHeading
        component={"h2"}
        sx={{
          fontSize: desktop ? "36px" : "24px",
        }}
        h1="Pricing"
        h2=""
        h2sx={{ color: "#48F" }}
      />
      <Typography
        fontSize={desktop ? 20 : 14}
        textAlign={"center"}
        sx={{ maxWidth: "70%" }}
      >
        Cashifybot is completely free to use, with no upfront fees. You only
        incur a small fee per transaction.
      </Typography>

      <Stack
        direction={desktop ? "row" : "column"}
        spacing={"24px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <motion.div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <Paper
            sx={{
              bgcolor: "#48F1",
              width: desktop ? "400px" : "300px",
              border: "1px solid #48F2",
              borderRadius: "4px",
              p: "32px",
              position: "relative",
            }}
          >
            <Stack
              sx={{ height: "100%", px: "28px" }}
              alignItems={"center"}
              spacing="18px"
            >
              <Typography fontSize={20} fontWeight={600}>
                Membership Plan
              </Typography>
              <Typography
                fontSize={24}
                fontWeight={500}
                sx={{ px: "24px" }}
                textAlign={"center"}
              >
                3% <span style={{ fontSize: "12px" }}>/transaction</span>
              </Typography>
              <Divider sx={{ width: "100%" }} />
              <Stack spacing="12px">
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Free to Start</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Dashboard Access</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Sell Digital Assets</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Unlimited Access Codes</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Bot Customization</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Unlimited Subscriptions</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Live Support</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Built-in Affiliate Tools</Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_CHECKMARK />
                  </SvgIcon>
                  <Typography>Broadcast Messages</Typography>
                </Stack>
              </Stack>
              <Button
                onClick={loginOrGoToBilling}
                variant="contained"
                fullWidth
                size="large"
                sx={{ borderRadius: "25px" }}
              >
                Get Started
              </Button>
            </Stack>
          </Paper>
        </motion.div>
      </Stack>
      <Typography fontSize={12} textAlign={"center"}>
        Note: Our transaction fees are calculated after factoring in payment
        processing fees.
      </Typography>
    </Stack>
  );
}
