import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Check, Info } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";

import { enqueueSnackbar } from "notistack";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../../components/PFMAutoComplete";
import { IC_ALERT } from "../../assets/ui";
import { rtmSetCurrency } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import YouTubePlayer from "react-player/youtube";

export default function OnboardingStepCurrency(props: {
  next: () => void;
  back: () => void;
}) {
  const [profile, setProfile] = useRecoilState(aProfile);

  const [CURRENCIES] = useState<AutocompleteOption[]>([
    {
      label: "USD $",
      id: "usd",
    },
    {
      label: "GBP £",
      id: "gbp",
    },
    {
      label: "Euro €",
      id: "eur",
    },
  ]);

  const [currency, setCurrency] = useState<AutocompleteOption>();

  const whyChooseUs = [
    "Get paid quickly and securely with our Stripe and Crypto payments integration.",
    "Accept card payments in over 40+ countries, and crypto payments worldwide.",
    "Easy to use with powerful, user-friendly dashboard.",
    "Create unlimited subscriptions for any number of channels.",
    "You are charged per transactions only. No fixed charges for the starters. ",
    "Partner with Cashifybot and earn more through its Partnership Program.",
    "And so much more!",
  ];

  async function saveCurrency(id: string) {
    try {
      await rtmSetCurrency(id);
    } catch (err: any) {
      enqueueSnackbar("Error updating currency. Please try later. ", {
        variant: "error",
      });
      console.error("Error updating user's currency on onboarding page. ", err);
    }
  }

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      if (prof.currency) {
        setCurrency(
          CURRENCIES.find((c) => c.id === prof.currency) || CURRENCIES[0]
        );
      } else {
        setCurrency(CURRENCIES[0]);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading profile. Please try again. ", {
        variant: "error",
      });
    }
  }
  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Payments: Currency
      </Typography>
      <Typography sx={{ my: "8px" }}>
        Your payments made through Crypto, and Stripe, will be tracked in the
        selected currency of your choice from the supported currencies.
        <br />
        <br />
        Please choose a currency that you would like to see your payments in
        from the options below.
        <br />
        <br />
        Note that our affiliate system uses USD by default.
      </Typography>

      <Stack sx={{ width: "300px", pt: "24px" }}>
        <PFMAutoComplete
          key={currency as any}
          options={CURRENCIES}
          multiple={false}
          important
          value={currency}
          onUpdate={(n) => {
            setCurrency(n as any);
            saveCurrency((n as any).id);
          }}
          label="Currency"
          placeholder="Select your preferred currency"
        />
      </Stack>
      <Alert
        sx={{ alignSelf: "start", mt: "20px" }}
        variant="filled"
        color="warning"
        icon={
          <SvgIcon color="warning">
            <IC_ALERT />
          </SvgIcon>
        }
      >
        <AlertTitle>Attention</AlertTitle>
        <Typography>
          Please select your platform currency carefully, as this choice cannot
          be modified later.
        </Typography>
      </Alert>

      <Stack direction={"row"} spacing="8px" sx={{ mt: "32px" }}>
        <Button
          onClick={props.back}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Back
        </Button>
        <Button
          disabled={!currency}
          onClick={() => {
            saveCurrency(currency?.id || "usd").then(props.next);
          }}
          variant="contained"
          sx={{ alignSelf: "start", mt: "32px" }}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
