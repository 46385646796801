import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_ALERT, IC_PAID } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import {
  rtmCreateAutoPayout,
  rtmCreateManualPayout,
  rtmGeneratePayoutPreview,
  rtmGetCryptochillInvoice,
} from "../core/api/user";
import { rtmGetProfile } from "../core/api";

import { PFMSwitch } from "../components/PFMSwitch";
import LoadingView from "../components/LoadingView";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import PFMTable from "../components/PFMTable";
import { Download } from "@mui/icons-material";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import { User } from "../types/User";
import { TelegramUser } from "../types/TelegramUser";
import { TelegramPayout } from "../types/TelegramPayout";
import {
  CCInvoiceStatus,
  CryptochillInvoice,
} from "../types/Cryptochill/CryptochillInvoice";

export default function CreatePayoutDialog(props: DialogProps<any>) {
  const [profile, setProfile] = useState<User>();
  const [mode, setMode] = useState<"automatic" | "manual">("manual");
  const [busy, setBusy] = useState(false);
  // Whether to generate paypal address list for users in an automated payout.
  const [includePaypal, setIncludePaypal] = useState(true);
  // Whether to generate crypto list for users in Manual payout.
  const [includeCrypto, setIncludeCrypto] = useState(true);
  const [preview, setPreview] = useState<{
    total: number;
    crypto: number;
    paypal: number;
    cryptoUsers: TelegramUser[];
    paypalUsers: TelegramUser[];
    totalUsers: number;
    nonConfiguredUsers: TelegramUser[];
  }>();
  const [manualPayoutDetails, setManualPayoutDetails] = useState<{
    total: number;
    crypto: number;
    paypal: number;
    cryptoUsers: TelegramUser[];
    paypalUsers: TelegramUser[];
    totalUsers: number;
    nonConfiguredUsers: TelegramUser[];
    csvPaypal: string;
    csvCrypto: string;
    payout: TelegramPayout;
  }>();
  const [autoPayoutDetails, setAutoPayoutDetails] = useState<{
    total: number;
    crypto: number;
    paypal: number;
    totalUsers: number;
    csvPaypal?: string;
    cryptoUsers: TelegramUser[];
    paypalUsers: TelegramUser[];
    cryptoPayout?: TelegramPayout; // Auto Payout
    paypalPayout?: TelegramPayout; // Manual Payout (PayPal users)
  }>();

  const [payoutInvoice, setPayoutInvoice] = useState<CryptochillInvoice>();

  const [network, setNetwork] = useState<AutocompleteOption>();
  const [coin, setCoin] = useState<AutocompleteOption>();

  // Acceptable coins for crypto.
  const [ALLCOINS] = useState<AutocompleteOption[]>([
    {
      label: "ETH",
      id: "ETH",
      network: "ethereum",
    },
  ]);
  // Supported networks for payment.
  const [NETWORKS] = useState<AutocompleteOption[]>([
    {
      label: "Ethereum (ERC20)",
      id: "ethereum",
    },
  ]);
  // Available Coins for each network, displayed in dropdown
  const [COINS, setCOINS] = useState<AutocompleteOption[]>([]);

  async function createPayout() {
    try {
      if (mode === "automatic") {
        if (!coin) {
          enqueueSnackbar("Please select your payment currency. ", {
            variant: "error",
          });
          return;
        }
        setBusy(true);
        const r = await rtmCreateAutoPayout(
          coin.network,
          coin.id,
          includePaypal
        );
        setAutoPayoutDetails(r);
        // Get the invoice details
        const inv = await rtmGetCryptochillInvoice(r.cryptoPayout?.invoiceId!);
        if (inv) {
          setPayoutInvoice(inv);

          setInterval(async () => {
            const inv = await rtmGetCryptochillInvoice(
              r.cryptoPayout?.invoiceId!
            );
            if (inv) setPayoutInvoice(inv);
          }, 5000);
        }
      } else {
        setBusy(true);
        const r = await rtmCreateManualPayout(includeCrypto);
        setManualPayoutDetails(r);
      }
    } catch (err: any) {
      enqueueSnackbar("Error creating payout. Please try again. ", {
        variant: "error",
      });
      console.error("Error creating payout. ", err);
    }
    setBusy(false);
  }

  async function loadPreview() {
    try {
      if (mode === "automatic" && !coin) {
        enqueueSnackbar("Please select the payment currency. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const r = await rtmGeneratePayoutPreview(
        mode === "automatic" ? includePaypal : true,
        mode === "automatic" ? true : includeCrypto
      );
      setPreview(r);
    } catch (err: any) {
      enqueueSnackbar("Error loading preview. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading payout preview. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data for affiliation settings dialog.", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setCOINS([...ALLCOINS.filter((c) => c.network === network?.id)]);
  }, [network]);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={
        payoutInvoice && payoutInvoice.status !== CCInvoiceStatus.complete
          ? undefined
          : props.closeHandler
      }
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_PAID />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Create Payout
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Generate a list, or send crypto payouts automatically.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!profile && <LoadingView />}
        {profile && !preview && (
          <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
            {/* Affiliation Kind  */}
            <Stack spacing={"4px"}>
              <Typography>Select your payout method</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                <Stack
                  flex={1}
                  sx={{
                    cursor: "pointer",
                    border:
                      mode === "automatic"
                        ? "1px solid #48F"
                        : "1px solid #FFF3",
                    borderRadius: "12px",
                    px: "14px",
                    py: "8px",
                    position: "relative",
                    opacity: 0.5,
                  }}
                >
                  <Badge
                    sx={{
                      position: "absolute",
                      top: "20px",
                      right: "60px",
                      width: "120px",
                    }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    badgeContent="Coming Soon!"
                    color="primary"
                  />
                  <Typography fontWeight={500} fontSize={20}>
                    Automatic (crypto only)
                  </Typography>
                  <Typography fontSize={14}>
                    Send crypto payouts to users through our platform.
                  </Typography>
                </Stack>

                <Stack
                  onClick={() => setMode("manual")}
                  flex={1}
                  sx={{
                    cursor: "pointer",
                    border:
                      mode === "manual" ? "1px solid #48F" : "1px solid #FFF3",
                    borderRadius: "12px",
                    px: "14px",
                    py: "8px",
                  }}
                >
                  <Typography fontWeight={500} fontSize={20}>
                    Manually
                  </Typography>
                  <Typography fontSize={14}>
                    Generate CSV lists of crypto addresses and PayPal emails.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            {mode === "automatic" && (
              <Stack spacing={"8px"}>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <PFMSwitch
                    checked={includePaypal}
                    onChange={(e) => setIncludePaypal(e.currentTarget.checked)}
                  />
                  <Typography>
                    Create manual payout for PayPal
                    <br />
                    <span style={{ fontSize: 12 }}>
                      Use this to also create a PayPal Mass Payout list for
                      users with PayPal address.
                    </span>
                  </Typography>
                </Stack>
                <Typography>Payment Method</Typography>
                <Stack direction={"row"} spacing="8px">
                  <PFMAutoComplete
                    important
                    options={NETWORKS}
                    multiple={false}
                    value={network}
                    onUpdate={(n) => {
                      setNetwork(n as any);
                      setCoin(undefined);
                    }}
                    fullWidth
                    label="Network"
                    placeholder="Select your preferred network..."
                  />
                  <PFMAutoComplete
                    important
                    key={COINS as any}
                    label="Currency"
                    placeholder="Select a currency..."
                    options={COINS}
                    value={coin}
                    onUpdate={(t) => setCoin(t as any)}
                  />
                </Stack>
                <Alert
                  icon={
                    <SvgIcon>
                      <IC_ALERT />
                    </SvgIcon>
                  }
                >
                  <AlertTitle>How it works</AlertTitle>
                  <Typography>
                    - Our platform calculates the total amount of payouts.{" "}
                    <br />- An invoice on our platform is generated for you to
                    load the funds with preferred currency. <br />- Once the
                    invoice is complete, the funds are sent to each user in a
                    batch of 50 addresses each.
                    <br />- Works only for crypto payouts.
                    <br />- You can choose to create a manual payout for PayPal
                    users.
                  </Typography>
                </Alert>

                <Alert
                  color="warning"
                  icon={
                    <SvgIcon>
                      <IC_ALERT />
                    </SvgIcon>
                  }
                >
                  <AlertTitle>Note</AlertTitle>
                  <Typography>
                    Network fees will be deducted from the users' final payout.
                  </Typography>
                </Alert>
              </Stack>
            )}

            {mode === "manual" && (
              <Stack spacing={"8px"}>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <PFMSwitch
                    checked={includeCrypto}
                    onChange={(e) => setIncludeCrypto(e.currentTarget.checked)}
                  />
                  <Typography>
                    Include users with crypto payout
                    <br />
                    <span style={{ fontSize: 12 }}>
                      Use this to create a list of crypto addresses with amounts
                      for use in NOWPayments.io
                    </span>
                  </Typography>
                </Stack>
                <Alert
                  icon={
                    <SvgIcon>
                      <IC_ALERT />
                    </SvgIcon>
                  }
                >
                  <AlertTitle>How it works</AlertTitle>
                  <Typography>
                    - Our platform generates a list of all the crypto (and
                    emails for PayPal) addresses to pay.
                    <br />- You send payouts to users manually using PayPal Mass
                    Payouts, and NOWPayments.io Mass Payout with generated CSV
                    files.
                    <br />- You can manually mark the payout as complete.
                  </Typography>
                </Alert>
              </Stack>
            )}
          </Stack>
        )}
        {profile && preview && !manualPayoutDetails && !autoPayoutDetails && (
          <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
            <Stack spacing={"4px"}>
              <Typography fontSize={20} fontWeight={500}>
                Confirm Payout
              </Typography>
            </Stack>

            {/* The totals */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Total (Crypto)</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(preview.crypto).dollars}.
                {parseStripeAmount(preview.crypto).cents}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Total (PayPal)</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(preview.paypal).dollars}.
                {parseStripeAmount(preview.paypal).cents}
              </Typography>
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Grand Total</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(preview.total).dollars}.
                {parseStripeAmount(preview.total).cents}
              </Typography>
            </Stack>
            <Divider />

            {/* Crypto Payout Users: Shown only when crypto users are available  */}
            {preview.cryptoUsers.length > 0 && (
              <Stack spacing={"4px"}>
                <PFMTable
                  sx={{ minHeight: "200px" }}
                  description="Users with Crypto Payouts"
                  tableHead={
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  }
                  tableBody={preview.cryptoUsers.map((cu) => (
                    <TableRow>
                      <TableCell>
                        <Stack
                          direction={"row"}
                          spacing="12px"
                          alignItems={"center"}
                        >
                          <Avatar
                            src={cu.image || ""}
                            sx={{ color: "white" }}
                          />
                          <Stack>
                            <Typography fontSize={14} fontWeight={500}>
                              {cu.name}
                            </Typography>
                            <Typography
                              fontSize={14}
                              fontWeight={400}
                              color="#94969C"
                            >
                              @{cu.username || cu.id}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {cu.affiliate?.payout?.currency?.toUpperCase()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {getCurrencySymbol(profile.currency)}
                          {parseStripeAmount(cu.affiliate?.balance!).dollars}.
                          {parseStripeAmount(cu.affiliate?.balance!).cents}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                />
              </Stack>
            )}

            {/* PayPal Payout Users: Shown only when paypal users are available  */}
            {preview.paypalUsers.length > 0 && (
              <Stack spacing={"4px"}>
                <PFMTable
                  sx={{ minHeight: "200px" }}
                  description="Users with PayPal Payouts"
                  tableHead={
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  }
                  tableBody={preview.paypalUsers.map((cu) => (
                    <TableRow>
                      <TableCell>
                        <Stack
                          direction={"row"}
                          spacing="12px"
                          alignItems={"center"}
                        >
                          <Avatar
                            src={cu.image || ""}
                            sx={{ color: "white" }}
                          />
                          <Stack>
                            <Typography fontSize={14} fontWeight={500}>
                              {cu.name}
                            </Typography>
                            <Typography
                              fontSize={14}
                              fontWeight={400}
                              color="#94969C"
                            >
                              @{cu.username || cu.id}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography>{cu.affiliate?.payout?.paypal}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {getCurrencySymbol(profile.currency)}
                          {parseStripeAmount(cu.affiliate?.balance!).dollars}.
                          {parseStripeAmount(cu.affiliate?.balance!).cents}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                {mode === "automatic" && (
                  <Alert
                    color="warning"
                    icon={
                      <SvgIcon>
                        <IC_ALERT />
                      </SvgIcon>
                    }
                  >
                    <AlertTitle>Note</AlertTitle>
                    <Typography>
                      Users with PayPal payouts will be added to a separate
                      payout, that you will manually mark as Complete after
                      sending the funds to the generated list.
                    </Typography>
                  </Alert>
                )}
              </Stack>
            )}
          </Stack>
        )}
        {profile && preview && manualPayoutDetails && (
          <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
            <Alert>
              <Typography>Payout created successfully!</Typography>
            </Alert>

            <Stack spacing={"4px"}>
              <Typography fontSize={20} fontWeight={500}>
                Payout Details
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Payout ID</Typography>
              <Typography>{manualPayoutDetails.payout.id}</Typography>
            </Stack>
            {/* The totals */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Total (Crypto)</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(preview.crypto).dollars}.
                {parseStripeAmount(preview.crypto).cents}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Total (PayPal)</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(preview.paypal).dollars}.
                {parseStripeAmount(preview.paypal).cents}
              </Typography>
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Grand Total</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(preview.total).dollars}.
                {parseStripeAmount(preview.total).cents}
              </Typography>
            </Stack>
            <Divider />

            {/* Download Links to CSV files  */}
            <PFMInput
              label="PayPal CSV File"
              helperText="Use this file in PayPal mass payouts. Link is valid for 1 hour only."
              text={manualPayoutDetails.csvPaypal}
              endAdornment={
                <IconButton
                  onClick={() =>
                    window.open(manualPayoutDetails.csvPaypal, "_blank")
                  }
                >
                  <SvgIcon>
                    <Download />
                  </SvgIcon>
                </IconButton>
              }
            />

            {manualPayoutDetails.csvCrypto && (
              <PFMInput
                label="NOWPayments.io CSV File"
                helperText="Use this file in NOWPayments.io Mass Payout option. Link is valid for 1 hour only."
                text={manualPayoutDetails.csvCrypto}
                endAdornment={
                  <IconButton
                    onClick={() =>
                      window.open(manualPayoutDetails.csvCrypto, "_blank")
                    }
                  >
                    <SvgIcon>
                      <Download />
                    </SvgIcon>
                  </IconButton>
                }
              />
            )}
          </Stack>
        )}
        {profile && preview && autoPayoutDetails && (
          <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
            <Alert>
              <Typography>Payout created successfully!</Typography>
            </Alert>

            <Stack spacing={"4px"}>
              <Typography fontSize={20} fontWeight={500}>
                Payout Details
              </Typography>
            </Stack>

            {/* Payout ID */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Auto Payout ID</Typography>
              <Typography>{autoPayoutDetails.cryptoPayout?.id}</Typography>
            </Stack>
            {/* If manual payout is also created,  */}
            {autoPayoutDetails.paypalPayout?.id && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ opacity: 0.7 }}>PayPal Payout ID</Typography>
                <Typography>{autoPayoutDetails.paypalPayout?.id}</Typography>
              </Stack>
            )}

            {/* The totals */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Total (Crypto)</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(autoPayoutDetails.crypto).dollars}.
                {parseStripeAmount(autoPayoutDetails.crypto).cents}
              </Typography>
            </Stack>

            {/* if paypal is included  */}
            {autoPayoutDetails.paypal > 0 && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ opacity: 0.7 }}>Total (PayPal)</Typography>
                <Typography>
                  {getCurrencySymbol(profile.currency)}
                  {parseStripeAmount(autoPayoutDetails.paypal).dollars}.
                  {parseStripeAmount(autoPayoutDetails.paypal).cents}
                </Typography>
              </Stack>
            )}
            <Divider />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Grand Total</Typography>
              <Typography>
                {getCurrencySymbol(profile.currency)}
                {parseStripeAmount(autoPayoutDetails.total).dollars}.
                {parseStripeAmount(autoPayoutDetails.total).cents}
              </Typography>
            </Stack>
            <Divider />

            {/* Download Links to CSV files, if available */}
            {autoPayoutDetails.csvPaypal && (
              <PFMInput
                label="PayPal CSV File"
                helperText="Use this file in PayPal mass payouts. Link is valid for 1 hour only."
                text={autoPayoutDetails.csvPaypal}
                endAdornment={
                  <IconButton
                    onClick={() =>
                      window.open(autoPayoutDetails.csvPaypal, "_blank")
                    }
                  >
                    <SvgIcon>
                      <Download />
                    </SvgIcon>
                  </IconButton>
                }
              />
            )}

            {/* Show the crypto invoice  */}
            <Stack
              direction={"row"}
              onClick={() =>
                window.open(
                  "https://cryptochill.com/invoice/" + payoutInvoice?.id,
                  "_blank"
                )
              }
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "10px",
                borderRadius: "8px",
                border: `2px solid #4488FF`,
              }}
            >
              <Stack spacing="8px" flex={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ width: "100%" }}
                >
                  <Typography>Invoice ID: </Typography>
                  <Typography color={"primary"}>{payoutInvoice?.id}</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ width: "100%" }}
                >
                  <Typography>Network: </Typography>
                  <Typography color={"primary"}>
                    {network?.label.toUpperCase()}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ width: "100%" }}
                >
                  <Typography>Currency: </Typography>
                  <Typography color={"primary"}>
                    {payoutInvoice?.kind.toUpperCase()}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ width: "100%" }}
                >
                  <Typography>Pay Address:</Typography>
                  <Typography color={"primary"}>
                    {payoutInvoice?.address}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ width: "100%" }}
                >
                  <Typography>Amount: </Typography>
                  <Typography color={"primary"}>
                    {payoutInvoice?.amount.invoiced.amount}{" "}
                    {payoutInvoice?.kind.toUpperCase()}
                  </Typography>
                </Stack>

                {/* The info will be used from profile if exists, or the user will be asked to enter it.  */}
                <Alert
                  color="warning"
                  icon={
                    <SvgIcon>
                      <IC_ALERT />
                    </SvgIcon>
                  }
                >
                  <AlertTitle>Note</AlertTitle>
                  <Typography>
                    The amount specified is exclusive of the network fees. Make
                    sure to send exactly the specified amount to complete the
                    payout.
                  </Typography>
                </Alert>
              </Stack>
              <img
                style={{ width: "150px", height: "150px" }}
                src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${payoutInvoice?.address}`}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={
            busy ||
            (payoutInvoice && payoutInvoice.status !== CCInvoiceStatus.complete)
          }
          size="large"
          fullWidth
          variant="contained"
          color="secondary"
        >
          {mode === "automatic" && payoutInvoice ? (
            <>
              {payoutInvoice.status === CCInvoiceStatus.pending ||
              (payoutInvoice.status as any) === "new"
                ? "Waiting for payment..."
                : payoutInvoice.status === CCInvoiceStatus.confirmed
                ? "Confirming..."
                : payoutInvoice.status === CCInvoiceStatus.complete
                ? "Invoice Complete. Click to close. "
                : payoutInvoice.status === CCInvoiceStatus.incomplete
                ? "Incomplete invoice, waiting for invoice to be fulfilled..."
                : "Expired or failed"}
            </>
          ) : (
            "Close"
          )}
        </Button>
        {!manualPayoutDetails && !autoPayoutDetails && (
          <Button
            disabled={busy}
            onClick={preview ? createPayout : loadPreview}
            size="large"
            fullWidth
            variant="contained"
            color="primary"
          >
            {preview ? "Create Payout" : "Continue"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
