import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { IC_PAID } from "../assets/ui";

import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import { rtmSendPayout } from "../core/api/admin";
import { Payout } from "../types/Payout";
import { User } from "../types/User";

export default function PayoutDetailsDialog(
  props: DialogProps<Payout & { user: User }>
) {
  const [busy, setBusy] = useState(false);
  const theme = useTheme();

  async function confirm() {
    try {
      setBusy(true);
      const r = await rtmSendPayout(props.data?.id!);
      enqueueSnackbar("Payout is being processed. ", { variant: "success" });
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error processing payout. Please try later.", {
        variant: "error",
      });
      console.error("Error processing payout. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {}, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <SvgIcon>
            <IC_PAID />
          </SvgIcon>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Payout Details
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              View and confirm payout.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="8px" sx={{ p: "32px", pb: "32px" }}>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar
              src={props.data?.user.image || ""}
              sx={{ color: "white" }}
            />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {props.data?.user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {props.data?.user.email}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing="8px"
            sx={{ width: "100%" }}
          >
            <Typography>Network: </Typography>
            <Typography color={"primary"}>
              {props.data?.payout_network.toUpperCase()}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing="8px"
            sx={{ width: "100%" }}
          >
            <Typography>Currency: </Typography>
            <Typography color={"primary"}>
              {props.data?.payout_currency.toUpperCase()}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing="8px"
            sx={{ width: "100%" }}
          >
            <Typography>Wallet address: </Typography>
            <Typography color={"primary"}>
              {props.data?.payout_address}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing="8px"
            sx={{ width: "100%" }}
          >
            <Typography>Amount (fiat): </Typography>
            <Typography color={"primary"}>
              {getCurrencySymbol(props.data?.currency!)}
              {`${parseStripeAmount(props.data?.amount || 0).dollars}.${
                parseStripeAmount(props.data?.amount || 0).cents
              }`}
            </Typography>
          </Stack>

          {props.data?.status !== "requested" && (
            <>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing="8px"
                sx={{ width: "100%" }}
              >
                <Typography>Status: </Typography>
                <Typography color={"primary"}>{props.data?.status}</Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing="8px"
                sx={{ width: "100%" }}
              >
                <Typography>Transaction ID: </Typography>
                <Typography color={"primary"}>
                  {props.data?.transactionId}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={"row"}
          sx={{ width: "100%", px: "32px" }}
          spacing="12px"
        >
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          {(props.data?.status === "requested" ||
            props.data?.status === "failed") && (
            <Button
              disabled={busy}
              size="large"
              fullWidth
              onClick={confirm}
              variant="contained"
              color="primary"
            >
              Send Payout
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
