import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import PFMInput from "../../components/PFMInput";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { IC_REFRESH, IC_SEARCH } from "../../assets/ui";
import { motion } from "framer-motion";
import { rtmGetTransactions } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";
import { Transaction } from "../../types/Transaction";

export default function SectionUserTransactions() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [data, setData] = useState<Transaction[]>([]);

  async function load(page: number) {
    try {
      setBusy(true);
      const _data = await rtmGetTransactions(page, search);
      if (_data) {
        setData(_data.data);
        setPageNumber(_data.currentPage);
        setDataCount(_data.total);
        setPageCount(_data.pageCount);
      }
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading transactions data. ",
        stack: err,
        tags: ["transactions"],
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Transactions`);
    setSectionDescription(
      `View the transactions associated with your payments profile.`
    );
    load(0);
    logEvent({
      name: "page_view",
      identifier: "transactions",
    });
  }, []);

  function RenderData(u: Transaction) {
    const amnt = parseStripeAmount(u.amount);
    const sym = getCurrencySymbol(u.currency);
    return (
      <TableRow>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(u.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {u.type}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {u.invoiceId}
        </TableCell>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Avatar src={u.customerImage} />
            <Stack>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 12,
                  },
                }}
              >
                {u.customerName}
              </Typography>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                @{u.customerUsername || u.customerUid || u.customerId}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <PFMInput
            placeholder="Search..."
            startIcon={IC_SEARCH}
            text={search}
            onUpdate={(t) => setSearch(t)}
            onReturn={() => {
              load(pageNumber);
            }}
          />
          <Button
            variant="contained"
            disabled={busy}
            onClick={() => load(pageNumber)}
            color="secondary"
            size="small"
            sx={{ width: "140px" }}
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Transactions"
        paginated
        pageNumber={pageNumber}
        totalPages={pageCount}
        nextPage={(n) => load(n)}
        prevPage={(n) => load(n)}
        description="List of all the transactions."
        titleBadge={data.length + " Transactions"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
              <PFMInput
                placeholder="Search..."
                startIcon={IC_SEARCH}
                text={search}
                onUpdate={(t) => setSearch(t)}
                onReturn={() => {
                  load(pageNumber);
                }}
              />
              <Button
                size="large"
                variant="contained"
                disabled={busy}
                onClick={() => load(pageNumber)}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
            </Stack>
          )
        }
        tableHead={
          <TableRow>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Time
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Type
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Invoice ID
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Customer
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Amount
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderData(d))}</>}
      />
    </motion.div>
  );
}
