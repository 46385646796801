import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Radio,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { IC_ALERT, IC_CHECKMARK, IC_EDIT, IC_HELP } from "../../../assets/ui";
import PFMInput from "../../../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../../../components/PFMAutoComplete";
import { CurrencyBitcoin, Warning } from "@mui/icons-material";
import { IC_STRIPE } from "../../../assets/landing";
import {
  rtmCreateStripeAccount,
  rtmGetStripeDashboard,
  rtmGetStripeProfile,
  rtmUpdatePayments,
} from "../../../core/api/user";
import { rtmGetProfile } from "../../../core/api";
import { User } from "../../../types/User";
import { Role } from "../../../types/Role";

export default function SectionUserSettingsPayments() {
  const [busy, setBusy] = useState(false);

  const [profile, setProfile] = useState<User>();
  const [stripeProfile, setStripeProfile] = useState<any>();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [network, setNetwork] = useState<AutocompleteOption>();
  const [coin, setCoin] = useState<AutocompleteOption>();
  const [address, setAddress] = useState("");
  const [selectedCoins, setSelectedCoins] = useState<
    { label: string; id: string; network: string }[]
  >([]);

  const isCryptoActive = useMemo(() => {
    return address && network && coin;
  }, [profile, address, network, coin]);

  const isStripeActive = useMemo(() => {
    return (
      stripeProfile &&
      stripeProfile.capabilities &&
      stripeProfile.capabilities.card_payments === "active" &&
      stripeProfile.capabilities.transfers === "active" &&
      stripeProfile.payouts_enabled
    );
  }, [stripeProfile]);

  const [refresh, setRefresh] = useState(0);
  const [COINS, setCOINS] = useState<AutocompleteOption[]>([]);
  const [ALLCOINS] = useState<AutocompleteOption[]>([
    {
      label: "BTC",
      id: "BTC",
      network: "bitcoin",
    },
    // Ethereum
    {
      label: "ETH",
      id: "ETH",
      network: "ethereum",
    },
    {
      label: "USDT",
      id: "ETH_USDT",
      network: "ethereum",
    },
    {
      label: "USDC",
      id: "ETH_USDC",
      network: "ethereum",
    },
    // Polygon
    {
      label: "MATIC",
      id: "MATIC",
      network: "polygon",
    },
    {
      label: "USDC-POLYGON",
      id: "USDC-POLYGON",
      network: "polygon",
    },
    {
      label: "USDT",
      id: "USDT-POLYGON",
      network: "polygon",
    },
    // TRON
    {
      label: "TRX",
      id: "TRX",
      network: "tron",
    },
    {
      label: "USDT",
      id: "USDT-TRX",
      network: "tron",
    },
    {
      label: "USDC",
      id: "USDC-TRX",
      network: "tron",
    },

    // ARBI
    {
      label: "ETH",
      id: "ETH-ARBITRUM",
      network: "arbitrum",
    },
    {
      label: "USDC",
      id: "USDC-ARBITRUM",
      network: "arbitrum",
    },
    {
      label: "USDT",
      id: "USDT-ARBITRUM",
      network: "arbitrum",
    },
    // Ripple
    {
      label: "XRP",
      id: "XRP",
      network: "ripple",
    },
    // BSC
    {
      label: "BNB",
      id: "BNB",
      network: "bsc",
    },
    {
      label: "BUSD",
      id: "BUSD",
      network: "bsc",
    },
    {
      label: "USDC",
      id: "USDC-BSC",
      network: "bsc",
    },
    {
      label: "USDT",
      id: "USDT-BSC",
      network: "bsc",
    },
  ]);
  const [NETWORKS] = useState<AutocompleteOption[]>([
    {
      label: "Bitcoin",
      id: "bitcoin",
    },
    {
      label: "Ethereum",
      id: "ethereum",
    },
    {
      label: "Tron",
      id: "tron",
    },
    {
      label: "Arbitrum",
      id: "arbitrum",
    },
    {
      label: "Polygon",
      id: "polygon",
    },
    {
      label: "Ripple",
      id: "ripple",
    },
    {
      label: "Binance Smart Chain",
      id: "bsc",
    },
  ]);

  async function save() {
    try {
      if (!isCryptoActive) {
        enqueueSnackbar("Please provide valid wallet details. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      // save the details
      await rtmUpdatePayments({
        address: address,
        coins: selectedCoins,
        currency: coin!.id,
        network: network!.id,
      });
      enqueueSnackbar("Profile updated successfully.", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function load() {
    try {
      setBusy(true);
      const prof = await rtmGetProfile();
      // Load network
      setAddress(prof.business?.crypto?.wallet || "");
      setNetwork(NETWORKS.find((n) => n.id === prof.business?.crypto?.network));
      setCoin(ALLCOINS.find((c) => c.id === prof.business?.crypto?.currency));
      if (
        prof.business?.crypto &&
        prof.business?.crypto?.accepted_currencies?.length > 0
      ) {
        setSelectedCoins(
          ALLCOINS.filter(
            (c) =>
              prof.business!.crypto!.accepted_currencies.filter(
                (ac) => ac?.id === c?.id
              )?.length > 0
          ) as any
        );
      } else {
        setSelectedCoins([]);
      }
      setProfile(prof);

      // Load stripe
      const sProf = await rtmGetStripeProfile();
      setStripeProfile(sProf);
    } catch (err: any) {
      enqueueSnackbar("Error loading profile. Please try again.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function stripeDashboard() {
    try {
      setBusy(true);
      const link = await rtmGetStripeDashboard();
      window.open(link, "_blank");
    } catch (err: any) {
      enqueueSnackbar("Error generating link. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function updateStripe() {
    try {
      setBusy(true);
      // Use onboarding flow
      const link = await rtmCreateStripeAccount();
      window.open(link, "_blank");
    } catch (err: any) {
      enqueueSnackbar("Error generating link. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setCOINS([...ALLCOINS.filter((c) => c.network === network?.id)]);
    setRefresh(refresh + 1);
  }, [network]);

  return (
    <Stack sx={{ py: "12px", px: "32px" }} alignItems={"start"} spacing="18px">
      {profile && profile.role !== Role.Affiliate && (
        <>
          <Stack spacing={"8px"} alignItems={"start"}>
            <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
              <SvgIcon sx={{ width: "64px", height: "64px", p: 0, m: 0 }}>
                <IC_STRIPE />
              </SvgIcon>
              <Typography fontSize={18} fontWeight={500}>
                Connect
              </Typography>
            </Stack>

            <Typography fontSize={14}>
              Accept credit/debit card payments in over 43+ countries using
              Stripe Connect.
            </Typography>

            {/* If we are connected and active, we show  */}
            {isStripeActive && (
              <>
                <Typography display={"inline"}>
                  Connected with{" "}
                  <Typography display={"inline"} color="primary">
                    {stripeProfile?.email}
                  </Typography>
                </Typography>
                <Stack direction={"row"} spacing={"8px"}>
                  <Button
                    onClick={stripeDashboard}
                    variant="contained"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Express Dashboard
                  </Button>
                  <Button
                    onClick={updateStripe}
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Update Information
                  </Button>
                </Stack>
              </>
            )}

            {/* If the user has signed up already, but not active, we show warning, and option to update info  */}
            {!isStripeActive && profile?.business?.stripe?.accountId && (
              <>
                <Typography
                  color={"error"}
                  sx={{ alignItems: "center", display: "flex", gap: "8px" }}
                >
                  <SvgIcon>
                    <Warning />
                  </SvgIcon>
                  Please update your information or contact support.
                </Typography>
                <Button
                  disabled={busy}
                  onClick={updateStripe}
                  sx={{
                    alignSelf: "start",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                  variant="contained"
                >
                  Update Information
                </Button>
              </>
            )}

            {/* And if the user has not yet signed up, we show Signup button.  */}
            {!profile?.business?.stripe && (
              <Stack direction={"row"} spacing="12px">
                <Button
                  disabled={busy}
                  onClick={updateStripe}
                  variant="contained"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Signup
                </Button>
              </Stack>
            )}
          </Stack>

          <Divider sx={{ width: "100%" }} />
        </>
      )}

      <Stack spacing="12px" sx={{ width: "100%" }}>
        <Typography>Payout Wallet</Typography>
        <Stack
          direction={mobile ? "column" : "row"}
          spacing="8px"
          sx={{
            width: "500px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <PFMAutoComplete
              key={network as any}
              options={NETWORKS}
              multiple={false}
              value={network}
              onUpdate={(n) => {
                setNetwork(n as any);
                setCoin(undefined);
              }}
              fullWidth
              label="Network"
              placeholder="Select your preferred network..."
            />
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <PFMAutoComplete
              key={refresh}
              label="Coin"
              placeholder="Select coin..."
              options={COINS}
              value={coin}
              onUpdate={(t) => setCoin(t as any)}
            />
          </Stack>
        </Stack>

        <Stack sx={{ width: "100%" }}>
          <PFMInput
            text={address}
            onUpdate={(t) => setAddress(t)}
            sx={{
              width: "500px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            label="Address"
            placeholder="Enter your wallet address..."
          />
        </Stack>
      </Stack>

      {profile && profile.role !== Role.Affiliate && (
        <Stack spacing={"8px"} key={selectedCoins as any}>
          <Stack>
            <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
              <CurrencyBitcoin />
              <Typography fontSize={18} fontWeight={500}>
                Accepting Crypto
              </Typography>
            </Stack>

            <Typography fontSize={14} textAlign={"start"}>
              Accept payments worldwide with our deep integration with
              Cryptochill API, accepting most popular currencies through
              multiple networks, transferred to your preferred wallet.
            </Typography>
          </Stack>
          <Typography>Accepted Currencies</Typography>
          <Typography fontSize={14}>
            You can enable or disable the specific currencies for your bot by
            using the following buttons.
          </Typography>

          {/* TODO: Add coin selection buttons  */}
          {NETWORKS.map((n) => (
            <Stack spacing={"8px"}>
              <Typography fontWeight={500}>{n.label}</Typography>
              <Stack
                direction={"row"}
                rowGap={"8px"}
                columnGap={"8px"}
                flexWrap={"wrap"}
              >
                {/* Add each coin of this network */}
                {ALLCOINS.filter((c) => c.network === n.id).map((c) => (
                  <Button
                    onClick={() => {
                      if (
                        selectedCoins.filter((sc) => sc.id === c.id).length > 0
                      ) {
                        // remove
                        setSelectedCoins([
                          ...selectedCoins.filter((sc) => sc.id !== c.id),
                        ]);
                      } else {
                        // add
                        setSelectedCoins([
                          ...selectedCoins,
                          ALLCOINS.find((ac) => ac.id === c.id) as any,
                        ]);
                      }
                    }}
                    color={
                      selectedCoins.filter((sc) => sc.id === c.id).length > 0
                        ? "primary"
                        : "secondary"
                    }
                    variant="outlined"
                  >
                    {c.label} ({c.id})
                  </Button>
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
      <Button
        onClick={save}
        size="large"
        disabled={busy || !isCryptoActive}
        sx={{ alignSelf: "start" }}
        variant="contained"
      >
        Save
      </Button>
    </Stack>
  );
}
