import React, { useState } from "react";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import LinkButton from "../../components/LinkButton";
import { LOGO_RETINA } from "../../assets/brand";

export default function Footer() {
  const desktop = useMediaQuery("(min-width: 720px)");
  const [busy, setBusy] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <Stack
      id="footer"
      sx={{
        background: "#48F1",
        pt: "48px",
        pb: "24px",
      }}
    >
      <Stack
        spacing="48px"
        sx={{ maxWidth: "1280px", width: "100%", alignSelf: "center" }}
      >
        {/* The logo, its menu, and subscription container  */}
        <Stack
          direction={desktop ? "row" : "column"}
          justifyContent={desktop ? "space-around" : "center"}
          sx={{ flexWrap: "wrap" }}
          alignItems={"center"}
          columnGap={"32px"}
          rowGap={"32px"}
        >
          {/* Logo and Menu  */}
          <Stack alignItems={desktop ? undefined : "center"}>
            <img
              src={LOGO_RETINA}
              alt="cashify logo"
              style={{
                height: "120px",
                objectFit: "contain",
                alignSelf: desktop ? "flex-start" : "center",
              }}
            />
            <nav>
              <LinkButton label="Help" href="https://t.me/CashifySupportBot" />
              <LinkButton label="Terms" href="/terms" />
              <LinkButton label="Privacy" href="/privacy" />
            </nav>
          </Stack>
          {/* Subscribe  */}
          <Stack
            alignItems={desktop ? "start" : "center"}
            justifyContent={desktop ? "flex-start" : "center"}
            sx={{ maxWidth: "80%" }}
            spacing={"16px"}
          >
            <Typography textAlign={"center"}>
              Stay up to date by joining our Telegram channel
            </Typography>
            {/* <PFMInput
            text={email}
            onUpdate={(t) => setEmail(t)}
            disabled={busy}
            label="Stay up to date with our new services"
            placeholder="Enter your email address..."
          /> */}
            <Button
              sx={{
                px: "44px",
                height: "44px",
              }}
              variant="contained"
              href="https://t.me/CashifybotOfficial"
            >
              Join Now
            </Button>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          flexWrap={"wrap"}
          rowGap={"18px"}
        >
          <Typography textAlign={"center"} sx={{ maxWidth: "70%" }}>
            Cashifybot and its logo are registered trademarks of CASHIFYBOT LTD,
            Company No. 15776775, United Kingdom.
            <br />
            Copyright &copy; 2024. All rights reserved.{" "}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
