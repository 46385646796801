import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import AddUserDialog from "../../dialogs/AddUser";
import EditUserDialog from "../../dialogs/EditUser";
import SuspendedUserDialog from "../../dialogs/SuspendedUserDialog";
import {
  IC_FILTER,
  IC_FILTER_REMOVE,
  IC_REFRESH,
  IC_SEARCH,
  IC_SORT_A,
  IC_SORT_Z,
  IC_USER_ADD,
} from "../../assets/ui";
import { rtmListUsers } from "../../core/api/admin";
import { Filter, FilterList, SortByAlpha } from "@mui/icons-material";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import PFMInput from "../../components/PFMInput";
import PFMSelect from "../../components/PFMSelect";
import { User } from "../../types/User";

export default function SectionAdminUsers() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<User[]>([]);
  const { showModal } = useModal();

  const [sort, setSort] = useState<{
    type:
      | "lastSigninTimestamp"
      | "registeredTimestamp"
      | "name"
      | "plan"
      | "business.crypto.balance"
      | "status"
      | "role";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    type: "status" | "role" | "plan" | "balanceGte" | "balanceLte";
    value: any;
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  const [filterPopoverAnchor, setfilterPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  async function loadUsers(page: number) {
    try {
      //Load data
      setBusy(true);
      const users = await rtmListUsers({
        page: page,
        sort: sort,
        filter: filter,
        search: search,
      });
      if (users) {
        setTotalPages(users.pageCount);
        setPageNumber(users.currentPage);
        setTotalUsers(users.total);
        setData(users.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showAddUserDialog() {
    const modal = showModal(AddUserDialog, {
      closeHandler(result) {
        modal.destroy();
        loadUsers(pageNumber);
      },
    });
  }

  function showEditDialog(usr: User) {
    if (usr.suspended) {
      const modal = showModal(SuspendedUserDialog, {
        closeHandler(result) {
          modal.destroy();
          loadUsers(pageNumber);
        },
        data: usr,
      });
    } else {
      const modal = showModal(EditUserDialog, {
        closeHandler(result) {
          modal.destroy();
          loadUsers(pageNumber);
        },
        data: usr,
      });
    }
  }

  useEffect(() => {
    setSection(`Identity and Access Management`);
    setSectionDescription(
      `Manage users who has access to the platform with roles.`
    );

    // Load page 0
    loadUsers(0);
  }, []);

  useEffect(() => {
    loadUsers(pageNumber);
  }, [sort, filter, search]);

  function RenderUser(user: User) {
    const amnt = parseStripeAmount(user.business?.crypto?.balance || 0);
    return (
      <TableRow
        onClick={() => showEditDialog(user)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(user.uid)}
          >
            {user.uid.substring(0, 4)}...
            {user.uid.substring(user.uid.length - 4)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={
                user.suspended
                  ? "Suspended"
                  : user.disabled
                  ? "Disabled"
                  : user.emailVerified
                  ? "Active"
                  : "Unverified"
              }
              variant="outlined"
              color={
                user.suspended
                  ? "error"
                  : user.disabled
                  ? "warning"
                  : user.emailVerified
                  ? "success"
                  : "info"
              }
              size="small"
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(user.registeredTimestamp)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(user.lastSigninTimestamp)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip label={user.role} variant="identifier" size="small" />
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {getCurrencySymbol(user.currency || "usd")}
            {amnt.dollars}.{amnt.cents}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {user.plan && user.plan.currentPeriodEnd > moment().unix()
              ? "Pro"
              : "Basic"}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadUsers(pn)}
        prevPage={(pn) => loadUsers(pn)}
        titleBadge={totalUsers + " Users"}
        title="Users"
        description="List of all the users on the platform."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <IconButton
              onClick={(e) => {
                if (filter) {
                  setFilter(undefined);
                } else {
                  setfilterPopoverAnchor(e.currentTarget);
                }
              }}
            >
              <SvgIcon>
                {filter && <IC_FILTER_REMOVE />}
                {!filter && <IC_FILTER />}
              </SvgIcon>
            </IconButton>
            <Popover
              open={Boolean(filterPopoverAnchor)}
              anchorEl={filterPopoverAnchor}
              onClose={() => setfilterPopoverAnchor(null)}
            >
              <Stack sx={{ px: "24px", py: "12px" }}>
                <Typography>Filter Users Where</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
                  <PFMSelect
                    value={filter?.type}
                    onChange={(ce) =>
                      setFilter({ type: ce?.id as any, value: filter?.value })
                    }
                    options={[
                      { label: "Role", id: "role" },
                      { label: "Plan", id: "plan" },
                    ]}
                  />
                  <Typography>is</Typography>
                  {/* We show options for plan and role, and input for balance  */}
                  {filter?.type === "plan" && (
                    <PFMSelect
                      value={filter?.value ? "true" : "false"}
                      onChange={(ce) =>
                        setFilter({
                          type: filter.type,
                          value: ce?.id === "true" ? true : false,
                        })
                      }
                      options={[
                        { label: "Pro / Premium", id: "true" },
                        { label: "Basic / Free", id: "false" },
                      ]}
                    />
                  )}
                  {filter?.type === "role" && (
                    <PFMSelect
                      value={filter?.value}
                      onChange={(ce) =>
                        setFilter({
                          type: filter.type,
                          value: ce?.id,
                        })
                      }
                      options={[
                        { label: "Admin", id: "administrator" },
                        { label: "User", id: "user" },
                        { label: "Affiliate", id: "affiliate" },
                      ]}
                    />
                  )}
                </Stack>
              </Stack>
            </Popover>
            <PFMInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={IC_SEARCH}
              placeholder="Search user..."
            />
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => loadUsers(pageNumber)}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
            <Button
              sx={{ width: "200px", height: "48px" }}
              onClick={showAddUserDialog}
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={
                <SvgIcon>
                  <IC_USER_ADD />
                </SvgIcon>
              }
            >
              Add User
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "name",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>User</Typography>
                {sort?.type === "name" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "status",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Status</Typography>
                {sort?.type === "status" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "registeredTimestamp",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Registered</Typography>
                {sort?.type === "registeredTimestamp" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "lastSigninTimestamp",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Last Activity</Typography>
                {sort?.type === "lastSigninTimestamp" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Role  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "role",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Role</Typography>
                {sort?.type === "role" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Balance  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "business.crypto.balance",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Balance</Typography>
                {sort?.type === "business.crypto.balance" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Plan  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "plan",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Plan</Typography>
                {sort?.type === "plan" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderUser(d))}</>}
      />
    </Stack>
  );
}
