import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";

import { enqueueSnackbar } from "notistack";
import PFMInput from "../../components/PFMInput";
import { IC_ALERT, IC_HELP, IMG_BOT_MENU } from "../../assets/ui";
import { rtmGetTelegramConfig, rtmUpdateBotMenu } from "../../core/api/user";
import { logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";
import { TelegramConfig } from "../../types/TelegramConfig";

export default function OnboardingStepPersonalize(props: {
  next: () => void;
  back: () => void;
}) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [token, setToken] = useState("");
  const [menuText, setMenuText] = useState("");
  const [help, setHelp] = useState("");
  const [about, setAbout] = useState("");
  const [bio, setBio] = useState("");
  const [description, setDescription] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [cfg, setCfg] = useState<TelegramConfig>();

  async function load() {
    try {
      // Load existing configuratio
      const _cfg = await rtmGetTelegramConfig();
      if (_cfg) {
        setCfg(_cfg);
        setMenuText(_cfg.mainMenuText || "");
        setHelp(_cfg.helpMenuText || "");
        setAbout(_cfg.aboutMenuText || "");
        setBio(_cfg.botBio || "");
        setDescription(_cfg.botDescription || "");
        setPrivacy(_cfg.privacy || "");
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading configuration. Please try again.", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading telegram configuration from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
  }

  async function saveConfig() {
    try {
      setBusy(true);
      await rtmUpdateBotMenu(menuText, help, about, bio, description, privacy);
      enqueueSnackbar("Configuration updated successfully.", {
        variant: "success",
      });
      props.next();
    } catch (err: any) {
      enqueueSnackbar("Error updating configuration. Please try again. ", {
        variant: "error",
      });
      console.error(err);
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error saving telegram configuration from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Personalize Your Bot
      </Typography>
      <Typography sx={{ my: "8px" }}>
        It's time to personalize your bot so it matches your channel's
        requirements and appearance.
        <br />
        <br />
        <span style={{ color: "#4488FF" }}>Cashifybot</span> allows you to
        change the main menu, help, and about text of your bot to fit your
        needs. <br />
        However, if you want to update your bot's appearance, such as Profile
        Picture and description, you could use @BotFather to do so.
        <br />
        <br />
        <span style={{ color: "#4488FF" }}>Note: </span> you can change these
        settings any time later in the Settings &gt; Configuration
      </Typography>
      <Stack sx={{ my: "8px" }}>
        <Stack spacing="8px">
          {cfg && (
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <Avatar src={cfg.displayPicture || ""} />
              <Typography>
                Using bot token for{" "}
                <span style={{ color: "#4488FF" }}>@{cfg.username}</span>
              </Typography>
            </Stack>
          )}
          <Alert
            sx={{ alignSelf: "start" }}
            icon={
              <SvgIcon>
                <IC_ALERT />
              </SvgIcon>
            }
          >
            <Typography>
              To change bot's name and profile picture, please use @BotFather.
            </Typography>
          </Alert>
        </Stack>
      </Stack>
      <Stack spacing="8px">
        <Stack spacing={"14px"}>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography>Bio / About</Typography>
            <Tooltip
              arrow
              placement="right"
              title="The text is shown in the about text of the chat."
            >
              <SvgIcon>
                <IC_HELP />
              </SvgIcon>
            </Tooltip>
          </Stack>
          <PFMInput
            disabled={busy}
            multiline
            text={bio}
            onUpdate={(t) => setBio(t)}
            rows={2}
            sx={{ width: "500px" }}
          />
        </Stack>
        <Stack spacing={"14px"}>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography>Bot Description</Typography>
            <Tooltip
              arrow
              placement="right"
              title="The text is shown in the bot chat with new users."
            >
              <SvgIcon>
                <IC_HELP />
              </SvgIcon>
            </Tooltip>
          </Stack>
          <PFMInput
            disabled={busy}
            multiline
            text={description}
            onUpdate={(t) => setDescription(t)}
            rows={2}
            sx={{ width: "500px" }}
          />
        </Stack>

        <Stack spacing={"82px"} direction={"row"}>
          <Stack spacing={"16px"}>
            <Stack spacing={"8px"}>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography>Main Menu Text</Typography>
                <Tooltip
                  arrow
                  placement="right"
                  title="The text is shown in the main menu. E.g. when user sends /start.  "
                >
                  <SvgIcon>
                    <IC_HELP />
                  </SvgIcon>
                </Tooltip>
              </Stack>
              <PFMInput
                disabled={busy}
                multiline
                text={menuText}
                onUpdate={(t) => setMenuText(t)}
                rows={5}
                sx={{ width: "500px" }}
                helperText="Supports basic HTML Syntax. "
              />
            </Stack>

            <Stack spacing={"8px"}>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography>About Text</Typography>
                <Tooltip
                  arrow
                  placement="right"
                  title="The text shown in the about menu of the bot."
                >
                  <SvgIcon>
                    <IC_HELP />
                  </SvgIcon>
                </Tooltip>
              </Stack>
              <PFMInput
                disabled={busy}
                multiline
                text={about}
                onUpdate={(t) => setAbout(t)}
                rows={5}
                sx={{ width: "500px" }}
                helperText="Supports basic HTML Syntax. "
              />
            </Stack>

            <Stack spacing={"8px"}>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography>Help Text</Typography>
                <Tooltip
                  arrow
                  placement="right"
                  title="The text shown in the help menu of the bot."
                >
                  <SvgIcon>
                    <IC_HELP />
                  </SvgIcon>
                </Tooltip>
              </Stack>
              <PFMInput
                disabled={busy}
                multiline
                text={help}
                onUpdate={(t) => setHelp(t)}
                rows={5}
                sx={{ width: "500px" }}
                helperText="Supports basic HTML Syntax. "
              />
            </Stack>

            <Stack spacing={"8px"}>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography>Privacy Policy</Typography>
                <Tooltip
                  arrow
                  placement="right"
                  title="Your bot's privacy policy. Telegram may restrict your bot without a privacy policy."
                >
                  <SvgIcon>
                    <IC_HELP />
                  </SvgIcon>
                </Tooltip>
              </Stack>
              <PFMInput
                disabled={busy}
                multiline
                text={privacy}
                onUpdate={(t) => setPrivacy(t)}
                rows={5}
                sx={{ width: "500px" }}
                helperText="Supports basic HTML Syntax. "
              />
            </Stack>
          </Stack>
          <Stack justifyContent={"center"}>
            <img src={IMG_BOT_MENU} />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={"row"}
        spacing="8px"
        sx={{ my: "18px" }}
        alignItems={"center"}
      >
        <Button
          disabled={busy}
          onClick={props.back}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Back
        </Button>
        <Button
          disabled={busy}
          onClick={props.next}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Skip
        </Button>
        {!busy && (
          <Button
            disabled={busy}
            onClick={saveConfig}
            variant="contained"
            sx={{ alignSelf: "start" }}
          >
            Next
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
