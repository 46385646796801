import axios from "axios";
import { TransactionalEmailKey } from "../types/TransactionalEmail";

export function parseStripeAmount(cents: number) {
  const dollars = Math.floor(cents / 100);
  const remainingCents = cents % 100;
  return { dollars, cents: Math.abs(Math.floor(remainingCents)) };
}
export function getCurrencySymbol(currencyCode: string): string {
  try {
    // Use the Intl.NumberFormat to get the currency symbol for the given currency code.
    const currencyFormatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
    });
    const parts = currencyFormatter.formatToParts(1);

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === "currency") {
        return parts[i].value;
      }
    }
  } catch (error) {
    // Handle any errors, such as unsupported currency codes.
    console.error(
      `Error getting currency symbol for ${currencyCode}: ${error}`
    );
  }

  // Return an empty string if the currency symbol couldn't be determined.
  return "";
}

export function getColorByTag(tag: string) {
  switch (tag.toLowerCase()) {
    case "error":
      return "#F55";
    case "information":
      return "#48F";
    case "warning":
      return "gold";
    case "crash":
      return "#F00";
    default:
      return "#48F";
  }
}

export async function SendWebhookMessage(
  hook: string,
  title: string,
  text: string,
  color: number,
  fields: { name: string; value: string; inline?: boolean }[]
) {
  try {
    if (Boolean(hook) === false) throw new Error("Invalid webhook address.");
    await axios.post(hook, {
      content: null,
      embeds: [
        {
          title: title,
          description: text,
          color: color,
          fields: fields,
        },
      ],
      username: "Cashify Contact Form",
      attachments: [],
    });
  } catch (err: any) {}
}

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname: string) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie(cname: string) {
  let user = getCookie(cname);
  if (user != "") {
    return true;
  } else {
    return false;
  }
}

export function GetTransactionalEmailKeyDescription(
  key: TransactionalEmailKey
) {
  switch (key) {
    case TransactionalEmailKey.emailVerification:
      return "Email Verification";
    case TransactionalEmailKey.invoiceCreated:
      return "Invoice (Crypto) Created";
    case TransactionalEmailKey.invoicePaid:
      return "Invoice (Crypto) Paid";
    case TransactionalEmailKey.newSignin:
      return "New Sign In";
    case TransactionalEmailKey.passwordReset:
      return "Password Reset Request";
    case TransactionalEmailKey.paymentFailed:
      return "Payment Failed (Stripe)";
    case TransactionalEmailKey.payoutCompleted:
      return "Payout Completed (Crypto)";
    case TransactionalEmailKey.payoutProcessing:
      return "Payout Processing (Crypto)";
    case TransactionalEmailKey.payoutRequested:
      return "Payout Requested (Crypto)";
    case TransactionalEmailKey.postPasswordReset:
      return "Password Changed";
    case TransactionalEmailKey.welcome:
      return "Welcome";
  }
}

export function GetTransactionalEmailVariables(key: TransactionalEmailKey) {
  switch (key) {
    case TransactionalEmailKey.emailVerification:
      return ["{{link}}"];
    case TransactionalEmailKey.invoiceCreated:
      return [
        "{{id}}",
        "{{date}}",
        "{{plan}}",
        "{{due_date}}",
        "{{amount}}",
        "{{network}}",
        "{{currency}}",
        "{{address}}",
        "{{link}}",
      ];
    case TransactionalEmailKey.invoicePaid:
      return [
        "{{id}}",
        "{{date}}",
        "{{plan}}",
        "{{txid}}",
        "{{amount}}",
        "{{currency}}",
      ];
    case TransactionalEmailKey.newSignin:
      return ["{{location}}", "{{device}}", "{{ip}}"];
    case TransactionalEmailKey.passwordReset:
      return ["{{link}}"];
    case TransactionalEmailKey.paymentFailed:
      return ["{{link}}"];
    case TransactionalEmailKey.payoutCompleted:
      return [
        "{{network}}",
        "{{address}}",
        "{{amount}}",
        "{{date}}",
        "{{link}}",
      ];
    case TransactionalEmailKey.payoutProcessing:
      return [
        "{{network}}",
        "{{address}}",
        "{{amount}}",
        "{{date}}",
        "{{link}}",
      ];
    case TransactionalEmailKey.payoutRequested:
      return ["{{network}}", "{{address}}", "{{amount}}", "{{date}}"];
    case TransactionalEmailKey.postPasswordReset:
      return [];
    case TransactionalEmailKey.welcome:
      return ["{{link}}"];
  }
}
