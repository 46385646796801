import {
  Button,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import DualHeading from "../../components/DualHeading";
import {
  IC_AFFILIATE,
  IC_CUSTOMIZE,
  IC_DASHBOARD,
  IC_PAYMENT,
  IC_STRIPE,
  IC_SUBSCRIPTION,
  IC_UNLOCK,
} from "../../assets/landing";
import YouTubePlayer from "react-player/youtube";
import { motion } from "framer-motion";
export default function Demo() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="demo"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        bgcolor: "#111A",
        py: "48px",
      }}
    >
      <Stack>
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          <DualHeading
            sx={{ fontSize: desktop ? "36px" : "24px", textAlign: "center" }}
            h1="Curious to know"
            h2="more?"
            h2sx={{ color: "#48F" }}
          />
        </motion.div>
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          <Typography fontSize={desktop ? 16 : 14} textAlign={"center"}>
            Watch this video to explore the features and benefits of Cashifybot.
          </Typography>
        </motion.div>
      </Stack>
      <Paper
        style={{
          borderRadius: "8px",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: desktop ? undefined : "80%",
        }}
      >
        <YouTubePlayer
          style={{ maxWidth: "100%" }}
          controls
          url={"https://youtu.be/Ba595bCVhFU"}
        />
      </Paper>
      <Stack
        alignItems={"center"}
        style={{ maxWidth: desktop ? "40%" : "90%" }}
      >
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          <Typography
            fontSize={desktop ? 20 : 14}
            fontWeight={400}
            style={{ textAlign: "center" }}
          >
            Explore additional videos and tutorials on our YouTube channel for
            an in-depth look into Cashifybot's functionalities.
          </Typography>
        </motion.div>
        <Button
          href="https://youtube.com/channel/UCewTH2sb2knfPGlJC7KTWGA"
          sx={{ borderRadius: "25px" }}
        >
          YouTube Channel
        </Button>
      </Stack>
    </Stack>
  );
}
