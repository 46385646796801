import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Paper,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  ToggleButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aPaymentsConfig,
  aProfile,
} from "../../core/states";
import { useNavigate } from "react-router";
import { useModal } from "mui-modal-provider";
import {
  IC_AFFILIATE_BALANCE,
  IC_ALERT,
  IC_CROWN,
  IC_PAID,
  IC_PENDING,
  IC_SETTINGS,
  IMG_AFFILIATION,
} from "../../assets/ui";
import moment from "moment";
import PFMTable from "../../components/PFMTable";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";

import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { motion } from "framer-motion";
import { rtmGetProfile } from "../../core/api";
import {
  rtmGetAffiliateStats,
  rtmGetFeatureFlag,
  rtmListReferrers,
  rtmListTelegramPayouts,
  rtmListTelegramRewards,
} from "../../core/api/user";
import { LogMessageKind, PaginatedResponse } from "@zexcore/types";
import LoadingView from "../../components/LoadingView";
import SetupAffiliationDialog from "../../dialogs/SetupAffiliation";
import CreatePayoutDialog from "../../dialogs/CreatePayout";
import TelegramPayoutDetailsDialog from "../../dialogs/TelegramPayoutDetails";
import { logEvent, logMessage } from "@zexcore/sdk";
import { TelegramUser } from "../../types/TelegramUser";
import { TelegramReward } from "../../types/TelegramReward";
import { TelegramPayout } from "../../types/TelegramPayout";

export default function SectionUserAffiliateTools() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [config] = useRecoilState(aPaymentsConfig);

  const [range, setRange] = useState<"affiliates" | "payouts" | "rewards">(
    "affiliates"
  );

  const [affiliates, setAffiliates] =
    useState<PaginatedResponse<TelegramUser>>();
  const [rewards, setRewards] = useState<
    PaginatedResponse<
      TelegramReward & {
        userInfo: TelegramUser;
        sourceUserInfo: TelegramUser;
      }
    >
  >();
  const [payouts, setPayouts] = useState<PaginatedResponse<TelegramPayout>>();

  const [stats, setStats] = useState<{
    pending: number;
    paid: number;
    balance: number;
  }>();

  const currentBalance = useMemo(() => {
    if (stats) {
      const amnt = parseStripeAmount(stats.balance || 0);
      return `${getCurrencySymbol(profile?.currency!)}${amnt.dollars}.${
        amnt.cents
      }`;
    }
    return `${getCurrencySymbol(profile?.currency!)}0.0`;
  }, [stats]);

  const paidOut = useMemo(() => {
    if (stats) {
      const amnt = parseStripeAmount(stats.paid || 0);
      return `${getCurrencySymbol(profile?.currency!)}${amnt.dollars}.${
        amnt.cents
      }`;
    }
    return `${getCurrencySymbol(profile?.currency!)}0.0`;
  }, [stats]);

  const pending = useMemo(() => {
    if (stats) {
      const amnt = parseStripeAmount(stats.pending || 0);
      return `${getCurrencySymbol(profile?.currency!)}${amnt.dollars}.${
        amnt.cents
      }`;
    }
    return `${getCurrencySymbol(profile?.currency!)}0.0`;
  }, [stats]);

  const nav = useNavigate();
  const { showModal } = useModal();

  async function loadOnce() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error(err);
      logMessage({
        kind: LogMessageKind.Error,
        message:
          "Error loading user profile or feature flag in affiliate section. ",
        stack: err,
        tags: ["affiliate tools"],
      });
    }
  }

  async function load(_page: number) {
    try {
      setBusy(true);
      if (range === "affiliates") {
        // Load referrals
        const r = await rtmListReferrers(_page);
        setAffiliates(r);
      } else if (range === "rewards") {
        const e = await rtmListTelegramRewards(_page);
        setRewards(e);
      } else if (range === "payouts") {
        const ps = await rtmListTelegramPayouts(_page);
        setPayouts(ps);
      }
      const r = await rtmGetAffiliateStats();
      setStats(r);
    } catch (err: any) {
      console.error("Error loading affiliation data. ", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading data in affiliate section. ",
        stack: err,
        tags: ["affiliate tools"],
      });
    }
    setBusy(false);
  }

  function showSetupDialog() {
    const modal = showModal(SetupAffiliationDialog, {
      closeHandler(result) {
        modal.destroy();
        load(0);
      },
    });
  }

  function showCreatePayoutDialog() {
    const modal = showModal(CreatePayoutDialog, {
      closeHandler(result) {
        modal.destroy();
        load(0);
      },
    });
  }

  function showPayoutDetailsDialog(payout: TelegramPayout) {
    const modal = showModal(TelegramPayoutDetailsDialog, {
      closeHandler(result) {
        modal.destroy();
        load(0);
      },
      data: payout,
    });
  }

  useEffect(() => {
    setSection(`Affiliation Tools`);
    setSectionDescription(
      `Grow your telegram channels with your own affiliate program.`
    );
    loadOnce().then(() => load(0));
    logEvent({
      name: "page_view",
      identifier: "affiliate_tools",
    });
  }, []);

  useEffect(() => {
    load(0);
  }, [range]);

  function RenderReward(
    rew: TelegramReward & {
      userInfo: TelegramUser;
      sourceUserInfo: TelegramUser;
    }
  ) {
    const amnt = parseStripeAmount(rew.amount);
    return (
      <TableRow>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(rew.created).format("MMM DD, YYYY hh:mm:ss A Z")}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={rew.userInfo.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {rew.userInfo.name}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#94969C"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 8,
                  },
                }}
              >
                @{rew.userInfo.username || rew.userInfo.id}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar
              src={rew.sourceUserInfo.image || ""}
              sx={{ color: "white" }}
            />
            <Stack>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {rew.sourceUserInfo.name}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#94969C"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 8,
                  },
                }}
              >
                @{rew.sourceUserInfo.username || rew.sourceUserInfo.id}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {rew.subscription}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {rew.gateway?.toUpperCase()}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {getCurrencySymbol(rew.currency)}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderReferral(ref: TelegramUser) {
    const amnt = parseStripeAmount(ref.affiliate?.balance || 0);
    const earnings = parseStripeAmount(ref.affiliate?.earnings || 0);
    const sym = getCurrencySymbol(profile?.currency!);
    return (
      <TableRow>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={ref.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {ref.name}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#94969C"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 8,
                  },
                }}
              >
                @{ref.username || ref.id}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {moment.unix(ref.created!).format("MMM DD, YYYY hh:mm:ss A Z")}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {ref.affiliate?.code}
        </TableCell>
        <TableCell>{ref.affiliate?.referrals || 0}</TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {earnings.dollars}.{earnings.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderPayout(pay: TelegramPayout) {
    const amnt = parseStripeAmount(pay.amount);
    const sym = getCurrencySymbol(pay.currency);
    return (
      <TableRow
        onClick={() => showPayoutDetailsDialog(pay)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {pay.id}
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
            label={pay.status}
            size="small"
            variant="identifier"
          />
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {moment.unix(pay.created).format("MMM DD, YYYY hh:mm:ss A Z")}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {moment.unix(pay.updated).format("MMM DD, YYYY hh:mm:ss A Z")}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {pay.type === "crypto" ? "Cashifybot" : "External"}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  if (!profile) {
    return <LoadingView />;
  }

  // If the user has not yet enabled Affiliate Tools, we show them the button.
  if (!profile?.affiliation) {
    return (
      <motion.div
        initial={{ opacity: 0, translateX: -20 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 20 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            zIndex: 5,
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"20px"}
        >
          <Typography fontWeight={500} fontSize={28}>
            Grow your channels faster!
          </Typography>
          <Stack
            sx={{ width: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              fontSize={18}
              sx={{ maxWidth: "50%" }}
              textAlign={"center"}
            >
              Empower your channel's growth with Cashifybot's powerful affiliate
              tools. Encourage your subscribers to refer new members and reward
              them for their efforts. With our affiliate program, you can
              incentivize user referrals, expand your channel's reach, and boost
              engagement like never before.
            </Typography>
          </Stack>
          <Stack alignItems={"center"} spacing={"8px"}>
            <Button
              disabled={busy}
              variant="contained"
              size="large"
              onClick={showSetupDialog}
            >
              Enable Affiliation
            </Button>
          </Stack>
        </Stack>
      </motion.div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, translateX: -20 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 20 }}
        transition={{ duration: 0.5 }}
        style={{
          flex: 1,
          display: "flex",
          padding: "12px",
          gap: mobile ? "14px" : "32px",
          flexDirection: "column",
        }}
      >
        <Stack
          direction={"row"}
          rowGap={mobile ? "12px" : "24px"}
          columnGap={mobile ? "12px" : "24px"}
          flexWrap={"wrap"}
        >
          <motion.div
            style={{ width: mobile ? "100%" : undefined }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.1, delay: 0.1 }}
            exit={{ scale: 0 }}
          >
            <Paper
              sx={{
                minHeight: "180px",
                minWidth: "300px",
                background: "#FFF1",
                borderRadius: "8px",
                border: "1px solid #FFF0",
                transition: "all .3s",
                ":hover": {
                  border: "1px solid #48DA",
                },
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  width: "100%",
                  minHeight: "auto",
                },
              }}
            >
              <Stack sx={{ p: "24px" }} spacing={"24px"}>
                <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                  <SvgIcon
                    sx={{
                      background: "#48D",
                      height: "48px",
                      width: "48px",
                      p: "12px",
                      borderRadius: "8px",
                    }}
                  >
                    <IC_AFFILIATE_BALANCE />
                  </SvgIcon>
                  <Typography fontWeight={500} fontSize={20}>
                    Balances
                  </Typography>
                  <Tooltip title="The total amount earned by of all your affiliates at this moment that has not been paid out. ">
                    <SvgIcon>
                      <IC_ALERT />
                    </SvgIcon>
                  </Tooltip>
                </Stack>
                <Stack>
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                    spacing={"8px"}
                  >
                    <Typography fontWeight={500} fontSize={24}>
                      {currentBalance}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </motion.div>

          <motion.div
            style={{ width: mobile ? "100%" : undefined }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.1, delay: 0.3 }}
            exit={{ scale: 0 }}
          >
            <Paper
              sx={{
                minHeight: "180px",
                minWidth: "300px",
                background: "#FFF1",
                borderRadius: "8px",
                border: "1px solid #FFF0",
                transition: "all .3s",
                ":hover": {
                  border: "1px solid #025",
                },
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  width: "100%",
                  minHeight: "auto",
                },
              }}
            >
              <Stack sx={{ p: "24px" }} spacing={"24px"}>
                <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                  <SvgIcon
                    sx={{
                      background: "#025",
                      height: "48px",
                      width: "48px",
                      p: "12px",
                      borderRadius: "8px",
                    }}
                  >
                    <IC_PENDING />
                  </SvgIcon>
                  <Typography fontWeight={500} fontSize={20}>
                    Payouts Pending
                  </Typography>
                </Stack>
                <Stack>
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                    spacing={"8px"}
                  >
                    <Typography fontWeight={500} fontSize={24}>
                      {pending}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </motion.div>

          <motion.div
            style={{ width: mobile ? "100%" : undefined }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.1, delay: 0.4 }}
            exit={{ scale: 0 }}
          >
            <Paper
              sx={{
                minHeight: "180px",
                minWidth: "300px",
                background: "#FFF1",
                borderRadius: "8px",
                border: "1px solid #FFF0",
                transition: "all .3s",
                ":hover": {
                  border: "1px solid #0A5A",
                },
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  width: "100%",
                  minHeight: "auto",
                },
              }}
            >
              <Stack sx={{ p: "24px" }} spacing={"24px"}>
                <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                  <SvgIcon
                    sx={{
                      background: "#0A5",
                      height: "48px",
                      width: "48px",
                      p: "12px",
                      borderRadius: "8px",
                    }}
                  >
                    <IC_PAID />
                  </SvgIcon>
                  <Typography fontWeight={500} fontSize={20}>
                    Paid Out
                  </Typography>
                </Stack>
                <Stack>
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                    spacing={"8px"}
                  >
                    <Typography fontWeight={500} fontSize={24}>
                      {paidOut}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </motion.div>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              gap: "8px",
            },
          }}
        >
          <Stack
            direction={"row"}
            spacing={"12px"}
            sx={{
              [theme.breakpoints.down("md")]: {
                alignSelf: "flex-start",
              },
            }}
          >
            <Button
              onClick={showCreatePayoutDialog}
              variant="contained"
              endIcon={
                <SvgIcon>
                  <IC_PAID />
                </SvgIcon>
              }
            >
              Create Payout
            </Button>
            <Button
              onClick={showSetupDialog}
              variant="outlined"
              endIcon={
                <SvgIcon>
                  <IC_SETTINGS />
                </SvgIcon>
              }
            >
              Configure
            </Button>
            {!profile.affiliation.enabled && (
              <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                <SvgIcon color="warning">
                  <IC_ALERT />
                </SvgIcon>
                <Typography>Affiliation has been disabled.</Typography>
              </Stack>
            )}
          </Stack>

          <StyledToggleButtonGroup
            sx={{
              alignSelf: "flex-end",
              [theme.breakpoints.down("md")]: {
                alignSelf: "flex-start",
              },
            }}
            exclusive
            value={range}
            onChange={(c, e) => setRange(e ? e : range)}
          >
            <ToggleButton value={"affiliates"}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                spacing="16px"
                sx={{
                  px: "16px",
                  [theme.breakpoints.down("md")]: {
                    px: "8px",
                  },
                }}
              >
                {range === "affiliates" && (
                  <Badge
                    variant="dot"
                    color={range === "affiliates" ? "success" : undefined}
                  ></Badge>
                )}
                <Typography
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Affiliates
                </Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton value={"payouts"}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                spacing="16px"
                sx={{
                  px: "16px",
                  [theme.breakpoints.down("md")]: {
                    px: "8px",
                  },
                }}
              >
                {range === "payouts" && (
                  <Badge
                    variant="dot"
                    color={range === "payouts" ? "success" : undefined}
                  ></Badge>
                )}
                <Typography
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Payouts
                </Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton value={"rewards"}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                spacing="16px"
                sx={{
                  px: "16px",
                  [theme.breakpoints.down("md")]: {
                    px: "8px",
                  },
                }}
              >
                {range === "rewards" && (
                  <Badge
                    variant="dot"
                    color={range === "rewards" ? "success" : undefined}
                  ></Badge>
                )}
                <Typography
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Earnings
                </Typography>
              </Stack>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>

        {/* Render Referrals */}
        {range === "affiliates" && (
          <PFMTable
            sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
            title="Your Affiliates"
            description="Your affiliated users on telegram."
            tableHead={
              <TableRow>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  User
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Registered
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Referral Code
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Referrals
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Balance
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Total Earnings
                </TableCell>
              </TableRow>
            }
            tableBody={affiliates?.data?.map((r) => RenderReferral(r))}
          />
        )}

        {/* Render Earnings */}
        {range === "rewards" && (
          <PFMTable
            sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
            title="User Earnings"
            description="View earnings of your affiliates."
            tableHead={
              <TableRow>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  User
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Source
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Subscription
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Gateway
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Amount
                </TableCell>
              </TableRow>
            }
            tableBody={rewards?.data?.map((r) => RenderReward(r))}
          />
        )}

        {/* Render Payouts */}
        {range === "payouts" && (
          <PFMTable
            sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
            title="User Payouts"
            description="View and manage payout requests from your users."
            tableHead={
              <TableRow>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Created
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Updated
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Gateway
                </TableCell>
                <TableCell
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                    },
                  }}
                >
                  Amount
                </TableCell>
              </TableRow>
            }
            tableBody={payouts?.data?.map((p) => RenderPayout(p))}
          />
        )}
      </motion.div>
    </>
  );
}
