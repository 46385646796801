import React, { useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  CircularProgress,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { IC_ALERT, IC_HELP, IMG_BOT_MENU } from "../../../assets/ui";
import PFMInput from "../../../components/PFMInput";
import { rtmGetTelegramConfig, rtmUpdateBotMenu } from "../../../core/api/user";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../../../authentication/firebase";
import { TelegramConfig } from "../../../types/TelegramConfig";
import { DEFAULT_PRIVACY_POLICY } from "../../../core/default_privacy_policy";

export default function SectionUserSettingsConfig() {
  const [busy, setBusy] = useState(false);
  const [editingToken, setEditingToken] = useState(false);
  const [savingToken, setSavingToken] = useState(false);
  const [tokenSaved, setTokenSaved] = useState(false);
  const [token, setToken] = useState("");
  const [menuText, setMenuText] = useState("");
  const [privacyText, setPrivacyText] = useState("");
  const [help, setHelp] = useState("");
  const [about, setAbout] = useState("");
  const [bio, setBio] = useState("");
  const [description, setDescription] = useState("");
  const [cfg, setCfg] = useState<TelegramConfig>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  async function load() {
    try {
      const _cfg = await rtmGetTelegramConfig();
      if (_cfg) {
        setToken(_cfg.token);
        setCfg(_cfg);
        setMenuText(_cfg.mainMenuText || "");
        setHelp(_cfg.helpMenuText || "");
        setAbout(_cfg.aboutMenuText || "");
        setBio(_cfg.botBio || "");
        setDescription(_cfg.botDescription || "");
        setPrivacyText(_cfg.privacy || DEFAULT_PRIVACY_POLICY);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading configuration. Please try again.", {
        variant: "error",
      });
    }
  }

  async function saveConfig() {
    try {
      setBusy(true);
      await rtmUpdateBotMenu(
        menuText,
        help,
        about,
        bio,
        description,
        privacyText
      );
      enqueueSnackbar("Configuration updated successfully.", {
        variant: "success",
      });
    } catch (err: any) {
      enqueueSnackbar("Error updating configuration. Please try again. ", {
        variant: "error",
      });
      console.error("Error updating bot configuration. ");
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack sx={{ py: "12px", px: "32px" }} alignItems={"start"} spacing="8px">
      <Stack direction={"row"} alignItems={"center"} spacing="8px">
        <Typography
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 14,
            },
          }}
        >
          Bot Token
        </Typography>
        <Tooltip
          arrow
          placement="right"
          title="The bot token is used to log in to the telegram bot. This cannot be changed."
        >
          <SvgIcon>
            <IC_HELP />
          </SvgIcon>
        </Tooltip>
      </Stack>

      <Stack spacing="8px">
        <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
          <Stack>
            <Avatar
              sx={{ height: "32px", width: "32px" }}
              src={cfg?.displayPicture}
            />
          </Stack>
          <Stack
            flex={1}
            sx={{ height: "100%" }}
            justifyContent={"space-around"}
          >
            <Stack direction={"row"} spacing={"8px"}>
              <Stack spacing={"8px"} alignItems={"start"}>
                <Typography
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                    },
                  }}
                >
                  Signed in as @{cfg?.username}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <PFMInput
          disabled={busy || !editingToken}
          text={token}
          onUpdate={(t) => {
            setToken(t);
          }}
          sx={{
            width: "380px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
          helperText="The bot token cannot be changed."
        />
        <Alert
          icon={
            <SvgIcon>
              <IC_ALERT />
            </SvgIcon>
          }
        >
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 12,
              },
            }}
          >
            To change bot's name and profile picture, please use @BotFather.
          </Typography>
        </Alert>
      </Stack>

      <Stack spacing={"14px"} sx={{ width: "100%" }}>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
            }}
          >
            Bio / About
          </Typography>
          <Tooltip
            arrow
            placement="right"
            title="The text is shown in the about text of the chat."
          >
            <SvgIcon>
              <IC_HELP />
            </SvgIcon>
          </Tooltip>
        </Stack>
        <PFMInput
          disabled={busy}
          multiline
          text={bio}
          onUpdate={(t) => setBio(t)}
          rows={2}
          sx={{
            width: "500px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        />
      </Stack>

      <Stack spacing={"14px"} sx={{ width: "100%" }}>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
            }}
          >
            Bot Description
          </Typography>
          <Tooltip
            arrow
            placement="right"
            title="The text is shown in the bot chat with new users."
          >
            <SvgIcon>
              <IC_HELP />
            </SvgIcon>
          </Tooltip>
        </Stack>
        <PFMInput
          disabled={busy}
          multiline
          text={description}
          onUpdate={(t) => setDescription(t)}
          rows={2}
          sx={{
            width: "500px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        />
      </Stack>

      <Stack
        direction={"row"}
        spacing={"32px"}
        sx={{
          width: "100%",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Stack spacing={"14px"}>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                },
              }}
            >
              Main Menu Text
            </Typography>
            <Tooltip
              arrow
              placement="right"
              title="The text is shown in the main menu. E.g. when user sends /start.  "
            >
              <SvgIcon>
                <IC_HELP />
              </SvgIcon>
            </Tooltip>
          </Stack>
          <PFMInput
            disabled={busy}
            multiline
            text={menuText}
            onUpdate={(t) => setMenuText(t)}
            rows={5}
            sx={{
              width: "500px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            helperText="Supports basic HTML Syntax. "
          />
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                },
              }}
            >
              About Text
            </Typography>
            <Tooltip
              arrow
              placement="right"
              title="The text shown in the about menu of the bot."
            >
              <SvgIcon>
                <IC_HELP />
              </SvgIcon>
            </Tooltip>
          </Stack>
          <PFMInput
            disabled={busy}
            multiline
            text={about}
            onUpdate={(t) => setAbout(t)}
            rows={5}
            sx={{
              width: "500px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            helperText="Supports basic HTML Syntax. "
          />
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                },
              }}
            >
              Help Text
            </Typography>
            <Tooltip
              arrow
              placement="right"
              title="The text shown in the help menu of the bot."
            >
              <SvgIcon>
                <IC_HELP />
              </SvgIcon>
            </Tooltip>
          </Stack>
          <PFMInput
            disabled={busy}
            multiline
            text={help}
            onUpdate={(t) => setHelp(t)}
            rows={5}
            sx={{
              width: "500px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            helperText="Supports basic HTML Syntax. "
          />

          {/* Privacy */}
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                },
              }}
            >
              Privacy Policy
            </Typography>
            <Tooltip
              arrow
              placement="right"
              title="Your bot's privacy policy. Telegram may restrict your bot without a privacy policy."
            >
              <SvgIcon>
                <IC_HELP />
              </SvgIcon>
            </Tooltip>
          </Stack>
          <PFMInput
            disabled={busy}
            multiline
            text={privacyText}
            onUpdate={(t) => setPrivacyText(t)}
            rows={5}
            sx={{
              width: "500px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            helperText="Supports basic HTML Syntax. "
          />
        </Stack>
        <Stack justifyContent={"center"}>
          <img src={IMG_BOT_MENU} />
        </Stack>
      </Stack>

      <Button
        onClick={saveConfig}
        disabled={busy}
        sx={{ alignSelf: "start" }}
        variant="contained"
      >
        Save
      </Button>
    </Stack>
  );
}
