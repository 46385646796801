export type TransactionalEmail = {
  key: TransactionalEmailKey;
  template: string;
  from?: string;
  subject: string;
};

export enum TransactionalEmailKey {
  emailVerification = "email-verification",
  welcome = "welcome",
  passwordReset = "password-reset",
  newSignin = "new-signin",
  postPasswordReset = "post-password-reset",
  invoiceCreated = "invoice-created",
  invoicePaid = "invoice-paid",
  paymentFailed = "payment-failed",
  payoutRequested = "payout-requested",
  payoutProcessing = "payout-processing",
  payoutCompleted = "payout-completed",
}
