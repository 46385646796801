import React, { useEffect, useState } from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { Check, Info } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";

import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { rtmFinishOnboarding } from "../../core/api/user";
import YouTubePlayer from "react-player/youtube";

export default function OnboardingStepFinish(props: { next: () => void }) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const nav = useNavigate();

  async function finish() {
    try {
      // Set the boarded to true.
      setBusy(true);
      await rtmFinishOnboarding();
      // Navigate to dashboard
      nav("/dashboard");
      enqueueSnackbar("Welcome to Cashifybot!", { variant: "info" });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error processing your request. Please try again. ", {
        variant: "error",
      });
    }
  }

  return (
    <Stack flex={1} sx={{ width: "100%" }}>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Ready to go!
      </Typography>
      <Typography sx={{ my: "8px" }}>
        Thank you for choosing Cashifybot. Click the button below to finish the
        onboarding process and explore the dashboard.
      </Typography>

      <Typography fontSize={22} sx={{ mt: "32px" }}>
        Dashboard Overview
      </Typography>
      <Typography sx={{ my: "8px" }}>
        Watch the video below to learn how to navigate through the Cashifybot
        Dashboard
      </Typography>
      <YouTubePlayer controls url={"https://youtu.be/oQEDEiOYr6k"} />

      <Button
        onClick={finish}
        disabled={busy}
        variant="contained"
        sx={{ alignSelf: "start", mt: "32px" }}
      >
        Finish
      </Button>
    </Stack>
  );
}
