import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Paper,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  ToggleButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aPaymentsConfig,
  aProfile,
} from "../../core/states";
import { useNavigate } from "react-router";
import { useModal } from "mui-modal-provider";
import { IC_AFFILIATE_BALANCE, IC_PAID, IC_PENDING } from "../../assets/ui";
import moment from "moment";
import PFMTable from "../../components/PFMTable";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";

import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { motion } from "framer-motion";
import RequestPayoutDialog from "../../dialogs/RequestPayout";
import BalanceErrorDialog from "../../dialogs/BalanceError";
import { rtmListPayouts } from "../../core/api/admin";
import PayoutDetailsDialog from "../../dialogs/PayoutDetails";
import { Payout } from "../../types/Payout";
import { User } from "../../types/User";

export default function SectionAdminPayouts() {
  const [profile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);

  const [range, setRange] = useState<"pending" | "complete">("pending");

  const [pageNumber, setPageNumber] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [payouts, setPayouts] = useState<(Payout & { user: User })[]>([]);
  const [config] = useRecoilState(aPaymentsConfig);

  const theme = useTheme();
  const nav = useNavigate();
  const { showModal } = useModal();

  async function load(page: number) {
    try {
      setBusy(true);
      const ps = await rtmListPayouts(
        page,
        range === "complete" ? "complete" : "all"
      );
      if (ps) {
        setPageCount(ps.pageCount);
        setDataCount(ps.total);
        setPayouts(ps.data);
        setPageNumber(ps.currentPage);
      }
    } catch (err: any) {
      console.error("Error loading payouts data. ", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function showRequestPayoutDlg() {
    // get min balance
    const minBalance = config.payouts.minPayout;
    if (
      !profile?.business?.crypto?.balance ||
      profile?.business?.crypto?.balance < minBalance
    ) {
      const modal = showModal(BalanceErrorDialog, {
        closeHandler(result) {
          modal.destroy();
        },
      });
    } else {
      const modal = showModal(RequestPayoutDialog, {
        data: { account: "affiliation" },
        closeHandler(result) {
          modal.destroy();
        },
      });
    }
  }

  async function showPayoutDetails(po: any) {
    const modal = showModal(PayoutDetailsDialog, {
      data: po,
      closeHandler(result) {
        modal.destroy();
        load(pageNumber);
      },
    });
  }

  useEffect(() => {
    setSection(`Payouts`);
    setSectionDescription(`View and manage pending and completed payouts.`);
    load(0);
  }, []);

  useEffect(() => {
    load(0);
  }, [range]);

  function RenderPayout(pay: Payout & { user: User }) {
    const amnt = parseStripeAmount(pay.amount);
    return (
      <TableRow
        onClick={() => {
          // Show the payout dialog
          showPayoutDetails(pay);
        }}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Chip label={pay.id} size="small" variant="identifier" />
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={pay.user?.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {pay.user?.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {pay.user?.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Chip label={pay.status} size="small" variant="identifier" />
        </TableCell>
        <TableCell>
          {moment.unix(pay.created).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell>
          {moment.unix(pay.updated).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell>
          {pay.payout_network.toUpperCase()}/{pay.payout_currency.toUpperCase()}
        </TableCell>
        <TableCell>
          {getCurrencySymbol(pay.currency)}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, translateX: -20 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 20 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            p: "12px",
            height: "100%",
            transition: "all 1s",
          }}
          spacing={"32px"}
        >
          <Stack
            direction={"row"}
            rowGap={"24px"}
            columnGap={"24px"}
            flexWrap={"wrap"}
          >
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.1 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  transition: "all .3s",
                  border: "1px solid #FFF0",
                  ":hover": {
                    border: "1px solid #48FA",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#48F",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_AFFILIATE_BALANCE />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Balances
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}></Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.2 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  border: "1px solid #FFF0",
                  borderRadius: "8px",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #448A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#448",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PENDING />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Pending
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}></Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.3 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  border: "1px solid #FFF0",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #0A5A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#0A5",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PAID />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Total Paid
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}></Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={"12px"}>
              <Button
                onClick={showRequestPayoutDlg}
                disabled={busy}
                variant="contained"
                endIcon={
                  <SvgIcon>
                    <IC_PAID />
                  </SvgIcon>
                }
              >
                Batch Payout
              </Button>
            </Stack>

            <StyledToggleButtonGroup
              sx={{ alignSelf: "flex-end" }}
              exclusive
              value={range}
              onChange={(c, e) => setRange(e)}
            >
              <ToggleButton value={"pending"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{ px: "16px" }}
                >
                  {range === "pending" && (
                    <Badge
                      variant="dot"
                      color={range === "pending" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography>Pending</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"complete"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{ px: "16px" }}
                >
                  {range === "complete" && (
                    <Badge
                      variant="dot"
                      color={range === "complete" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography>Complete</Typography>
                </Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>

          {/* Render Payouts */}
          <PFMTable
            sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
            title="Payouts"
            description={
              range === "complete"
                ? "List of all the completed payouts."
                : "List of all the payouts currently pending."
            }
            pageNumber={pageNumber}
            paginated
            totalPages={pageCount}
            titleBadge={dataCount + " Payouts"}
            nextPage={(pn) => load(pn)}
            prevPage={(pn) => load(pn)}
            tableHead={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last updated</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            }
            tableBody={payouts.map((p) => RenderPayout(p))}
          />
        </Stack>
      </motion.div>
    </>
  );
}
