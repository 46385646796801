import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import Header from "./header";
import Hero from "./hero";
import Features from "./features";
import Demo from "./demo";
import Pricing from "./pricing";
import Faq from "./faq";
import ContactUs from "./contact";
import Footer from "./footer";
import GoToTop from "./fab";
import Partner from "./partner";
import { Helmet } from "react-helmet";
import Migration from "./migration";
import CookieConsentForm from "../../components/CookieConsent";
import { setCookie } from "../../core/helper";

const ogData = {
  title: "Cashifybot | Telegram Subscriptions Bot",
  description:
    "Monetize your Telegram groups and channels effortlessly with our user-friendly subscription bot. Seamlessly manage subscriptions, subscribers, and analytics all in one easy-to-navigate web application.",
  url: "https://cashifybot.com",
  image: "https://cashifybot.com/brand/logo.png",
  siteName: "Cashifybot",
};
export default function LandingPage() {
  // Set cookie based on source
  const url = new URLSearchParams(window.location.search);
  if (url.has("src")) {
    setCookie("traffic_utm_", url.get("src")?.toString() || "", 30);
    if (url.get("src")?.toString() === "reddit") {
      (window as any).rdt("track", "PageVisit");
    } else if (url.get("src")?.toString() === "google") {
      (window as any).gtag?.("event", "page_view", {
        send_to: "AW-394810679/eb3hCK60t8gZELeqobwB",
      });
    }
  }
  return (
    <Stack>
      <Helmet>
        <title>{ogData.title}</title>
        <meta name="description" content={ogData.description} />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
      </Helmet>
      <CookieConsentForm />
      <Header />
      <Hero />
      <Features />
      <Demo />
      <Migration />
      <Pricing />
      {/* <Partner /> */}
      <Faq />
      <ContactUs />
      <Footer />
      <GoToTop />
    </Stack>
  );
}
