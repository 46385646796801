import React, { ReactElement } from "react";
import { Button, Divider, Link, Paper, Stack, Typography } from "@mui/material";

export default function InsightCard(props: {
  icon?: ReactElement;
  title?: string;
  gbp?: string;
  usd?: string;
  eur?: string;
  subheading?: any;
  accent?: string;
  actionText?: string;
  action?: () => void;
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "352px",
        background: "#101828",
        borderTop: "4px solid " + props.accent,
        borderRadius: "12px",
        p: "24px",
      }}
    >
      <Stack spacing="24px">
        <Stack direction={"row"} alignItems={"center"} spacing="12px">
          <Stack
            sx={{
              width: "48px",
              height: "48px",
              background: props.accent,
              borderRadius: "10px",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {props.icon}
          </Stack>
          <Typography fontSize={16} fontWeight={600}>
            {props.title}
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography fontSize={12} fontWeight={400}>
              GBP
            </Typography>
            <Typography fontSize={22} fontWeight={600}>
              {props.gbp}
            </Typography>
          </Stack>
          <Stack>
            <Typography fontSize={12} fontWeight={400}>
              USD
            </Typography>
            <Typography fontSize={22} fontWeight={600}>
              {props.usd}
            </Typography>
          </Stack>
          <Stack>
            <Typography fontSize={12} fontWeight={400}>
              EUR
            </Typography>
            <Typography fontSize={22} fontWeight={600}>
              {props.eur}
            </Typography>
          </Stack>
        </Stack>
        {props.action && (
          <>
            <Stack flex={1} />
            <Stack>
              <Divider />
              <Link
                underline="none"
                color="inherit"
                onClick={props.action}
                sx={{ alignSelf: "flex-end", cursor: "pointer", py: "10px" }}
              >
                {props.actionText} -&gt;
              </Link>
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
}

export function SingleInsightCard(props: {
  icon?: ReactElement;
  title?: string;
  value?: string;
  accent?: string;
  actionText?: string;
  action?: () => void;
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        background: "#101828",
        borderTop: "4px solid " + props.accent,
        borderRadius: "12px",
        p: "24px",
        height: "100%",
      }}
    >
      <Stack spacing="24px">
        <Stack direction={"row"} alignItems={"center"} spacing="12px">
          <Stack
            sx={{
              width: "48px",
              height: "48px",
              background: props.accent,
              borderRadius: "10px",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {props.icon}
          </Stack>
          <Typography fontSize={16} fontWeight={600}>
            {props.title}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
          <Typography fontSize={28} fontWeight={600}>
            {props.value}
          </Typography>
        </Stack>
        {props.action && (
          <>
            <Stack flex={1} />
            <Stack>
              <Divider />
              <Link
                underline="none"
                color="inherit"
                onClick={props.action}
                sx={{ alignSelf: "flex-end", cursor: "pointer", py: "10px" }}
              >
                {props.actionText} -&gt;
              </Link>
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
}
