import { CryptochillAmount } from "./Callback";
import { TransactionsEntity } from "./TransactionsEntity";

export interface CryptochillInvoice {
  id: string;
  kind: string;
  created_at: string;
  profile_id: string;
  address: string;
  lightning?: null;
  status: CCInvoiceStatus;
  expires_at: string;
  network: string;
  amount: CryptochillAmount;
  custom_fee?: null;
  min_confirmations?: null;
  zero_conf_enabled?: null;
  notes?: null;
  passthrough: string;
  transactions?: TransactionsEntity[] | null;
}

/**
 * Represents the status of nowpayments invoice on network.
 */
export enum CCInvoiceStatus {
  pending = "pending",
  confirmed = "confirmed",
  complete = "complete",
  incomplete = "incomplete",
  expired = "expired",
}
