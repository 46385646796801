import React, { useRef } from "react";
import { Typography, TypographyProps } from "@mui/material";

import { useEffect, useState } from "react";

let currentIndex = 0;

export default function RotatingText(props: {
  texts: string[];
  delay: number;
  speed: number;
  repeatDelay: number;
  typographyProps: TypographyProps;
  pre?: string;
}) {
  const [currentText, setCurrentText] = useState("");
  const getCurrentText = useRef("");

  useEffect(() => {
    let _interval = setInterval(async () => {
      if (getCurrentText.current) {
        // Retract.
        let oldText = getCurrentText.current;
        while (oldText.length > 0) {
          oldText = oldText.substring(0, oldText.length - 1);
          setCurrentText(oldText);
          await new Promise((resolve) => setTimeout(resolve, props.speed));
        }
      }
      let txt = "";
      // Change text
      while (true) {
        // If txt is complete, we break.
        txt = props.texts[currentIndex].substring(0, txt.length + 1);
        setCurrentText(txt);
        // wait for speed delay
        await new Promise((resolve) => setTimeout(resolve, props.speed));
        if (txt === props.texts[currentIndex]) {
          break;
        }
      }
      if (currentIndex === props.texts.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, props.repeatDelay));
      }
      // Increment
      currentIndex =
        currentIndex + 1 >= props.texts.length - 1 ? 0 : currentIndex + 1;
    }, props.delay);

    return () => clearInterval(_interval);
  }, []);

  useEffect(() => {
    getCurrentText.current = currentText;
  }, [currentText]);

  return (
    <Typography {...props.typographyProps} style={{ caretShape: "underscore" }}>
      {props.pre} {currentText}
      {"|"}
    </Typography>
  );
}
