import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Check, Info } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";

import { enqueueSnackbar } from "notistack";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../../components/PFMAutoComplete";
import { IC_ALERT } from "../../assets/ui";
import { rtmSetCurrency } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import YouTubePlayer from "react-player/youtube";

export default function OnboardingStepBilling(props: { next: () => void }) {
  const [profile, setProfile] = useRecoilState(aProfile);

  const [CURRENCIES] = useState<AutocompleteOption[]>([
    {
      label: "USD $",
      id: "usd",
    },
    {
      label: "GBP £",
      id: "gbp",
    },
    {
      label: "Euro €",
      id: "eur",
    },
  ]);

  const [currency, setCurrency] = useState<AutocompleteOption>();

  const whyChooseUs = [
    "Get paid quickly and securely with our Stripe and Crypto payments integration.",
    "Accept card payments in over 40+ countries, and crypto payments worldwide.",
    "Easy to use with powerful, user-friendly dashboard.",
    "Create unlimited subscriptions for any number of channels.",
    "You are charged per transactions only. No fixed charges for the starters. ",
    "Partner with Cashifybot and earn more through its Partnership Program.",
    "And so much more!",
  ];

  async function saveCurrency(id: string) {
    try {
      await rtmSetCurrency(id);
    } catch (err: any) {
      enqueueSnackbar("Error updating currency. Please try later. ", {
        variant: "error",
      });
      console.error("Error updating user's currency on onboarding page. ", err);
    }
  }

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      if (prof.currency) {
        setCurrency(
          CURRENCIES.find((c) => c.id === prof.currency) || CURRENCIES[0]
        );
      } else {
        setCurrency(CURRENCIES[0]);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading profile. Please try again. ", {
        variant: "error",
      });
    }
  }
  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack flex={1}>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Welcome to Cashifybot!
      </Typography>
      <Typography sx={{ my: "8px" }}>
        The ultimate solution to telegram subscriptions.
      </Typography>

      <Stack direction={"row"} flex={1} spacing={"32px"} sx={{ pr: "32px" }}>
        <Stack flex={1}>
          <Typography fontSize={22} sx={{ mt: "32px" }}>
            Why choose Cashifybot?
          </Typography>
          <Stack sx={{ mt: "16px" }} spacing={"8px"}>
            {whyChooseUs.map((w) => (
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Check color="primary" />
                <Typography>{w}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Typography fontSize={22} sx={{ mt: "32px" }}>
            Quick Start
          </Typography>
          <Typography sx={{ my: "8px" }}>
            Watch the video below for a quick demonstration of how to set up
            Cashifybot.
          </Typography>
          <YouTubePlayer
            width={"100%"}
            style={{ width: "100%" }}
            controls
            url={"https://youtu.be/Vc31GzXJfIk"}
          />
        </Stack>
      </Stack>

      <Button
        disabled={!currency}
        onClick={() => {
          saveCurrency(currency?.id || "usd").then(props.next);
        }}
        variant="contained"
        sx={{ alignSelf: "start", mt: "32px" }}
      >
        Get Started
      </Button>
    </Stack>
  );
}
