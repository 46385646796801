import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Chip,
  IconButton,
  Link,
  Paper,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aPaymentsConfig,
  aProfile,
} from "../../core/states";
import { useNavigate } from "react-router";
import { useModal } from "mui-modal-provider";
import {
  IC_ALERT,
  IC_BALANCE,
  IC_CODE,
  IC_COPY,
  IC_LINK,
  IC_PAID,
} from "../../assets/ui";
import moment from "moment";
import PFMTable from "../../components/PFMTable";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";

import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { motion } from "framer-motion";
import RequestPayoutDialog from "../../dialogs/RequestPayout";
import { FbAnalytics } from "../../authentication/firebase";
import { rtmGetProfile } from "../../core/api";
import BalanceErrorDialog from "../../dialogs/BalanceError";
import {
  rtmCreateAffiliateProfile,
  rtmGetAffiliateProfile,
  rtmListPayouts,
  rtmListReferrals,
  rtmListRewards,
} from "../../core/api/user";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";
import { Reward } from "../../types/Affiliation/Reward";
import { Payout } from "../../types/Payout";
import { Affiliate } from "../../types/Affiliation/Affiliate";
import { Role } from "../../types/Role";

export default function SectionUserPartnership() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [config] = useRecoilState(aPaymentsConfig);

  const [range, setRange] = useState<"referrals" | "earnings" | "payouts">(
    "referrals"
  );

  const [referrals, setReferrals] = useState<
    { user: string; registered: number; outcome: number }[]
  >([]);
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [affiliate, setAffiliate] = useState<Affiliate | false>();

  const currentBalance = useMemo(() => {
    if (affiliate) {
      const amnt = parseStripeAmount(affiliate.balance || 0);
      return `${getCurrencySymbol("usd")}${amnt.dollars}.${amnt.cents}`;
    }
    return `${getCurrencySymbol("usd")}0.0`;
  }, [affiliate]);

  const withdrawn = useMemo(() => {
    if (affiliate) {
      const amnt = parseStripeAmount(affiliate.withdrawn || 0);
      return `${getCurrencySymbol("usd")}${amnt.dollars}.${amnt.cents}`;
    }
    return `${getCurrencySymbol("usd")}0.0`;
  }, [affiliate]);

  const pending = useMemo(() => {
    if (affiliate) {
      const amnt = parseStripeAmount(affiliate.pendingWithdraw || 0);
      return `${getCurrencySymbol("usd")}${amnt.dollars}.${amnt.cents}`;
    }
    return `${getCurrencySymbol("usd")}0.0`;
  }, [affiliate]);

  const theme = useTheme();
  const nav = useNavigate();
  const { showModal } = useModal();

  async function load() {
    try {
      setBusy(true);
      const a = await rtmGetAffiliateProfile();
      setAffiliate(a);
      if (range === "referrals") {
        // Load referrals
        const r = await rtmListReferrals();
        setReferrals(r || []);
      } else if (range === "earnings") {
        const e = await rtmListRewards();
        setRewards(e || []);
      } else if (range === "payouts") {
        const ps = await rtmListPayouts();
        setPayouts(ps || []);
      }
      const prof = await rtmGetProfile();
      setProfile(prof);
      if (prof && prof.role === Role.Affiliate && !a) {
        const r = await rtmCreateAffiliateProfile();
        if (r) {
          const aff = await rtmGetAffiliateProfile();
          setAffiliate(aff);
        }
      }
    } catch (err: any) {
      console.error("Error loading affiliation data. ", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading data for partnership section. ",
        stack: err,
        tags: ["partnership"],
      });
    }
    setBusy(false);
  }

  async function signup() {
    try {
      setBusy(true);
      const a = await rtmCreateAffiliateProfile();
      if (a === true) {
        enqueueSnackbar(
          "You have successfully joined the partnership program. ",
          { variant: "success" }
        );
        load();
        logEvent({
          name: "partnership signup",
          description:
            "User signed up for partnership from affiliation section.",
          identifier: profile?.uid,
        });
      }
    } catch (err: any) {
      console.error("Error signing up for affiliation. ", err);
      enqueueSnackbar("Error signing up for affiliation. Please try again. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message:
          "Error signing up for partnership from invite and earn section. ",
        stack: err,
        tags: ["partnership"],
      });
    }
    setBusy(false);
  }

  async function showRequestPayoutDlg() {
    if (
      !profile?.business?.crypto ||
      !profile.business.crypto.wallet ||
      !profile.business.crypto.currency
    ) {
      enqueueSnackbar("Profile is not set up. ", { variant: "error" });
      return;
    }
    // get min balance
    const minBalance = config.payouts.minPayout;
    if (!affiliate || affiliate.balance < minBalance) {
      const modal = showModal(BalanceErrorDialog, {
        closeHandler(result) {
          modal.destroy();
        },
      });
    } else {
      const modal = showModal(RequestPayoutDialog, {
        data: { account: "affiliation" },
        closeHandler(result) {
          modal.destroy();
          load();
        },
      });
    }
  }

  useEffect(() => {
    setSection(`Invite & Earn`);
    setSectionDescription(
      `Refer users and earn 30% recurring commission on every Pro Plan upgrade.`
    );
    load();
    logEvent({
      name: "page_view",
      identifier: "partnership",
    });
  }, []);

  useEffect(() => {
    load();
  }, [range]);

  function RenderReward(rew: Reward) {
    const amnt = parseStripeAmount(rew.amount);
    return (
      <TableRow>
        <TableCell>{rew.sourceId}</TableCell>
        <TableCell>
          {moment.unix(rew.created).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell>
          {getCurrencySymbol(rew.currency)}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderReferral(ref: {
    user: string;
    registered: number;
    outcome: number;
  }) {
    const amnt = parseStripeAmount(ref.outcome);
    return (
      <TableRow>
        <TableCell>{ref.user}</TableCell>
        <TableCell>
          {moment.unix(ref.registered).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell>
          {getCurrencySymbol("usd")}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderPayout(pay: Payout) {
    const amnt = parseStripeAmount(pay.amount);
    return (
      <TableRow>
        <TableCell>{pay.id}</TableCell>
        <TableCell>{pay.status}</TableCell>
        <TableCell>
          {moment.unix(pay.created).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell>{pay.payout_address}</TableCell>
        <TableCell>
          {getCurrencySymbol(pay.currency)}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, translateX: -20 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 20 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        {!affiliate && !busy && (
          <Stack
            sx={{
              zIndex: 5,
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={"40px"}
          >
            <Stack
              sx={{ width: "100%" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={500} fontSize={28}>
                Unlock recurring commissions!
              </Typography>
              <Typography
                fontSize={18}
                sx={{ maxWidth: "60%" }}
                textAlign={"center"}
              >
                Join our partnership program, become an affiliate, and earn a
                commission from every transaction made by customers referred by
                you.
              </Typography>
            </Stack>
            <Stack alignItems={"center"} spacing={"8px"}>
              <Button disabled={busy} onClick={signup} variant="contained">
                Sign up
              </Button>
              <Typography fontSize={12}>
                By pressing Signup, you agree to the{" "}
                <Link color="#48F" href="/terms">
                  Terms &amp; Conditions
                </Link>{" "}
                of Cashifybot's Partnership Program
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{
            p: "12px",
            height: "100%",
            transition: "all 1s",
            filter: affiliate === false ? "blur(10px)" : undefined,
            opacity: affiliate === false ? 0.3 : undefined,
          }}
          spacing={"32px"}
        >
          <Stack
            direction={"row"}
            rowGap={"24px"}
            columnGap={"24px"}
            flexWrap={"wrap"}
          >
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.1, delay: 0.1 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minHeight: "180px",
                  minWidth: "300px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  border: "1px solid #FFF0",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #48DA",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#48D",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_BALANCE />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Balance
                    </Typography>
                    {profile?.business?.stripe?.accountId && (
                      <Tooltip title="The balance and earnings here are shown in our platform currency (USD) rather than your profile currency. This is because our affiliate and payments system uses USD only.">
                        <SvgIcon>
                          <IC_ALERT />
                        </SvgIcon>
                      </Tooltip>
                    )}
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}>
                        {currentBalance}
                      </Typography>
                    </Stack>
                    {affiliate && affiliate.pendingWithdraw > 0 && (
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={"8px"}
                      >
                        <Typography fontSize={14}>{pending}</Typography>
                        <Tooltip title="Pending withdrawal">
                          <Chip
                            label="Pending Withdrawal"
                            variant="identifier"
                            size="small"
                            sx={{ fontSize: 10 }}
                          />
                        </Tooltip>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>

            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.3 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minHeight: "180px",
                  minWidth: "300px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  border: "1px solid #FFF0",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #0A5A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#0A5",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PAID />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Total Withdrawn
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}>
                        {withdrawn}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>

            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.4 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minHeight: "180px",
                  minWidth: "300px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  border: "1px solid #FFF0",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #025",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#025",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_CODE />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Referral Code
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={20}>
                        {(affiliate as Affiliate)?.code}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard?.writeText(
                            (affiliate as Affiliate)?.code
                          );
                          enqueueSnackbar("Code copied to clipboard!");
                        }}
                      >
                        <IC_COPY />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard?.writeText(
                            "https://cashifybot.com/signup?r=" +
                              (affiliate as Affiliate)?.code
                          );
                          enqueueSnackbar("Link copied to clipboard!");
                        }}
                      >
                        <IC_LINK />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={"12px"}>
              <Button
                onClick={showRequestPayoutDlg}
                disabled={busy || !affiliate}
                variant="contained"
                endIcon={
                  <SvgIcon>
                    <IC_PAID />
                  </SvgIcon>
                }
              >
                Request a Payout
              </Button>
            </Stack>

            <StyledToggleButtonGroup
              sx={{ alignSelf: "flex-end" }}
              exclusive
              value={range}
              onChange={(c, e) => setRange(e ? e : range)}
            >
              <ToggleButton value={"referrals"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{ px: "16px" }}
                >
                  {range === "referrals" && (
                    <Badge
                      variant="dot"
                      color={range === "referrals" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography>Referrals</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"earnings"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{ px: "16px" }}
                >
                  {range === "earnings" && (
                    <Badge
                      variant="dot"
                      color={range === "earnings" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography>Earnings</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"payouts"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{ px: "16px" }}
                >
                  {range === "payouts" && (
                    <Badge
                      variant="dot"
                      color={range === "payouts" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography>Payouts</Typography>
                </Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>

          {/* Render Referrals */}
          {range === "referrals" && (
            <PFMTable
              sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
              title="Referrals"
              description="List of your referred users."
              tableHead={
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Registered</TableCell>
                  <TableCell>Outcome</TableCell>
                </TableRow>
              }
              tableBody={referrals.map((r) => RenderReferral(r))}
            />
          )}

          {/* Render Earnings */}
          {range === "earnings" && (
            <PFMTable
              sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
              title="Earnings"
              description="View your earnings from referred users."
              tableHead={
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              }
              tableBody={rewards.map((r) => RenderReward(r))}
            />
          )}

          {/* Render Payouts */}
          {range === "payouts" && (
            <PFMTable
              sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
              title="Payouts"
              description="View your payouts history and current payout requests."
              tableHead={
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              }
              tableBody={payouts.map((p) => RenderPayout(p))}
            />
          )}
        </Stack>
      </motion.div>
    </>
  );
}
