import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowForward, ArrowRight, Star } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { SCR_DASHBOARD } from "../../assets/landing";
import { IC_CREDIT_CARD, IC_USERS } from "../../assets/ui";
import { motion } from "framer-motion";
import DualHeading from "../../components/DualHeading";
import ReactPlayer from "react-player";
import RotatingText from "../../components/RotatingText";

export default function Hero() {
  const [scrollOffset, setScrollOfset] = useState(window.scrollY);
  const desktop = useMediaQuery("(min-width: 1280px)");
  const tablet = useMediaQuery("(min-width: 1024px)");
  const nav = useNavigate();
  const playerRef = useRef<ReactPlayer>();
  function handleScroll() {
    setScrollOfset(Math.ceil(window.scrollY));
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Stack
      id="hero"
      sx={{
        overflow: "hidden",
        maxWidth: desktop || tablet ? "1280px" : "90%",
        alignSelf: "center",
        pt: desktop ? "48px" : "24px",
        width: "100%",
      }}
      justifyContent={desktop ? "space-between" : "start"}
      direction={desktop ? "row" : "column-reverse"}
      spacing={"32px"}
    >
      {/* The hero content */}
      <Stack flex={1} spacing={"18px"}>
        <Stack>
          <DualHeading
            sx={{
              fontSize: desktop ? 38 : tablet ? 32 : 24,
              fontWeight: 500,
              textAlign: desktop || tablet ? "start" : "center",
            }}
            h1="Unlock Success with"
            h2="Cashifybot."
            component={"h1"}
            h2sx={{ color: "#48F" }}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={desktop ? "start" : "center"}
            spacing={"8px"}
          >
            <Typography
              sx={{
                fontSize: desktop ? 28 : tablet ? 24 : 18,
              }}
            >
              Start selling
            </Typography>
            <RotatingText
              pre=" "
              repeatDelay={5000}
              delay={3000}
              speed={40}
              typographyProps={{
                sx: {
                  fontSize: desktop ? 28 : tablet ? 24 : 18,
                  color: "#48F",
                  borderRadius: "12px",
                },
              }}
              texts={[
                "Channel Subscriptions!",
                "License Keys!",
                "Templates!",
                "Anything!",
              ]}
            />
          </Stack>
        </Stack>
        <Typography
          component={"p"}
          fontSize={desktop ? 18 : tablet ? 14 : 12}
          fontWeight={400}
          sx={{
            alignSelf: desktop || tablet ? "start" : "center",
            color: "#D0D5DD",
          }}
          maxWidth={"450px"}
          textAlign={desktop || tablet ? "start" : "center"}
        >
          Monetize your Telegram groups and channels or sell digital assets
          effortlessly with our user-friendly subscription bot. Seamlessly
          manage subscriptions, subscribers, digital products and view analytics
          all in one easy-to-navigate web application.
        </Typography>
        <motion.div
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ type: "spring", stiffness: 50, delay: 1 }}
        >
          <Stack
            direction={desktop || tablet ? "row" : "column"}
            spacing={"8px"}
            sx={{
              justifyContent: desktop || tablet ? "start" : "center",
              alignItems: desktop || tablet ? "start" : "center",
            }}
          >
            <Button
              endIcon={<ArrowForward />}
              variant="contained"
              onClick={() => nav("/signup")}
              size={desktop ? "large" : tablet ? "medium" : "small"}
              sx={{ borderRadius: "25px", pointerEvents: "all" }}
            >
              Get started for free
            </Button>
            <Button
              onClick={() =>
                (window.location.href =
                  "https://documenter.getpostman.com/view/23844318/2sA3BobCFF")
              }
              size={desktop ? "large" : tablet ? "medium" : "small"}
              sx={{ borderRadius: "25px", pointerEvents: "all" }}
            >
              Documentation
            </Button>
          </Stack>
        </motion.div>
        <Stack
          alignItems={desktop || tablet ? "start" : "center"}
          spacing={"8px"}
        >
          <AvatarGroup>
            <Avatar />
            <Avatar />
            <Avatar />
            <Avatar />
          </AvatarGroup>
          <Stack direction={"row"} spacing={"4px"}>
            <Star color="warning" />
            <Star color="warning" />
            <Star color="warning" />
            <Star color="warning" />
            <Star color="warning" />
            <Typography>5.0</Typography>
          </Stack>
          <Typography fontSize={16} fontWeight={500}>
            Over 300+ Happy Users
          </Typography>
        </Stack>
      </Stack>

      {/* Video  */}
      <ReactPlayer
        ref={playerRef as any}
        style={{
          flex: 1,
          alignSelf: desktop || tablet ? "start" : "center",
          borderRadius: "8px",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          minWidth: desktop ? "600px" : tablet ? "400px" : "90%",
          zIndex: 1,
        }}
        width={desktop ? "600px" : tablet ? "400px" : "90%"}
        height={"100%"}
        controls={true}
        loop
        muted
        playing={true}
        playsinline
        url={"/brand/hero.mp4"}
      />
    </Stack>
  );
}
