import React, { useEffect, useRef } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_EMAIL } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { EmailTemplate } from "../types/EmailTemplate";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import {
  rtmDeleteTemplate,
  rtmGetTransactionalEmails,
  rtmTestEmailTemplate,
  rtmUpdateTemplate,
} from "../core/api/admin";
import PFMInput from "../components/PFMInput";
import canvas from "html2canvas";
import { GetTransactionalEmailVariables } from "../core/helper";
const VARIABLES = ["{{name}}", "{{email}}", "{{unsubscribe}}"];

export default function EditTemplateDialog(props: DialogProps<EmailTemplate>) {
  const [template, setTemplate] = useState<Partial<EmailTemplate>>(
    props.data || {}
  );
  const [name, setName] = useState(props.data?.name);
  const [saved, setSaved] = useState(false);
  const [content, setContent] = useState("");
  const [testEmail, setTestEmail] = useState("");
  const [testVariables, setTestVariables] = useState<{ [key: string]: string }>(
    {}
  );
  // Extra variables are loaded if this template has an transactional email assigned.
  const [extraVariables, setExtraVariables] = useState<string[]>([]);

  const emailEditorRef = useRef<EditorRef>(null);

  const exportHtml = () => {
    setBusy(true);
    const unlayer = emailEditorRef.current?.editor;

    // blockbuilder-preview design-web design-email
    unlayer?.exportHtml(async (data2) => {
      const { design, html } = data2;
      // Get the preview
      setContent(html);
      save(JSON.stringify(design), html);
    });
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    if (template.design) {
      const dt = JSON.parse(template.design);
      unlayer.loadDesign(dt);
    }
  };
  const [busy, setBusy] = useState(false);

  // save template
  async function save(design: string, content: string) {
    try {
      // Save broadcast.
      setBusy(true);
      setSaved(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const node = document.querySelector("#final-template-preview");
      const i = await canvas(node as any, {});
      await rtmUpdateTemplate({
        id: template.id,
        name: name,
        content: content,
        design: design,
        thumbnail: i.toDataURL(),
      });
      setSaved(false);

      enqueueSnackbar("Template has been saved. ", { variant: "success" });
      props.closeHandler();
      setBusy(false);
    } catch (err: any) {
      enqueueSnackbar("Error updating template. Please try again. ", {
        variant: "error",
      });
      console.error("Error updating template. ", err);
      setBusy(false);
    }
  }

  async function deleteTemplate() {
    try {
      // Save broadcast.
      setBusy(true);

      await rtmDeleteTemplate(template.id!);

      enqueueSnackbar("Template has been deleted. ", { variant: "success" });

      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error deleting template. Please try again. ", {
        variant: "error",
      });
      console.error("Error deleting template. ", err);
    }
    setBusy(false);
  }

  async function loadVars() {
    // load extra variables.
    try {
      const _txEmails = await rtmGetTransactionalEmails();
      const _tx = _txEmails.find((t) => t.template === props.data?.id);
      if (_tx) {
        // Get variables for this tx
        const _vars = GetTransactionalEmailVariables(_tx.key);
        setExtraVariables(_vars);
      }
    } catch (er: any) {
      console.log("Error loading variables. ", er);
    }
  }

  async function sendTestMail() {
    try {
      if (!template.id) {
        enqueueSnackbar("Please save the template first. ", {
          variant: "error",
        });
        return;
      }
      if (!testEmail) {
        enqueueSnackbar(
          "Please enter the address to send the test email to. ",
          { variant: "error" }
        );
        return;
      }
      setBusy(true);
      await rtmTestEmailTemplate(template.id, testEmail, testVariables);
      enqueueSnackbar("Test email has been sent. ", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Error sending test email. " + err.message, {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    loadVars();
  }, []);
  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullScreen
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_EMAIL />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Template Editor
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Create or update an email template.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px", height: "100%" }} spacing={"18px"}>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Stack>
              <PFMInput
                text={name}
                onUpdate={(t) => setName(t)}
                placeholder="Enter a name for this template..."
              />
            </Stack>
            <Typography>Available variables: </Typography>
            {[...VARIABLES, ...extraVariables].map((v) => (
              <Chip
                label={v}
                onClick={() => navigator.clipboard.writeText(v)}
              />
            ))}
          </Stack>
          <Stack
            direction={"row"}
            spacing={"8px"}
            sx={{ justifyContent: "start", alignItems: "center" }}
          >
            <Stack>
              <PFMInput
                text={testEmail}
                placeholder="Send test email to..."
                onUpdate={(t) => setTestEmail(t)}
              />
            </Stack>
            <Button
              onClick={sendTestMail}
              disabled={busy}
              variant="contained"
              color="warning"
            >
              Test Email
            </Button>
          </Stack>
          <Stack
            direction={"row"}
            rowGap={"8px"}
            columnGap={"8px"}
            flexWrap={"wrap"}
            alignItems={"center"}
          >
            <Typography>Test Variables: </Typography>
            {[...VARIABLES, ...extraVariables].map((v) => (
              <Stack>
                <PFMInput
                  text={testVariables[v]}
                  onUpdate={(txt) => {
                    testVariables[v] = txt;
                    setTestVariables({ ...testVariables });
                  }}
                  startAdornment={
                    <Typography sx={{ pl: "8px" }}>{v}: </Typography>
                  }
                  placeholder={v}
                />
              </Stack>
            ))}
          </Stack>
          <EmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            minHeight={"100%"}
            options={{ appearance: { theme: "dark" } }}
          />
        </Stack>
        {saved && (
          <div
            id="final-template-preview"
            style={{ width: "650px" }}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          size="large"
          variant="contained"
          color="secondary"
        >
          Close
        </Button>
        {props.data?.id && (
          <Button
            disabled={busy}
            onClick={deleteTemplate}
            size="large"
            variant="contained"
            color="warning"
          >
            Delete
          </Button>
        )}
        <Button
          disabled={busy}
          onClick={exportHtml}
          size="large"
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
