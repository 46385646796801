import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  buttonClasses,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { FbAuth } from "../authentication/firebase";
import { useRecoilState } from "recoil";
import {
  aBotStatus,
  aNotifications,
  aProfile,
  aShowSidebar,
} from "../core/states";
import { useLocation, useNavigate } from "react-router";
import { AppConfig } from "../config";
import { Role } from "../types/Role";
import { LOGO_SIDEBAR } from "../assets/brand";
import {
  IC_AFFILIATE,
  IC_BALANCE,
  IC_BROADCAST,
  IC_CANCEL,
  IC_CART,
  IC_CODE,
  IC_COUPON,
  IC_CREDIT_CARD,
  IC_CROWN,
  IC_DASHBOARD,
  IC_DEPLOYMENT,
  IC_EDIT_EMAIL,
  IC_EMAIL,
  IC_EMAIL_CAMPAIGN,
  IC_EMAIL_TEMPLATE,
  IC_EMAIL_TRANSACTIONAL,
  IC_GIFT,
  IC_INVOICE,
  IC_LOCK,
  IC_LOGOUT,
  IC_MANAGE,
  IC_NOTIFICATION,
  IC_PAYMENTS,
  IC_PLAN,
  IC_SETTINGS,
  IC_SETUP,
  IC_STORE,
  IC_TRANSACTION,
  IC_UESR,
  IC_USER_SETTINGS,
  IC_USERS,
  IC_WRENCH,
} from "../assets/ui";
import { rtmGetNotificationsCount, rtmGetProfile } from "../core/api";
import { logEvent } from "@zexcore/sdk";
import { IC_CRYPTO } from "../assets/landing";
import {
  ChevronLeft,
  Close,
  Message,
  MessageOutlined,
  Telegram,
} from "@mui/icons-material";
import { TelegramConfig } from "../types/TelegramConfig";
import { useModal } from "mui-modal-provider";
import OnboardingDialog from "../dialogs/Onboarding";
import { rtmGetTelegramConfig } from "../core/api/user";

export default function Sidebar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [profile, setProfile] = useRecoilState(aProfile);
  const [cfg, setCfg] = useState<TelegramConfig>();
  const loc = useLocation();
  const segs = loc.pathname.split("/");
  const { showModal } = useModal();

  const showOnboardingButton = useMemo(() => {
    if (
      !profile?.boarded ||
      !(profile.business?.stripe || profile?.business?.crypto) ||
      !cfg?.token
    ) {
      return true;
    }
    return false;
  }, [profile, cfg]);

  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);
  const [notiCount, setNotiCount] = useRecoilState(aNotifications);
  const [selectedItem, setSelectedItem] = useState<
    | "dashboard"
    | "users"
    | "subscriptions"
    | "broadcast"
    | "transactions"
    | "subscribers"
    | "partner"
    | "affiliation"
    | "livechat"
    | "deployments"
    | "settings"
    | "coupons"
    | "settings/profile"
    | "settings/payments"
    | "settings/configuration"
    | "invoices"
    | "notifications"
    | "billing"
    | "codes"
    | "payouts" // For admin, shows crypto payouts that are in pending and proceesd.
    | "payments" // For admin, shows the fees collected
    | "wallets" // For admin, shows wallet details on CC
    | "chats" // For admin, shows list of chats with bot configured.
    | "telegramusers" // For admin, shows list of telegram users.
    | "products"
    | "emails/campaigns"
    | "emails/transactional"
    | "emails/templates"
  >(segs && segs.length > 0 ? (segs[segs.length - 1] as any) : "dashboard");

  const [menu, setMenu] = useState<
    "store" | "data" | "tools" | "payments" | "settings" | "manage" | "emails"
  >();

  function showOnboarding() {
    const modal = showModal(OnboardingDialog, {
      closeHandler(result) {
        modal.destroy();
        loadProfile();
      },
    });
  }

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      const _cfg = await rtmGetTelegramConfig();
      setCfg(_cfg);
    } catch (err: any) {
      console.log("Error loading profile.");
      console.log(err);
    }
  }
  async function loadNotifications() {
    try {
      const notis = await rtmGetNotificationsCount();
      setNotiCount(notis || 0);
    } catch (err: any) {
      console.log("Error loading notifications count.");
      console.log(err);
      logEvent({
        name: "err_loading_notifications",
        description:
          "Loading notifications failed for user when calling rtmGetNotificationsCount",
        identifier: FbAuth.currentUser?.uid,
        data: [JSON.stringify(err)],
      });
    }
  }

  useEffect(() => {
    if (!selectedItem) return;
    // if the user is admin
    if (profile?.role === Role.Administrator) {
      // use /admin/ sub-route
      navigate("/admin/" + selectedItem);
    } else {
      navigate("/user/" + selectedItem);
    }
    switch (selectedItem) {
      case "affiliation":
      case "broadcast":
      case "codes": {
        setMenu("tools");
        break;
      }
      case "dashboard": {
        setMenu(undefined);
        break;
      }
      case "settings/configuration":
      case "settings/payments":
      case "settings/profile": {
        setMenu("settings");
        break;
      }
      case "products":
      case "subscriptions":
      case "coupons":
      case "subscribers": {
        setMenu("store");
        break;
      }
      // Admiins
      case "telegramusers":
      case "chats": {
        setMenu("data");
        break;
      }
      case "deployments":
      case "users": {
        setMenu("manage");
        break;
      }
      case "payouts":
      case "wallets":
      case "invoices":
      case "transactions": {
        if (profile?.role === Role.User) {
          setMenu("store");
        } else setMenu("payments");
        break;
      }
      case "emails/templates":
      case "emails/transactional":
      case "emails/campaigns": {
        setMenu("emails");
        break;
      }
      default: {
        setMenu(undefined);
        break;
      }
    }
    setShowSidebar(false);
  }, [selectedItem]);

  useEffect(() => {
    loadProfile();
    loadNotifications();
    const interval = setInterval(() => {
      // Get notifications
      loadNotifications();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      sx={{
        width: "280px",
        px: "20px",
        py: "32px",
        m: "12px",
        background: "#FFFFFF13",
        "& .MuiButton-containedPrimary": {
          borderRadius: "50px",
        },
        "& .MuiButton-containedPrimary:hover": {
          background: "rgba(124,172,248,0.08)",
        },
        transition: "all .3s",
        borderRadius: "12px",
        [theme.breakpoints.down("md")]: {
          position: "fixed",
          zIndex: 1000,
          background: "#222",
          top: 0,
          bottom: 0,
          left: showSidebar ? 0 : -500,
          m: 0,
          borderRadius: 0,
        },
      }}
      spacing="32px"
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <a href="/">
          <img
            src={LOGO_SIDEBAR}
            alt="Cashifybot Logo"
            style={{ height: mobile ? "32px" : "60px" }}
          />
        </a>
        {mobile && (
          <IconButton onClick={() => setShowSidebar(false)}>
            <SvgIcon>
              <Close />
            </SvgIcon>
          </IconButton>
        )}
      </Stack>

      {/* Items  */}
      <List
        sx={{
          py: "16px",
          overflow: "auto",
          overflowX: "hidden",
          flex: 1,
          [`& .${buttonClasses.root}`]: {
            mt: "4px",
            mb: "4px",
            width: "100%",
          },
        }}
      >
        {profile?.role === Role.Administrator && (
          <>
            {/* Dashboard */}
            <Button
              onClick={() => setSelectedItem("dashboard")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                pointerEvents:
                  selectedItem === "dashboard" ? "none" : undefined,
                background:
                  selectedItem === "dashboard" ? undefined : "transparent",
                color: selectedItem === "dashboard" ? "#FCFCFD" : "#94969C",
              }}
              color="primary"
              variant="contained"
              startIcon={
                <SvgIcon
                  htmlColor={selectedItem === "dashboard" ? "#FCFCFD" : ""}
                >
                  <IC_DASHBOARD />
                </SvgIcon>
              }
            >
              Dashboard
            </Button>

            <Collapse />

            <Button
              onClick={() => {
                if (menu === "manage") setMenu(undefined);
                else setMenu("manage");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "flex-start",
                alignItems: "center",
                background: menu === "manage" ? undefined : "transparent",
                color: menu === "manage" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "manage" ? "#FCFCFD" : ""}>
                  <IC_MANAGE />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "manage" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Manage
              </Typography>
            </Button>

            <Collapse in={menu === "manage"}>
              <Stack sx={{ pl: "32px" }}>
                {/* I AM  */}
                <Button
                  onClick={() => setSelectedItem("users")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "users" ? "none" : undefined,
                    background:
                      selectedItem === "users" ? undefined : "transparent",
                    color: selectedItem === "users" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "users" ? "#FCFCFD" : ""}
                    >
                      <IC_USERS />
                    </SvgIcon>
                  }
                >
                  IAM Center
                </Button>

                {/* Deployments  */}
                <Button
                  onClick={() => setSelectedItem("deployments")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "deployments" ? "none" : undefined,
                    background:
                      selectedItem === "deployments"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "deployments" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "deployments" ? "#FCFCFD" : "#94969C"
                      }
                    >
                      <IC_DEPLOYMENT />
                    </SvgIcon>
                  }
                >
                  Deployments
                </Button>
              </Stack>
            </Collapse>

            <Button
              onClick={() => {
                if (menu === "payments") setMenu(undefined);
                else setMenu("payments");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "flex-start",
                alignItems: "center",
                background: menu === "payments" ? undefined : "transparent",
                color: menu === "payments" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "payments" ? "#FCFCFD" : ""}>
                  <IC_PAYMENTS />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "payments" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Payments
              </Typography>
            </Button>

            <Collapse in={menu === "payments"}>
              <Stack sx={{ pl: "32px" }}>
                {/* Payoutts  */}
                <Button
                  onClick={() => setSelectedItem("payouts")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "payouts" ? "none" : undefined,
                    background:
                      selectedItem === "payouts" ? undefined : "transparent",
                    color: selectedItem === "payouts" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "payouts" ? "#FCFCFD" : ""}
                    >
                      <IC_CREDIT_CARD />
                    </SvgIcon>
                  }
                >
                  Payouts
                </Button>

                {/* Wallets  */}
                <Button
                  onClick={() => setSelectedItem("wallets")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "wallets" ? "none" : undefined,
                    background:
                      selectedItem === "wallets" ? undefined : "transparent",
                    color: selectedItem === "wallets" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "wallets" ? "#FCFCFD" : ""}
                    >
                      <IC_BALANCE />
                    </SvgIcon>
                  }
                >
                  Wallets
                </Button>

                {/* Platform Invoices  */}
                <Button
                  onClick={() => setSelectedItem("invoices")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "invoices" ? "none" : undefined,
                    background:
                      selectedItem === "invoices" ? undefined : "transparent",
                    color: selectedItem === "invoices" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "invoices" ? "#FCFCFD" : ""}
                    >
                      <IC_INVOICE />
                    </SvgIcon>
                  }
                >
                  Invoices
                </Button>

                {/* Platform Transactions  */}
                <Button
                  onClick={() => setSelectedItem("transactions")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "transactions" ? "none" : undefined,
                    background:
                      selectedItem === "transactions"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "transactions" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "transactions" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_TRANSACTION />
                    </SvgIcon>
                  }
                >
                  Transactions
                </Button>
              </Stack>
            </Collapse>

            <Button
              onClick={() => {
                if (menu === "emails") setMenu(undefined);
                else setMenu("emails");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "flex-start",
                alignItems: "center",
                background: menu === "emails" ? undefined : "transparent",
                color: menu === "emails" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "emails" ? "#FCFCFD" : ""}>
                  <IC_EMAIL />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "emails" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Emails
              </Typography>
            </Button>
            <Collapse in={menu === "emails"}>
              <Stack sx={{ pl: "32px" }}>
                {/* Emails  */}
                <Button
                  onClick={() => setSelectedItem("emails/campaigns")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "emails/campaigns" ? "none" : undefined,
                    background:
                      selectedItem === "emails/campaigns"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "emails/campaigns"
                        ? "#FCFCFD"
                        : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "emails/campaigns" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_EMAIL_CAMPAIGN />
                    </SvgIcon>
                  }
                >
                  Campaigns
                </Button>

                {/* Transactional Emails  */}
                <Button
                  onClick={() => setSelectedItem("emails/transactional")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "emails/transactional"
                        ? "none"
                        : undefined,
                    background:
                      selectedItem === "emails/transactional"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "emails/transactional"
                        ? "#FCFCFD"
                        : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "emails/transactional" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_EMAIL_TRANSACTIONAL />
                    </SvgIcon>
                  }
                >
                  Transactional
                </Button>

                {/* Templates */}
                <Button
                  onClick={() => setSelectedItem("emails/templates")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "emails/templates" ? "none" : undefined,
                    background:
                      selectedItem === "emails/templates"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "emails/templates"
                        ? "#FCFCFD"
                        : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "emails/templates" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_EMAIL_TEMPLATE />
                    </SvgIcon>
                  }
                >
                  Template Manager
                </Button>
              </Stack>
            </Collapse>

            <Button
              onClick={() => {
                if (menu === "data") setMenu(undefined);
                else setMenu("data");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "flex-start",
                alignItems: "center",
                background: menu === "data" ? undefined : "transparent",
                color: menu === "data" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "data" ? "#FCFCFD" : ""}>
                  <IC_UESR />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "data" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Content
              </Typography>
            </Button>

            <Collapse in={menu === "data"}>
              <Stack sx={{ pl: "32px" }}>
                {/* Telegram Users  */}
                <Button
                  onClick={() => setSelectedItem("telegramusers")}
                  disableElevation
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "telegramusers" ? "none" : undefined,
                    background:
                      selectedItem === "telegramusers"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "telegramusers" ? "#FCFCFD" : "#94969C",
                    borderRadius: "108px",
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "telegramusers" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_UESR />
                    </SvgIcon>
                  }
                >
                  Telegram Users
                </Button>
              </Stack>
            </Collapse>
          </>
        )}
        {/* User Buttons  */}
        {profile?.role === Role.User && (
          <>
            <Button
              onClick={() => setSelectedItem("dashboard")}
              disableElevation
              sx={{
                borderRadius: "100px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents:
                  selectedItem === "dashboard" ? "none" : undefined,
                background:
                  selectedItem === "dashboard" ? undefined : "transparent",
                color: selectedItem === "dashboard" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon
                  htmlColor={selectedItem === "dashboard" ? "#FCFCFD" : ""}
                >
                  <IC_DASHBOARD />
                </SvgIcon>
              }
            >
              Dashboard
            </Button>

            <Collapse />

            <Button
              onClick={() => {
                if (menu === "store") setMenu(undefined);
                else setMenu("store");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "flex-start",
                alignItems: "center",
                background: menu === "store" ? undefined : "transparent",
                color: menu === "store" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "store" ? "#FCFCFD" : ""}>
                  <IC_STORE />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "store" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Store
              </Typography>
            </Button>

            <Collapse in={menu === "store"}>
              <Stack sx={{ pl: "32px" }}>
                <Button
                  onClick={() => setSelectedItem("products")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "products" ? "none" : undefined,
                    color: selectedItem === "products" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "products" ? "#FCFCFD" : ""}
                    >
                      <IC_CART />
                    </SvgIcon>
                  }
                >
                  Products
                </Button>

                <Button
                  onClick={() => setSelectedItem("subscriptions")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "subscriptions" ? "none" : undefined,
                    color:
                      selectedItem === "subscriptions" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "subscriptions" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_CREDIT_CARD />
                    </SvgIcon>
                  }
                >
                  Subscriptions
                </Button>

                <Button
                  onClick={() => setSelectedItem("coupons")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "coupons" ? "none" : undefined,
                    color: selectedItem === "coupons" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "coupons" ? "#FCFCFD" : ""}
                    >
                      <IC_COUPON />
                    </SvgIcon>
                  }
                >
                  Coupons
                </Button>

                <Button
                  onClick={() => setSelectedItem("subscribers")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "subscribers" ? "none" : undefined,
                    background:
                      selectedItem === "subscribers"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "subscribers" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "subscribers" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_USERS />
                    </SvgIcon>
                  }
                >
                  Customers
                </Button>

                <Button
                  onClick={() => setSelectedItem("transactions")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "transactions" ? "none" : undefined,
                    background:
                      selectedItem === "transactions"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "transactions" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "transactions" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_TRANSACTION />
                    </SvgIcon>
                  }
                >
                  Transactions
                </Button>

                <Button
                  onClick={() => setSelectedItem("invoices")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "invoices" ? "none" : undefined,
                    background:
                      selectedItem === "invoices" ? undefined : "transparent",
                    color: selectedItem === "invoices" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "invoices" ? "#FCFCFD" : ""}
                    >
                      <IC_INVOICE />
                    </SvgIcon>
                  }
                >
                  Invoices
                </Button>
              </Stack>
            </Collapse>

            <Button
              onClick={() => {
                if (menu === "tools") setMenu(undefined);
                else setMenu("tools");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: menu === "tools" ? "none" : undefined,
                background: menu === "tools" ? undefined : "transparent",
                color: menu === "tools" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "tools" ? "#FCFCFD" : ""}>
                  <IC_WRENCH />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "tools" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Tools
              </Typography>
            </Button>

            <Collapse in={menu === "tools"} sx={{ m: 0, p: 0 }}>
              <Stack sx={{ pl: "32px" }}>
                <Button
                  onClick={() => setSelectedItem("codes")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "codes" ? "none" : undefined,
                    background:
                      selectedItem === "codes" ? undefined : "transparent",
                    color: selectedItem === "codes" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "codes" ? "#FCFCFD" : ""}
                    >
                      <IC_LOCK />
                    </SvgIcon>
                  }
                >
                  Access Codes
                </Button>

                <Button
                  onClick={() => setSelectedItem("affiliation")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "affiliation" ? "none" : undefined,
                    background:
                      selectedItem === "affiliation"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "affiliation" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "affiliation" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_AFFILIATE />
                    </SvgIcon>
                  }
                >
                  Affiliation
                </Button>

                <Button
                  onClick={() => setSelectedItem("broadcast")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "broadcast" ? "none" : undefined,
                    background:
                      selectedItem === "broadcast" ? undefined : "transparent",
                    color: selectedItem === "broadcast" ? "#FCFCFD" : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={selectedItem === "broadcast" ? "#FCFCFD" : ""}
                    >
                      <IC_BROADCAST />
                    </SvgIcon>
                  }
                >
                  Broadcast
                </Button>
              </Stack>
            </Collapse>

            <Button
              onClick={() => {
                if (menu === "settings") setMenu(undefined);
                else setMenu("settings");
              }}
              disableElevation
              sx={{
                borderRadius: "108px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: menu === "settings" ? "none" : undefined,
                background: menu === "settings" ? undefined : "transparent",
                color: menu === "settings" ? "#FCFCFD" : "#94969C",
              }}
              color="secondary"
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={menu === "settings" ? "#FCFCFD" : ""}>
                  <IC_SETTINGS />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon
                  sx={{
                    transform:
                      menu === "settings" ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                >
                  <ChevronLeft />
                </SvgIcon>
              }
            >
              <Typography flex={1} textAlign={"start"}>
                Settings
              </Typography>
            </Button>

            <Collapse in={menu === "settings"} sx={{ m: 0, p: 0 }}>
              <Stack sx={{ pl: "32px" }}>
                <Button
                  onClick={() => setSelectedItem("settings/profile")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "settings/profile" ? "none" : undefined,
                    background:
                      selectedItem === "settings/profile"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "settings/profile"
                        ? "#FCFCFD"
                        : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "settings/profile" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_UESR />
                    </SvgIcon>
                  }
                >
                  Profile
                </Button>

                <Button
                  onClick={() => setSelectedItem("settings/payments")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "settings/payments" ? "none" : undefined,
                    background:
                      selectedItem === "settings/payments"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "settings/payments"
                        ? "#FCFCFD"
                        : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "settings/payments" ? "#FCFCFD" : ""
                      }
                    >
                      <IC_CREDIT_CARD />
                    </SvgIcon>
                  }
                >
                  Payments
                </Button>

                <Button
                  onClick={() => setSelectedItem("settings/configuration")}
                  disableElevation
                  sx={{
                    borderRadius: "100px",
                    px: "18px",
                    justifyContent: "start",
                    alignItems: "center",
                    pointerEvents:
                      selectedItem === "settings/configuration"
                        ? "none"
                        : undefined,
                    background:
                      selectedItem === "settings/configuration"
                        ? undefined
                        : "transparent",
                    color:
                      selectedItem === "settings/configuration"
                        ? "#FCFCFD"
                        : "#94969C",
                  }}
                  variant="text"
                  startIcon={
                    <SvgIcon
                      htmlColor={
                        selectedItem === "settings/configuration"
                          ? "#FCFCFD"
                          : ""
                      }
                    >
                      <Telegram />
                    </SvgIcon>
                  }
                >
                  Telegram
                </Button>
              </Stack>
            </Collapse>
          </>
        )}

        {profile?.role === Role.Affiliate && (
          <Button
            onClick={() => setSelectedItem("dashboard")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "dashboard" ? "none" : undefined,
              background:
                selectedItem === "dashboard" ? undefined : "transparent",
              color: selectedItem === "dashboard" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon
                htmlColor={selectedItem === "dashboard" ? "#FCFCFD" : "#48F"}
              >
                <IC_GIFT />
              </SvgIcon>
            }
          >
            Invite & Earn
          </Button>
        )}
      </List>

      <Stack spacing={"8px"}>
        {showOnboardingButton && profile?.role === Role.User && (
          <Button
            onClick={showOnboarding}
            disableElevation
            sx={{
              borderRadius: "50px",
              justifyContent: "start",
              alignItems: "center",
            }}
            color="info"
            variant="contained"
            startIcon={
              <SvgIcon htmlColor={"#FCFCFD"}>
                <IC_SETUP />
              </SvgIcon>
            }
            endIcon={
              <Badge
                sx={{ pr: "18px" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                variant="standard"
                color={notiCount > 0 ? "primary" : undefined}
                badgeContent={notiCount}
              ></Badge>
            }
          >
            Finish Setup
          </Button>
        )}
        <Button
          onClick={() => setSelectedItem("notifications")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents:
              selectedItem === "notifications" ? "none" : undefined,
            background:
              selectedItem === "notifications" ? undefined : "transparent",
            color: selectedItem === "notifications" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon
              htmlColor={
                selectedItem === "notifications" ? "#FCFCFD" : undefined
              }
            >
              <IC_NOTIFICATION />
            </SvgIcon>
          }
          endIcon={
            <Badge
              sx={{ pr: "18px" }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              variant="standard"
              color={notiCount > 0 ? "primary" : undefined}
              badgeContent={notiCount}
            ></Badge>
          }
        >
          Notifications
        </Button>
        {false && (
          <Button
            onClick={() => setSelectedItem("partner")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "partner" ? "none" : undefined,
              background:
                selectedItem === "partner" ? undefined : "transparent",
              color: selectedItem === "partner" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon
                htmlColor={selectedItem === "partner" ? "#FCFCFD" : undefined}
              >
                <IC_GIFT />
              </SvgIcon>
            }
          >
            Invite & Earn
          </Button>
        )}
        {false && (
          <Button
            onClick={() => setSelectedItem("billing")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "billing" ? "none" : undefined,
              background:
                selectedItem === "billing" ? undefined : "transparent",
              color: selectedItem === "billing" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon
                htmlColor={
                  selectedItem === "billing"
                    ? "#FCFCFD"
                    : theme.palette.warning.main
                }
              >
                <IC_CROWN />
              </SvgIcon>
            }
          >
            Billing & Membership
          </Button>
        )}
        {profile?.role !== Role.User && (
          <Button
            onClick={() => setSelectedItem("settings")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "settings" ? "none" : undefined,
              background:
                selectedItem === "settings" ? undefined : "transparent",
              color: selectedItem === "settings" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon htmlColor={selectedItem === "settings" ? "#FCFCFD" : ""}>
                <IC_SETTINGS />
              </SvgIcon>
            }
          >
            Settings
          </Button>
        )}
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing="12px">
        <Avatar
          sx={{ width: "40px", height: "40px", color: "white" }}
          src={FbAuth.currentUser?.photoURL || ""}
        />
        <Stack flex={1}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#CECFD2",
              maxWidth: "160px",
              wordWrap: "unset",
              overflowWrap: "anywhere",
              [theme.breakpoints.down("md")]: {
                fontSize: 12,
              },
            }}
          >
            {profile?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#94969C",
              wordBreak: "break-word",
              [theme.breakpoints.down("md")]: {
                fontSize: 8,
              },
            }}
          >
            {profile?.email}
          </Typography>
        </Stack>
        <IconButton
          onClick={() => {
            signOut(FbAuth);
            window.location.reload();
          }}
          title="Sign out"
        >
          <SvgIcon>
            <IC_LOGOUT />
          </SvgIcon>
        </IconButton>
      </Stack>

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontSize={10}>{AppConfig.version}</Typography>
        <Typography fontSize={10}>{AppConfig.build}</Typography>
      </Stack>
    </Stack>
  );
}
