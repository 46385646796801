import React, { useEffect, useMemo, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";
import { enqueueSnackbar } from "notistack";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../../components/PFMAutoComplete";
import PFMInput from "../../components/PFMInput";
import { rtmUpdatePayments } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";

export default function OnboardingStepCrypto(props: {
  next: () => void;
  back: () => void;
}) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [saved, setSaved] = useState(false);

  const [network, setNetwork] = useState<AutocompleteOption>();
  const [coin, setCoin] = useState<AutocompleteOption>();
  const [address, setAddress] = useState("");
  const [selectedCoins, setSelectedCoins] = useState<
    { label: string; id: string; network: string }[]
  >([]);

  const isCryptoActive = useMemo(() => {
    return address && network && coin;
  }, [profile, address, network, coin]);

  const [refresh, setRefresh] = useState(0);
  const [COINS, setCOINS] = useState<AutocompleteOption[]>([]);

  const [ALLCOINS] = useState<AutocompleteOption[]>([
    {
      label: "BTC",
      id: "BTC",
      network: "bitcoin",
    },
    // Ethereum
    {
      label: "ETH",
      id: "ETH",
      network: "ethereum",
    },
    {
      label: "USDT",
      id: "ETH_USDT",
      network: "ethereum",
    },
    {
      label: "USDC",
      id: "ETH_USDC",
      network: "ethereum",
    },
    // Polygon
    {
      label: "MATIC",
      id: "MATIC",
      network: "polygon",
    },
    {
      label: "USDC-POLYGON",
      id: "USDC-POLYGON",
      network: "polygon",
    },
    {
      label: "USDT",
      id: "USDT-POLYGON",
      network: "polygon",
    },
    // TRON
    {
      label: "TRX",
      id: "TRX",
      network: "tron",
    },
    {
      label: "USDT",
      id: "USDT-TRX",
      network: "tron",
    },
    {
      label: "USDC",
      id: "USDC-TRX",
      network: "tron",
    },

    // ARBI
    {
      label: "ETH",
      id: "ETH-ARBITRUM",
      network: "arbitrum",
    },
    {
      label: "USDC",
      id: "USDC-ARBITRUM",
      network: "arbitrum",
    },
    {
      label: "USDT",
      id: "USDT-ARBITRUM",
      network: "arbitrum",
    },
    // Ripple
    {
      label: "XRP",
      id: "XRP",
      network: "ripple",
    },
    // BSC
    {
      label: "BNB",
      id: "BNB",
      network: "bsc",
    },
    {
      label: "BUSD",
      id: "BUSD",
      network: "bsc",
    },
    {
      label: "USDC",
      id: "USDC-BSC",
      network: "bsc",
    },
    {
      label: "USDT",
      id: "USDT-BSC",
      network: "bsc",
    },
  ]);
  const [NETWORKS] = useState<AutocompleteOption[]>([
    {
      label: "Bitcoin",
      id: "bitcoin",
    },
    {
      label: "Ethereum",
      id: "ethereum",
    },
    {
      label: "Tron",
      id: "tron",
    },
    {
      label: "Arbitrum",
      id: "arbitrum",
    },
    {
      label: "Polygon",
      id: "polygon",
    },
    {
      label: "Ripple",
      id: "ripple",
    },
    {
      label: "Binance Smart Chain",
      id: "bsc",
    },
  ]);

  async function save() {
    try {
      if (!isCryptoActive) {
        enqueueSnackbar("Please provide valid wallet details. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      // save the details
      await rtmUpdatePayments({
        address: address,
        coins: selectedCoins,
        currency: coin!.id,
        network: network!.id,
      });
      enqueueSnackbar("Profile updated successfully.", { variant: "success" });
      logEvent({
        name: "setup crypto",
        description: "Crypto profile setup for accepting crypto payments. ",
        identifier: profile?.uid,
      });
      props.next();
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
      console.error(err);
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error saving crypto profile from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  async function load() {
    try {
      setBusy(true);
      const prof = await rtmGetProfile();
      // Load network
      setAddress(prof.business?.crypto?.wallet || "");
      setNetwork(NETWORKS.find((n) => n.id === prof.business?.crypto?.network));
      setCoin(ALLCOINS.find((c) => c.id === prof.business?.crypto?.currency));
      if (
        prof.business?.crypto &&
        prof.business?.crypto?.accepted_currencies?.length > 0
      ) {
        setSelectedCoins(
          ALLCOINS.filter(
            (c) =>
              prof.business!.crypto!.accepted_currencies.filter(
                (ac) => ac?.id === c?.id
              )?.length > 0
          ) as any
        );
      } else {
        setSelectedCoins([]);
      }
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading profile. Please try again.", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading crypto profile from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setCOINS([...ALLCOINS.filter((c) => c.network === network?.id)]);
    setRefresh(refresh + 1);
  }, [network]);

  return (
    <Stack>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Accepting Crypto Payments
      </Typography>
      <Typography sx={{ my: "8px" }}>
        Set up your wallet and accepted payment methods (currencies) for your
        bot to enable crypto payments, worldwide.
      </Typography>
      <Typography>
        Cashifybot uses CryptoChill to get you paid instantly in various major
        crypto currencies, such as USDT (ETH)
        <br />
        Please provide the payout wallet details and select your preferred
        accepted currencies for the bot below.
      </Typography>

      <Stack spacing="12px" sx={{ maxWidth: "50%" }}>
        <Typography>Payout Wallet</Typography>
        <Stack direction={"row"} spacing="8px">
          <PFMAutoComplete
            key={network as any}
            options={NETWORKS}
            multiple={false}
            value={network}
            onUpdate={(n) => {
              setNetwork(n as any);
              setCoin(undefined);
            }}
            fullWidth
            label="Network"
            placeholder="Select your preferred network..."
          />
          <PFMAutoComplete
            key={refresh}
            label="Coin"
            placeholder="Select coin..."
            options={COINS}
            value={coin}
            onUpdate={(t) => setCoin(t as any)}
          />
        </Stack>
        <PFMInput
          text={address}
          onUpdate={(t) => setAddress(t)}
          sx={{ width: "500px" }}
          label="Address"
          placeholder="Enter your wallet address..."
        />
      </Stack>

      <Stack spacing={"8px"} key={selectedCoins as any} sx={{ pt: "18px" }}>
        <Typography>Accepted Currencies</Typography>
        <Typography fontSize={14}>
          You can enable or disable the specific currencies for your bot by
          using the following buttons.
        </Typography>

        {/* TODO: Add coin selection buttons  */}
        {NETWORKS.map((n) => (
          <Stack spacing={"8px"}>
            <Typography fontWeight={500}>{n.label}</Typography>
            <Stack direction={"row"} spacing={"8px"}>
              {/* Add each coin of this network */}
              {ALLCOINS.filter((c) => c.network === n.id).map((c) => (
                <Button
                  onClick={() => {
                    if (
                      selectedCoins.filter((sc) => sc.id === c.id).length > 0
                    ) {
                      // remove
                      setSelectedCoins([
                        ...selectedCoins.filter((sc) => sc.id !== c.id),
                      ]);
                    } else {
                      // add
                      setSelectedCoins([
                        ...selectedCoins,
                        ALLCOINS.find((ac) => ac.id === c.id) as any,
                      ]);
                    }
                  }}
                  color={
                    selectedCoins.filter((sc) => sc.id === c.id).length > 0
                      ? "primary"
                      : "secondary"
                  }
                  variant="outlined"
                >
                  {c.label} ({c.id})
                </Button>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Stack direction={"row"} spacing="8px" sx={{ mt: "32px" }}>
        <Button
          disabled={busy}
          onClick={props.back}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Back
        </Button>
        <Button
          disabled={busy}
          onClick={props.next}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Skip
        </Button>
        <Button
          disabled={busy || !isCryptoActive}
          onClick={save}
          variant="contained"
          color="primary"
          sx={{ alignSelf: "start", mt: "32px" }}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
