import React, { useEffect, useMemo, useState } from "react";
import { DialogProps } from "./props";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import PFMRadio from "../components/PFMRadio";
import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol } from "../core/helper";
import { IC_ADD_CARD, IC_HELP } from "../assets/ui";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import {
  rtmCreateSubscription,
  rtmGetProducts,
  rtmGetTelegramChats,
} from "../core/api/user";
import { logEvent } from "@zexcore/sdk";
import { TelegramChat } from "../types/TelegramChat";
import { Product } from "../types/Product";

export default function CreateSubscriptionDialog(props: DialogProps<any>) {
  const [profile] = useRecoilState(aProfile);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [chats, setChats] = useState<AutocompleteOption[]>([]);
  const [products, setProducts] = useState<AutocompleteOption[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    AutocompleteOption[]
  >([]);
  const [selectedChats, setSelectedChats] = useState<AutocompleteOption[]>([]);
  const [price, setPrice] = useState("");
  const [kind, setKind] = useState<"onetime" | "recurring">("onetime");
  const [schedule, setSchedule] = useState("");
  const [metadata, setMetadata] = useState<{ key: string; value: string }[]>(
    []
  );
  const [trial, setTrial] = useState(0);

  const [busy, setBusy] = useState(false);

  async function createSubscription() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a subscription name.", {
          variant: "error",
        });
        return;
      }
      if (!description) {
        enqueueSnackbar("Please enter a description for the subscription.", {
          variant: "error",
        });
        return;
      }
      if (
        (!selectedChats || selectedChats.length === 0) &&
        (!selectedProducts || selectedProducts.length === 0)
      ) {
        enqueueSnackbar("Please assign at least 1 chat or a product.", {
          variant: "error",
        });
        return;
      }
      if (!price) {
        enqueueSnackbar("Please enter the price for this subscription.", {
          variant: "error",
        });
        return;
      }
      const _price = parseFloat(price);
      if (Number.isNaN(_price) || _price <= 0 || _price < 5) {
        enqueueSnackbar("Please enter a valid price for this subscription.", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const _chats: Partial<TelegramChat>[] = [];
      for (let p of selectedChats) {
        _chats.push({
          id: p.id,
        });
      }
      const _prods: Partial<Product>[] = [];
      for (let p of selectedProducts) {
        _prods.push({ id: p.id, name: p.label });
      }
      // Add the subscription
      await rtmCreateSubscription({
        name: name,
        kind: kind,
        metadata: metadata,
        price: {
          amount: Math.floor(parseFloat(price) * 100),
          currency: profile?.currency!,
        },
        chats: _chats as any,
        description: description,
        schedule:
          kind === "recurring" ? (schedule.toLowerCase() as any) : undefined,
        trial: kind === "recurring" ? trial : undefined,
        products: _prods as any,
      });
      enqueueSnackbar("Subscription has been added. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating a new subscription. Error: " + err.message,
        {
          variant: "error",
        }
      );
      console.error(err);
      logEvent({
        name: "create_subscription_error",
        description: "Error creating a new subscription plan. ",
        identifier: profile?.uid,
      });
    }
    setBusy(false);
  }

  async function load() {
    try {
      setBusy(true);
      // Load the chats
      const _chats = await rtmGetTelegramChats();
      const opts: AutocompleteOption[] = [];
      if (_chats) {
        for (let c of _chats) {
          opts.push({
            label: c.title,
            icon: c.icon,
            id: c.id,
            username: c.username,
          });
        }
      }
      setChats(opts);

      const _prods = await rtmGetProducts();
      let prods: AutocompleteOption[] = [];
      if (_prods) {
        for (let p of _prods) {
          prods.push({
            label: p.name,
            id: p.id,
          });
        }
      }
      setProducts(prods);
    } catch (err: any) {
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_ADD_CARD />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Create Subscription
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Add a new subscription model for your bot.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", width: "580px" }}>
          <Stack spacing={"24px"} sx={{ px: "24px" }}>
            <PFMInput
              label="Subscription Name"
              placeholder="Name your subscription..."
              important
              text={name}
              onUpdate={(t) => setName(t)}
              disabled={busy}
            />
            <PFMInput
              label="Description"
              placeholder="Write a short description for this model. It will be shown to the users in the subscription details screen. "
              important
              multiline
              rows={4}
              text={description}
              onUpdate={(t) => setDescription(t)}
              disabled={busy}
            />
            <PFMAutoComplete
              multiple
              key={chats.length}
              options={chats}
              label="Assign Channels"
              placeholder="Select channels.."
              onUpdate={(sel) => setSelectedChats(sel as any)}
              value={selectedChats}
              helperText="Use this to provide access to the channels with this subscription. Send a message in the channel after adding the bot, and make sure your bot has access to messages."
            />
            <Stack>
              <PFMAutoComplete
                multiple
                key={products.length}
                options={products}
                label="Assign Products"
                placeholder="Select products.."
                helperText="Use this to provide access to your products with this subscription. "
                onUpdate={(sel) => setSelectedProducts(sel as any)}
                value={selectedProducts}
              />
              <Typography
                onClick={load}
                color="primary"
                sx={{
                  textDecoration: "underline",
                  px: "12px",
                  cursor: "pointer",
                }}
              >
                Refresh
              </Typography>
            </Stack>

            <PFMInput
              startAdornment={
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ pl: "8px", height: "100%" }}
                >
                  <Typography>
                    {getCurrencySymbol(profile?.currency!)}
                  </Typography>
                </Stack>
              }
              text={price.toString()}
              onUpdate={(p) => setPrice(p)}
              label="Subscription Price"
              placeholder="5.00"
              errorText={
                price &&
                (Number.isNaN(parseFloat(price)) || parseFloat(price) < 5)
                  ? "Invalid price."
                  : undefined
              }
              helperText={`Minimum price is ${getCurrencySymbol(
                profile?.currency!
              )}5.`}
              important
            />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                Type of subscription
              </Typography>
              <RadioGroup radioGroup="subscriptionKind">
                <PFMRadio
                  checked={kind === "onetime"}
                  onUpdate={(c) => setKind("onetime")}
                  label="One-time"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
                <PFMRadio
                  checked={kind === "recurring"}
                  onUpdate={(c) => setKind("recurring")}
                  label="Recurring"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
              </RadioGroup>
              {kind === "recurring" && (
                <PFMAutoComplete
                  options={[
                    { label: "Daily" },
                    { label: "Weekly" },
                    { label: "Monthly" },
                    { label: "Every 3 months" },
                    { label: "Every 6 months" },
                    { label: "Yearly" },
                  ]}
                  value={{ label: schedule }}
                  onUpdate={(v: any) => setSchedule(v.label)}
                  label="Recurring Schedule"
                  placeholder="Select a schedule..."
                  important
                />
              )}

              {/* Add trial option for recurring  */}
              {kind === "recurring" && (
                <PFMInput
                  sx={{ pt: "12px" }}
                  label="Trial Period (Stripe Only)"
                  placeholder="7"
                  text={trial.toString()}
                  helperText="Enter the trial period in number of days, or 0 to disable trial."
                  onUpdate={(c) => setTrial(parseInt(c) || 0)}
                  endAdornment={
                    <Select
                      variant="standard"
                      disableUnderline
                      value={"days"}
                      sx={{ background: "transparent" }}
                    >
                      <MenuItem value={"days"}>Days</MenuItem>
                    </Select>
                  }
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={busy}
          onClick={props.closeHandler}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={createSubscription}
          color="primary"
          variant="contained"
          fullWidth
        >
          Create Subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
}
