import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { Add, Delete, Edit } from "@mui/icons-material";

import { IC_LOCK_ADD, IC_REFRESH } from "../../assets/ui";
import { motion } from "framer-motion";
import CreateAccessCodeDialog from "../../dialogs/CreateAccessCode";
import DeleteAccessCodeDialog from "../../dialogs/DeleteAccessCode";
import EditAccessCodeDialog from "../../dialogs/EditAccessCode";
import {
  rtmDeleteAccessCode,
  rtmDeleteCoupon,
  rtmGetCodes,
  rtmGetCoupons,
} from "../../core/api/user";
import LimitErrorDialog from "../../dialogs/LimitError";
import { rtmGetProfile } from "../../core/api";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";
import { AccessCode } from "../../types/AccessCode";
import { Coupon } from "../../types/Coupon";
import CreateCouponDialog from "../../dialogs/CreateCoupon";
import DeleteCouponDialog from "../../dialogs/DeleteCoupon";

export default function SectionUserCoupons() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<Coupon[]>([]);

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _coups = await rtmGetCoupons();
      setData(_coups || []);
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.error(err);
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading data in access codes section. ",
        stack: err,
        tags: ["access codes"],
      });
    }
    setBusy(false);
  }

  function showCreateDialog() {
    const modal = showModal(CreateCouponDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
    });
  }

  function showDeleteDialog(coup: Coupon) {
    const modal = showModal(DeleteCouponDialog, {
      async closeHandler(result) {
        if (result) {
          await rtmDeleteCoupon(coup)
            .catch((err) => {
              enqueueSnackbar("Error deleting coupon. ", {
                variant: "error",
              });
              console.error("Error deleting coupon. ", err);
            })
            .then(() => {
              enqueueSnackbar("The coupon has been deleted. ", {
                variant: "success",
              });
            });
          load();
        }
        modal.destroy();
      },
      data: coup,
    });
  }

  useEffect(() => {
    setSection(`Coupons`);
    setSectionDescription(`View and manage coupon codes for discounts.`);
    load();
  }, []);

  function RenderCoupon(coup: Coupon) {
    return (
      <TableRow>
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {coup.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(coup.created).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {coup.times_redeemed +
              (coup.max_redemptions ? " / " + coup.max_redemptions : "")}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {coup.duration === "repeating"
              ? `${coup.duration_in_months} months`
              : coup.duration}
          </Typography>
        </TableCell>
        <TableCell>{coup.currency}</TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {coup.percent_off
              ? `${coup.percent_off}%`
              : `${coup.currency.toUpperCase()} ${coup.amount_off}`}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {coup.valid
              ? coup.redeem_by
                ? moment.unix(coup.redeem_by).fromNow()
                : "Never"
              : "Expired"}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <Stack direction={"row"}>
            <IconButton
              disabled={busy}
              color="error"
              title="Delete"
              onClick={() => showDeleteDialog(coup)}
            >
              <Delete />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <Button
            disabled={busy}
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon>
                <IC_LOCK_ADD />
              </SvgIcon>
            }
            onClick={showCreateDialog}
          >
            Create
          </Button>
          <Button
            variant="contained"
            disabled={busy}
            onClick={() => load()}
            color="secondary"
            size="small"
            sx={{ width: "140px" }}
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Coupons"
        description="List of all the coupon codes for your bot."
        titleBadge={data.length + " Coupons"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                disabled={busy}
                onClick={load}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                color="primary"
                startIcon={
                  <SvgIcon>
                    <Add />
                  </SvgIcon>
                }
                onClick={showCreateDialog}
              >
                Create
              </Button>
            </>
          )
        }
        tableHead={
          <TableRow>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Created
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Use Count
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Duration
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Currency
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Amount
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Expiry
            </TableCell>
            <TableCell
              align="right"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderCoupon(d))}</>}
      />
    </motion.div>
  );
}
