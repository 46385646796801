import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import { IC_CANCEL, IC_STORE } from "../assets/ui";
import { rtmUpdateProduct } from "../core/api/user";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { logEvent } from "@zexcore/sdk";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import PFMCheckbox from "../components/PFMCheckbox";
import { Add } from "@mui/icons-material";
import { Product } from "../types/Product";

export default function EditProductDialog(props: DialogProps<Product>) {
  const [profile] = useRecoilState(aProfile);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState("");
  const [successUrl, setSuccessUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [generateLicenseKey, setGenerateLicenseKey] = useState(false);
  const [limit, setLimit] = useState(0);
  const [files, setFiles] = useState<
    { name: string; size: number; url: string }[]
  >([]);
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [busy, setBusy] = useState(false);

  async function saveProduct() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a product name.", {
          variant: "error",
        });
        return;
      }
      if (!description) {
        enqueueSnackbar("Please enter a description for the product.", {
          variant: "error",
        });
        return;
      }
      if (!successUrl && files.length === 0) {
        enqueueSnackbar("Please add deliverables or set success url. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const r = await rtmUpdateProduct({
        ...props.data,
        description: description,
        files: files,
        generateLicenseKeys: generateLicenseKey,
        licenseKeyLimit: limit,
        media: media,
        name: name,
        status: "active",
        successMessage: successMessage,
        successUrl: successUrl,
      });
      enqueueSnackbar("Product has been updated. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error updating product. Error: " + err.message, {
        variant: "error",
      });
      console.error(err);
      logEvent({
        name: "update_product_error",
        description: "Error updating a product. ",
        identifier: profile?.uid,
      });
    }
    setBusy(false);
  }

  async function uploadMedia(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/media/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setMedia(url);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function uploadDeliverable(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/files/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setFiles([
            ...files,
            { name: attachment.name, size: attachment.size, url: url },
          ]);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  useEffect(() => {
    // Load subscription into inputs
    setName(props.data?.name || "");
    setDescription(props.data?.description || "");
    setMedia(props.data?.media || "");
    setSuccessUrl(props.data?.successUrl || "");
    setSuccessMessage(props.data?.successMessage || "");
    setGenerateLicenseKey(props.data?.generateLicenseKeys || false);
    setFiles(props.data?.files || []);
    setLimit(props.data?.licenseKeyLimit || 0);
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <IconContainer>
            <IC_STORE />
          </IconContainer>
          <Stack>
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <Typography fontSize={18} fontWeight={600}>
                Edit Product
              </Typography>
              <Tooltip title="Click to copy product id.">
                <Chip
                  onClick={() =>
                    navigator.clipboard.writeText(props.data?.id || "")
                  }
                  size="small"
                  label={props.data?.id}
                  variant="identifier"
                />
              </Tooltip>
            </Stack>
            <Typography fontSize={14} fontWeight={400}>
              Update an existing product.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", width: "580px" }}>
          <Stack spacing={"24px"} sx={{ px: "24px" }}>
            <PFMInput
              label="Product Name"
              placeholder="Name your product..."
              important
              text={name}
              onUpdate={(t) => setName(t)}
              disabled={busy}
            />
            <PFMInput
              label="Description"
              placeholder="Write a short description for this product. It will be shown to the users in the product details screen. "
              multiline
              rows={4}
              text={description}
              onUpdate={(t) => setDescription(t)}
              disabled={busy}
            />
            <input
              id="file-input-media"
              style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
              type="file"
              accept="image/png,image/jpeg,image/jpg"
              onChange={(f) => {
                if (f.target.files && f.target.files?.length > 0) {
                  uploadMedia(f.target.files[0]);
                }
              }}
            />
            <PFMInput
              label="Media (image/video)"
              placeholder="Paste a link to your product video or select an image..."
              text={media}
              onUpdate={(t) => setMedia(t)}
              disabled={busy}
              endAdornment={
                <Button
                  disabled={busy}
                  onClick={() =>
                    document.getElementById("file-input-media")?.click()
                  }
                >
                  Select
                </Button>
              }
            />

            <Stack>
              <PFMCheckbox
                value={generateLicenseKey}
                onUpdate={(t) => setGenerateLicenseKey(t)}
                label="Create license keys"
              />
              {generateLicenseKey && (
                <PFMInput
                  label="Limit"
                  placeholder="0"
                  important
                  text={limit.toString() || ""}
                  onUpdate={(t) => setLimit(parseInt(t || "0"))}
                  disabled={busy}
                  helperText="Number of times a license key can be used. 0 for unlimited."
                />
              )}
            </Stack>
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                Assets
              </Typography>
              <Typography fontSize={12} fontWeight={300}>
                Users can access these files and the url with an active
                subscription of this product.
              </Typography>
              <input
                id="file-input"
                style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
                type="file"
                onChange={(f) => {
                  if (f.target.files && f.target.files?.length > 0) {
                    uploadDeliverable(f.target.files[0]);
                  }
                }}
              />
              <Button
                disabled={busy}
                onClick={() => document.getElementById("file-input")?.click()}
                startIcon={<Add />}
                sx={{ alignSelf: "start" }}
              >
                Add a file
              </Button>

              {files.map((f) => (
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Typography>
                    {f.name}
                    {" - "}
                    <span>{Math.ceil(f.size / 1024)}KB</span>
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setFiles([...files.filter((fx) => fx.url !== f.url)]);
                    }}
                  >
                    <SvgIcon>
                      <IC_CANCEL />
                    </SvgIcon>
                  </IconButton>
                </Stack>
              ))}
              <PFMInput
                label="Link"
                placeholder="https://"
                text={successUrl}
                onUpdate={(t) => setSuccessUrl(t)}
                disabled={busy}
                helperText="Users can access this URL after a successful payment."
              />
            </Stack>

            <PFMInput
              label="Custom message"
              placeholder="Write a short message that is shown below the assets."
              multiline
              rows={3}
              text={successMessage}
              onUpdate={(t) => setSuccessMessage(t)}
              disabled={busy}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={busy}
          onClick={props.closeHandler}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={saveProduct}
          color="primary"
          variant="contained"
          fullWidth
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
