import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ArrowForward, Check, Close, Info } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { aBotStatus, aProfile } from "../../core/states";

import { enqueueSnackbar } from "notistack";
import PFMInput from "../../components/PFMInput";
import { IC_ALERT } from "../../assets/ui";
import {
  rtmGetBotStats,
  rtmGetCommand,
  rtmGetTelegramConfig,
  rtmSetCommand,
  rtmUnsetCommand,
} from "../../core/api/user";
import YouTubePlayer from "react-player/youtube";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";
import { TelegramConfig } from "../../types/TelegramConfig";

export default function OnboardingStepTelegram(props: {
  next: () => void;
  back: () => void;
}) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [connected, setConnected] = useState(false);
  const [token, setToken] = useState("");
  const [botStatus, setBotStatus] = useRecoilState(aBotStatus);
  const [config, setConfig] = useState<TelegramConfig>();

  const instructions = [
    <Typography>Open your telegram app and search for @BotFather.</Typography>,
    <Typography>
      In @BotFather chat, type <span style={{ color: "#4488FF" }}>/newbot</span>
      .
    </Typography>,
    <Typography>
      Type the name for your new bot in the chat and send it.
    </Typography>,
    <Typography>
      Now type the username for your bot. Make sure it ends with '
      <span style={{ color: "#4488FF" }}>bot</span>'. This is required by
      telegram.
    </Typography>,
    <Typography>
      If the username is available, you will get a unique bot token. Click on it
      to copy it.
    </Typography>,
    <Typography>
      Enter the bot token you copied in to the text field below, and click
      <span style={{ color: "#4488FF" }}> Connect</span>.
    </Typography>,

    <Typography>
      Once connected, add the bot to your channels and groups that you want to
      monetize, and make sure the bot is admin (
      <span style={{ color: "#F33A" }}>important!</span>).
    </Typography>,
  ];

  async function load() {
    try {
      // Get Bot Status
      const stats = await rtmGetBotStats();
      setBotStatus(stats);
      // Load existing configuratio
      const cfg = await rtmGetTelegramConfig();
      if (cfg) {
        setConfig(cfg);
        setToken(cfg.token);
        if (Boolean(cfg.token)) setConnected(true);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading telegram bot stats from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
  }

  async function setupTelegram() {
    try {
      // Create profile
      setBusy(true);
      // Set the command
      await rtmSetCommand("init_telegram", token);
      // Wait for command to be finished.
      while (true) {
        const cmd = await rtmGetCommand();
        if (cmd.result?.success) {
          // command succeeded
          setConnected(true);
          // Get the new cfg
          await rtmUnsetCommand();
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      enqueueSnackbar("Connected with the bot. ", {
        variant: "success",
      });
      logEvent({
        name: "connected bot",
        description: "User connected telegram bot successfully.",
        identifier: profile?.uid,
      });
      load();
      // Set telegram
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error updating data. Please try again later. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error setting up telegram bot from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  async function disconnectTelegram() {
    try {
      // Create profile
      setBusy(true);
      // Set the command
      await rtmSetCommand("reset_telegram", token);
      // Wait for command to be finished.
      while (true) {
        const cmd = await rtmGetCommand();
        if (cmd.result?.success) {
          // command succeeded
          setConnected(false);
          setConfig(undefined);
          // Get the new cfg
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      enqueueSnackbar("The bot has been disconnected. ", {
        variant: "success",
      });
      load();
      logEvent({
        name: "disconnect bot",
        description: "User disconnected telegram bot.",
        identifier: profile?.uid,
      });
      // Set telegram
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error disconnecting bot. Please try again later. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error disconnecting telegram bot from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();

    let _isIntervalBusy = false;
    let timer = setInterval(async () => {
      // Load stats of bot.
      if (_isIntervalBusy) return;
      _isIntervalBusy = true;
      try {
        const stats = await rtmGetBotStats();
        setBotStatus(stats);
      } catch (err: any) {}
      _isIntervalBusy = false;
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Setting up Telegram
      </Typography>
      <Typography sx={{ my: "8px" }}>
        To get most out of <span style={{ color: "#4488FF" }}>Cashifybot</span>,
        we need to connect your telegram bot with our platform.
      </Typography>
      <Typography>
        <span style={{ color: "#4488FF" }}>Cashifybot</span> uses Telegram Bot
        API to provide its services to the end users, and our customers. <br />
        Your bot is the unique identity of your Cashifybot platform. <br />
        If you do not have a bot set up already, please follow the instructions
        given below.
      </Typography>

      <Stack sx={{ mt: "16px" }} spacing={"8px"}>
        {instructions.map((w, i) => (
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography
              sx={{
                background: "#4488FF",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50px",
              }}
            >
              {i + 1}
            </Typography>
            <ArrowForward />
            {w}
          </Stack>
        ))}
      </Stack>
      <Typography fontSize={22} sx={{ mt: "32px" }}>
        Video Tutorial
      </Typography>
      <Typography sx={{ my: "8px" }}>
        Watch the video below to learn how to obtain a bot token.
      </Typography>
      <YouTubePlayer
        style={{ flex: 1, minHeight: "300px" }}
        controls
        url={"https://youtu.be/XVUyzBirhiY"}
      />
      <PFMInput
        disabled={connected}
        text={token}
        onUpdate={(t) => setToken(t)}
        label="Bot Token"
        important
        placeholder="Paste your bot token..."
        sx={{ width: "500px", mt: "32px" }}
      />
      {config?.token && (
        <Stack
          direction={"row"}
          spacing={"8px"}
          alignItems={"center"}
          sx={{ my: "8px" }}
        >
          <Check color="primary" />
          <Typography>Connected: </Typography>
          <Typography color={"primary"}>@{config.username} </Typography>
          <IconButton
            title="Disconnect"
            onClick={disconnectTelegram}
            disabled={busy}
          >
            <Close />
          </IconButton>
        </Stack>
      )}
      <Stack
        direction={"row"}
        spacing="8px"
        sx={{ my: "8px" }}
        alignItems={"center"}
      >
        <Button
          disabled={busy}
          onClick={props.back}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Back
        </Button>
        {!connected && (
          <Button
            disabled={busy || !token || botStatus?.status !== "running"}
            onClick={setupTelegram}
            variant="contained"
            sx={{ alignSelf: "start", gap: "8px" }}
          >
            {botStatus?.status === "pending" && (
              <>
                Provisioning, please wait...
                <CircularProgress size={"24px"} />
              </>
            )}
            {botStatus?.status !== "pending" && <>Connect</>}
          </Button>
        )}
        {busy && <CircularProgress size={"35px"} />}
        {!busy && (connected || config?.token) && (
          <Button
            disabled={busy}
            onClick={props.next}
            variant="contained"
            sx={{ alignSelf: "start" }}
          >
            Next
          </Button>
        )}
      </Stack>
      {(botStatus?.status === "failed" ||
        botStatus?.status === "unknown" ||
        botStatus?.status === "exited") && (
        <Stack direction={"row"}>
          <Alert
            color="error"
            icon={
              <SvgIcon>
                <IC_ALERT></IC_ALERT>
              </SvgIcon>
            }
          >
            <AlertTitle>Error Starting Bot</AlertTitle>
            <Typography>
              Failed to start a new bot instance for your account. Please
              contact administrators.
            </Typography>
          </Alert>
        </Stack>
      )}
    </Stack>
  );
}
