import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DualHeading from "../../components/DualHeading";
import { ExpandMoreRounded } from "@mui/icons-material";
export default function Faq() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="faq"
      sx={{
        bgcolor: "#111A",
        py: "48px",
        px: "10%",
      }}
    >
      <Stack
        direction={desktop ? "row" : "column"}
        spacing={desktop ? "70px" : "12px"}
        sx={{ maxWidth: "1280px", width: "100%", alignSelf: "center" }}
      >
        <Stack flex={1} spacing={"4px"}>
          <Typography fontSize={desktop ? 20 : 16}>Support</Typography>
          <DualHeading
            component={"h2"}
            sx={{ fontSize: desktop ? "28px" : "20px" }}
            h1="Frequently Asked"
            h2="Questions"
            h2sx={{ color: "#48F" }}
          />
          <Typography sx={{ py: "8px", fontSize: desktop ? 14 : 12 }}>
            Everything you need to know about the product and billing. Can’t
            find the answer you’re looking for? Please chat to our friendly
            team.
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                Does it automatically remove users when they are no longer
                subscribed?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                Of course! The main purpose of Cashifybot is to restrict access
                to your channels to paid users only. However, you can also allow
                specific people to join without any subscriptions using Access
                Codes.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                How can I migrate my subscribers from existing/other
                subscription bots?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                If you are already using another subscription management bot,
                follow the steps below to easily migrate your subscibers to
                cashifybot.
              </Typography>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                1. Set up a group/channel with Cashifybot. <br />
                2. Create an access code with a 1-month expiration.
                <br /> 3. Give the access code to your subscribers, and instruct
                them to use it with Cashifybot to join your new channel/group.
                <br /> 4. Instruct your subscribers to purchase subscription
                before the code expires with Cashifybot.
                <br /> 5. Unsubscribe everyone from your old subscription
                management.
              </Typography>
              <br />
              <Typography>
                Alternatively, you can also create a subscription with 1 month
                free trial for your existing subscribers.{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                What kind of products can I sell?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                Cashifbot allows you to sell digital assets, including files,
                links, and license keys. It also provides an API for validating
                license keys from your software applications.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                Can I use Cashifybot with multiple channels or groups?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                Of course! Cashifybot enables you to include multiple
                channels/groups in each subscription, allowing you to use
                multiple channels/groups in different subscriptions as well.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                How do I get paid?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                Our customers are paid directly through Stripe Connect, as well
                as through various crypto currency networks, such as Ethereum,
                Bitcoin, and many more.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                What are the payment methods for end-users?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                All payment methods supported by Stripe are available for
                end-users. This includes Credit/Debit Card, Stripe, Pay with
                PayPal, and more. Furthermore, you can also accept payments in
                over 20 + crypto currencies.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                Can I issue refunds to Telegram users?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                No, Cashifybot does not currently support refund functionality.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                Does Cashifybot have a refund policy?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                No, Cashifybot does not have any refund policy.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                What is the minimum payout limit?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                For now, we have set it to $50 due to network fees on Ethereum.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="outlined"
            sx={{
              background: "#FFF0",
              border: "1px solid #FFF0",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography fontSize={desktop ? 18 : 16} fontWeight={500}>
                What content types are allowed for sale?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight={400} fontSize={desktop ? 14 : 12}>
                If you are using Stripe, please refer to Stripe's terms and
                conditions. At Cashifybot, we have a zero-tolerance policy for
                explicit material, including gore, child abuse, and rape porn
                videos. We take these issues very seriously, and any accounts
                found providing such content will be immediately disabled
                without warning.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </Stack>
  );
}
