import React, { useEffect, useState } from "react";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { LOGO_SIDEBAR } from "../../assets/brand";
import LinkButton from "../../components/LinkButton";
import { FbAuth } from "../../authentication/firebase";
import { useNavigate } from "react-router";

export default function Header(props?: { active?: string }) {
  const [stick, setStick] = useState(false);
  const nav = useNavigate();
  const desktop = useMediaQuery("(min-width: 1280px)"); // we add spacings here
  const breakpoint = useMediaQuery("(min-width: 750px)"); // we split nav menu here

  function handleScroll() {
    setStick(Math.ceil(window.scrollY) > 200);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      rowGap={"12px"}
      sx={{
        zIndex: 100,
        py: stick ? "8px" : "24px",
        px: desktop ? 0 : "24px",
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        position: stick ? "sticky" : "unset",
        background: stick ? "#FFF2" : "#FFF0",
        backdropFilter: "blur(4px)",
      }}
      alignItems={"center"}
    >
      <Stack sx={{ maxWidth: "1280px", width: "100%" }} spacing={"12px"}>
        <Stack
          id="header"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <img
            src={LOGO_SIDEBAR}
            alt="cashify logo"
            style={{ height: desktop ? "60px" : "40px" }}
          />
          {breakpoint && (
            <nav
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                rowGap: "0",
                columnGap: 0,
              }}
            >
              <LinkButton
                observable="hero"
                label="Home"
                onClick={() => {
                  if (!window.document.getElementById("hero")) nav("/");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("hero")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                label="Features"
                observable="features"
                onClick={() => {
                  if (!window.document.getElementById("features"))
                    nav("/#features");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("features")
                        ?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                observable="demo"
                label="Demo"
                onClick={() => {
                  if (!window.document.getElementById("demo")) nav("/#demo");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("demo")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                observable="pricing"
                label="Pricing"
                onClick={() => {
                  if (!window.document.getElementById("pricing"))
                    nav("/#pricing");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("pricing")?.offsetTop,
                    });
                  }
                }}
              />

              <LinkButton
                observable="faq"
                label="FAQs"
                onClick={() => {
                  if (!window.document.getElementById("faq")) nav("/#faq");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("faq")?.offsetTop,
                    });
                  }
                }}
              />

              <LinkButton
                observable="contact"
                label="Contact"
                onClick={() => {
                  if (!window.document.getElementById("contact"))
                    nav("/#contact");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("contact")?.offsetTop,
                    });
                  }
                }}
              />
            </nav>
          )}
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            {!FbAuth.currentUser && (
              <>
                {desktop && (
                  <Button
                    onClick={() => nav("/login")}
                    size={desktop ? "large" : "small"}
                    variant="text"
                    sx={{ borderRadius: "25px" }}
                  >
                    Login
                  </Button>
                )}
                <Button
                  onClick={() => nav("/signup")}
                  size={desktop ? "large" : "small"}
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                >
                  Get Started
                </Button>
              </>
            )}
            {FbAuth.currentUser && (
              <>
                <Typography>
                  Signed in as{" "}
                  <span style={{ fontWeight: 500 }}>
                    {FbAuth.currentUser.displayName}
                  </span>
                </Typography>
                <Button
                  onClick={() => nav("/dashboard")}
                  size="large"
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                >
                  Dashboard
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        {!breakpoint && (
          <nav
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <LinkButton
              observable="hero"
              label="Home"
              onClick={() => {
                if (!window.document.getElementById("hero")) nav("/");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("hero")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Features"
              observable="features"
              onClick={() => {
                if (!window.document.getElementById("features"))
                  nav("/#features");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("features")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              observable="demo"
              label="Demo"
              onClick={() => {
                if (!window.document.getElementById("demo")) nav("/#demo");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("demo")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              observable="pricing"
              label="Pricing"
              onClick={() => {
                if (!window.document.getElementById("pricing"))
                  nav("/#pricing");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("pricing")?.offsetTop,
                  });
                }
              }}
            />

            <LinkButton
              observable="faq"
              label="FAQs"
              onClick={() => {
                if (!window.document.getElementById("faq")) nav("/#faq");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("faq")?.offsetTop,
                  });
                }
              }}
            />

            <LinkButton
              observable="contact"
              label="Contact"
              onClick={() => {
                if (!window.document.getElementById("contact"))
                  nav("/#contact");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("contact")?.offsetTop,
                  });
                }
              }}
            />
          </nav>
        )}
      </Stack>
    </Stack>
  );
}
