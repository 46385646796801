import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useModal } from "mui-modal-provider";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "../../components/LoadingOverlay";
import {
  Avatar,
  Button,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ArrowForward, Done, Lock } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import OnboardingStepWelcome from "./welcome";
import OnboardingStepBilling from "./billing";
import OnboardingStepTelegram from "./telegram";
import OnboardingStepSubscription from "./subscription";
import OnboardingStepFinish from "./explore";
import { LOGO_SIDEBAR } from "../../assets/brand";
import OnboardingStepCrypto from "./crypto";
import { rtmGetProfile } from "../../core/api";
import {
  rtmGetStripeProfile,
  rtmSetOnboardingState,
} from "../../core/api/user";
import OnboardingStepCurrency from "./currency";
import OnboardingStepPersonalize from "./personalization";
import { FbAuth } from "../../authentication/firebase";
import { signOut } from "firebase/auth";
import { IC_LOGOUT } from "../../assets/ui";

export default function PageOnboarding() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setStripeProfile] = useState<any>();
  const [step, setStep] = useState(0);

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      if (prof) {
        setStep(prof.onboardingState || 0);
      }
      if (prof.business?.stripe?.accountId) {
        // Get stripe account
        const str = await rtmGetStripeProfile();
        if (str) {
          setStripeProfile(str);
        }
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading profile. Please try again. ", {
        variant: "error",
      });
    }
  }

  function goNextStep() {
    setStep(step + 1);
    // If user's current step is lower than this, we set it in profile.
    if (!profile?.onboardingState || profile.onboardingState <= step) {
      // Set user's step
      rtmSetOnboardingState(step + 1);
    }
  }

  function goBackStep() {
    setStep(step - 1);
  }

  useEffect(() => {
    loadProfile();
  }, []);

  if (!profile) {
    return <LoadingOverlay busy />;
  }

  if (profile.boarded) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Stack flex={1} sx={{ height: "100vh", p: "12px" }} direction={"row"}>
      {/* A sidebar that shows checklist or steps of the onboarding flow  */}
      <Stack
        sx={{
          width: "280px",
          px: "20px",
          py: "32px",
          m: "12px",
          background: "#FFFFFF13",
          borderRadius: "12px",
        }}
      >
        {/* App Logo  */}
        <Stack direction={"row"} justifyContent={"center"}>
          <img src={LOGO_SIDEBAR} alt="PFM Logo" style={{ height: "40px" }} />
        </Stack>
        <Stack
          spacing={"16px"}
          sx={{
            py: "32px",
            "& .MuiButton-containedPrimary": {
              borderRadius: "50px",
              alignItems: "center",
              justifyContent: "flex-start",
              pointerEvents: "none",
            },
            "& .MuiButton-containedPrimary:hover": {
              background: "rgba(124,172,248,0.08)",
            },
          }}
        >
          <Button
            variant="contained"
            disabled={step > 0}
            startIcon={step === 0 ? <ArrowForward /> : <Done />}
            sx={{
              background: step === 0 ? "#4488FF" : "#4488FF99",
            }}
          >
            Welcome
          </Button>

          <Button
            variant="contained"
            disabled={step !== 1}
            startIcon={
              step === 1 ? <ArrowForward /> : step > 1 ? <Done /> : <Lock />
            }
            sx={{
              background:
                step === 1 ? "#4488FF" : step > 1 ? "transparent" : "#4488FF99",
            }}
          >
            Payments: Currency
          </Button>

          <Button
            variant="contained"
            disabled={step !== 2}
            startIcon={
              step === 2 ? <ArrowForward /> : step > 2 ? <Done /> : <Lock />
            }
            sx={{
              background:
                step === 2 ? "#4488FF" : step > 2 ? "transparent" : "#4488FF99",
            }}
          >
            Payments: Stripe
          </Button>

          <Button
            variant="contained"
            disabled={step !== 3}
            startIcon={
              step === 3 ? <ArrowForward /> : step > 3 ? <Done /> : <Lock />
            }
            sx={{
              background:
                step === 3 ? "#4488FF" : step > 3 ? "transparent" : "#4488FF99",
            }}
          >
            Payments: Crypto
          </Button>

          <Button
            variant="contained"
            disabled={step !== 4}
            startIcon={
              step === 4 ? <ArrowForward /> : step > 4 ? <Done /> : <Lock />
            }
            sx={{
              background:
                step === 4 ? "#4488FF" : step > 4 ? "transparent" : "#4488FF99",
            }}
          >
            Setting up Telegram
          </Button>

          <Button
            variant="contained"
            disabled={step !== 5}
            startIcon={
              step === 5 ? <ArrowForward /> : step > 5 ? <Done /> : <Lock />
            }
            sx={{
              background:
                step === 5 ? "#4488FF" : step > 5 ? "transparent" : "#4488FF99",
            }}
          >
            Telegram: Personalization
          </Button>

          <Button
            variant="contained"
            disabled={step !== 6}
            startIcon={
              step === 6 ? <ArrowForward /> : step > 6 ? <Done /> : <Lock />
            }
            sx={{
              background:
                step === 6 ? "#4488FF" : step > 6 ? "transparent" : "#4488FF99",
            }}
          >
            Explore Dashboard
          </Button>
        </Stack>
        <Stack flex={1} />
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing="12px"
          alignSelf={"end"}
        >
          <Avatar
            sx={{ width: "40px", height: "40px", color: "white" }}
            src={FbAuth.currentUser?.photoURL || ""}
          />
          <Stack flex={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#CECFD2",
                maxWidth: "160px",
                wordWrap: "unset",
                overflowWrap: "anywhere",
              }}
            >
              {profile?.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#94969C",
                wordBreak: "break-word",
              }}
            >
              {profile?.email}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              signOut(FbAuth);
              window.location.reload();
            }}
            title="Sign out"
          >
            <SvgIcon>
              <IC_LOGOUT />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>

      {/* The section container that shows the active onboarding step  */}
      <Stack flex={1} sx={{ py: "32px", px: "16px", overflow: "auto" }}>
        {step === 0 && <OnboardingStepWelcome next={goNextStep} />}
        {step === 1 && (
          <OnboardingStepCurrency next={goNextStep} back={goBackStep} />
        )}
        {step === 2 && (
          <OnboardingStepBilling next={goNextStep} back={goBackStep} />
        )}
        {step === 3 && (
          <OnboardingStepCrypto next={goNextStep} back={goBackStep} />
        )}
        {step === 4 && (
          <OnboardingStepTelegram next={goNextStep} back={goBackStep} />
        )}
        {step === 5 && (
          <OnboardingStepPersonalize next={goNextStep} back={goBackStep} />
        )}
        {step === 6 && <OnboardingStepFinish next={goNextStep} />}
      </Stack>
    </Stack>
  );
}
