import React, { useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import DualHeading from "../../components/DualHeading";
import { PFMSwitch } from "../../components/PFMSwitch";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
export default function Migration() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const theme = useTheme();
  const [method, setMethod] = useState<"code" | "trial">("code");

  return (
    <Stack
      id="migrate"
      alignItems={"center"}
      spacing={"32px"}
      sx={{ py: "48px" }}
    >
      <DualHeading
        component={"h2"}
        sx={{
          fontSize: desktop ? "36px" : "24px",
        }}
        h1="Migrating to"
        h2="Cashifybot"
        h2sx={{ color: "#48F" }}
      />
      <Typography
        fontSize={desktop ? 20 : 14}
        textAlign={"center"}
        sx={{ maxWidth: "70%" }}
      >
        Want to move from existing subscription management to Cashifybot? <br />
        Follow the steps below to easily transfer your old subscribers to
        Cashifybot.
      </Typography>
      <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
        <Typography color={method === "code" ? "secondary" : "inherit"}>
          Using Access Codes
        </Typography>
        <PFMSwitch
          checked={method === "trial"}
          onChange={(e) => setMethod(e.target.checked ? "trial" : "code")}
        />
        <Typography color={method === "trial" ? "secondary" : "inherit"}>
          Using Trial Subscriptions
        </Typography>
      </Stack>
      <AnimatePresence mode="popLayout">
        {method === "code" && (
          <motion.div
            key={1}
            initial={{ transform: "translateY(1000px)" }}
            animate={{ transform: "translateY(0px)" }}
            exit={{ transform: "translateY(500px)" }}
          >
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
                maxWidth: "900px",
              }}
            >
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      minWidth: "120px",
                    },
                  }}
                >
                  Signup
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector sx={{ borderRadius: "18px" }} />
                </TimelineSeparator>
                <TimelineContent>
                  Create a new account and complete the onboarding process.{" "}
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                      minWidth: "120px",
                    },
                  }}
                >
                  Connect with Telegram
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Create a new bot using @BotFather, connect your bot with
                  Cashifybot, and add the bot to your desired channel or group
                  with admin permissions.
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                      minWidth: "120px",
                    },
                  }}
                >
                  Create an Access Code
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Go to Tools &gt; Access Codes, and create a new access code
                  with trial period. Make sure to select your desired chat
                  (channel or group) that you want the members to join.
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                      minWidth: "120px",
                    },
                  }}
                >
                  Send Invitations
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Send the access code to your existing members in the old chat,
                  along with the bot's username. Access code can be used in the
                  bot menu.
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 10,
                      minWidth: "120px",
                    },
                  }}
                >
                  Create Subscriptions
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary"></TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  Create the subscription plans for users to subscribe to your
                  channel again through Cashifybot before the access code
                  expires.
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </motion.div>
        )}

        {method === "trial" && (
          <motion.div
            key={2}
            initial={{ transform: "translateY(1000px)" }}
            animate={{ transform: "translateY(0px)" }}
            exit={{ transform: "translateY(500px)" }}
          >
            <Timeline
              sx={{
                maxWidth: "900px",
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            >
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      minWidth: "120px",
                    },
                  }}
                >
                  Signup
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector sx={{ borderRadius: "18px" }} />
                </TimelineSeparator>
                <TimelineContent>
                  Create a new account and complete the onboarding process with
                  Stripe Connect.
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      minWidth: "120px",
                    },
                  }}
                >
                  Connect with Telegram
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Create a new bot using @BotFather, connect your bot with
                  Cashifybot, and add the bot to your desired channel or group
                  with admin permissions.
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      minWidth: "120px",
                    },
                  }}
                >
                  Create Subscription
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  Go to Store &gt; Subscriptions, and create a new subscriptions
                  with trial period. Make sure to select your desired chat
                  (channel or group) that you want the members to join.
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  variant="button"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      minWidth: "120px",
                    },
                  }}
                >
                  Send Invitations
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                </TimelineSeparator>
                <TimelineContent>
                  Invite your existing members to subscribe to your channel via
                  Cashifybot, using your own bot's username. Users will get
                  trial access to your channels and they will be automatically
                  charged when their trial ends.
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
}
