import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import { IC_REFRESH } from "../../assets/ui";
import { rtmGetWallets } from "../../core/api/admin";

export default function SectionAdminWallets() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<
    {
      name: string;
      available: string;
      pending: string;
      currency: string;
      id: string;
    }[]
  >([]);

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _wallets = await rtmGetWallets();
      setData(_wallets);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Wallets`);
    setSectionDescription(`View balances in wallets on cryptochill`);

    load();
  }, []);

  useEffect(() => {
    load();
  }, []);

  function RenderWallet(w: {
    name: string;
    id: string;
    available: string;
    pending: string;
    currency: string;
  }) {
    return (
      <TableRow
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(w.id)}
          >
            {w.id}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography fontSize={14} fontWeight={500}>
            {w.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={14} fontWeight={500}>
            {w.currency}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={14} fontWeight={500}>
            {w.pending}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography fontSize={14} fontWeight={500}>
            {w.available}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        title="Wallets"
        description="List of all the wallets on cryptochill."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => load()}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Name</Typography>
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Currency</Typography>
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Pending</Typography>
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Available</Typography>
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderWallet(d))}</>}
      />
    </Stack>
  );
}
