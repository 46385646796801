import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { aProfile } from "../../core/states";
import { enqueueSnackbar } from "notistack";
import {
  rtmCreateStripeAccount,
  rtmGetStripeProfile,
} from "../../core/api/user";
import { logEvent, logMessage } from "@zexcore/sdk";
import { LogMessageKind } from "@zexcore/types";

export default function OnboardingStepBilling(props: {
  next: () => void;
  back: () => void;
}) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [stripeProfile, setStripeProfile] = useState<any>();
  const [busy, setBusy] = useState(false);

  async function loadProfile() {
    try {
      if (profile?.business?.stripe?.accountId) {
        // Get stripe account
        const str = await rtmGetStripeProfile();
        if (str) {
          setStripeProfile(str);
        }
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading profile. Please try again. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error loading user's stripe profile in billing section. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
  }

  async function createAccount() {
    try {
      // Create profile
      setBusy(true);
      const resp = await rtmCreateStripeAccount();
      if (resp) {
        window.open(resp, "_blank");
        loadProfile();
        logEvent({
          name: "create stripe",
          description: "Stripe connect account creation button. ",
          identifier: profile?.uid,
        });
      } else {
        enqueueSnackbar("The server did not sent any data. ", {
          variant: "error",
        });
        console.error(
          "Error creating stripe account for user. Server returned empty response."
        );
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error creating account. Please try again later. ", {
        variant: "error",
      });
      logMessage({
        kind: LogMessageKind.Error,
        message: "Error creating stripe connect account from onboarding. ",
        stack: err,
        tags: ["onboarding"],
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack>
      <Typography fontSize={24} fontWeight={400}>
        Onboarding
      </Typography>
      <Typography fontSize={32} fontWeight={600}>
        Setting up Stripe Connect
      </Typography>
      <Typography sx={{ my: "8px" }}>
        Setup your stripe connected account to accept credit/debit card payments
        from over 40+ countries.
      </Typography>
      <Typography>
        Cashifybot uses Stripe to get you paid quickly and keep your personal
        and payment information secure. <br />
        Thousands of companies around the world trust Stripe to process payments
        for their users. <br />
        Set up a Stripe account to get paid with Cashifybot.
      </Typography>
      <Stack direction={"row"} spacing="8px" sx={{ mt: "32px" }}>
        <Button
          disabled={busy}
          onClick={props.back}
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "start" }}
        >
          Back
        </Button>
        {!stripeProfile && (
          <Button
            disabled={busy}
            onClick={props.next}
            variant="contained"
            color="secondary"
            sx={{ alignSelf: "start" }}
          >
            Skip
          </Button>
        )}
        <Button
          disabled={busy}
          onClick={createAccount}
          variant="contained"
          color={stripeProfile ? "secondary" : "primary"}
          sx={{ alignSelf: "start", mt: "32px" }}
        >
          {stripeProfile ? "Update Information" : "Create Account"}
        </Button>

        {stripeProfile && (
          <Button
            disabled={busy}
            onClick={props.next}
            variant="contained"
            sx={{ alignSelf: "start", mt: "32px" }}
          >
            Next
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
