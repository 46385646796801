const debug = {
  version: "2024.8.7",
  build: "#103",
  api: "http://localhost:3001",
  rtm: "ws://localhost:3001",
  firebaseConfig: {
    apiKey: "AIzaSyAvKJLrUmfSQoHWG96PH3lUCyOMODD1sqA",
    authDomain: "cashify-api.firebaseapp.com",
    projectId: "cashify-api",
    storageBucket: "cashify-api.appspot.com",
    messagingSenderId: "30506682345",
    appId: "1:30506682345:web:99b2d9def26f5ea0b62d78",
    measurementId: "G-B7K9QK7H0X",
  },
  zexcore: {
    apiKey: "1ab68e2098e58075acced7ee8c1c63d04ce6cf781bee56486b",
    projectId: "452121152165253120",
    endpoint: "ws://localhost:80",
  },
  discord: {
    webhook:
      "https://discord.com/api/webhooks/1195002258824364122/iL7JQmnY_716f8ssH1P7Lp87aIt59wHMvZiJ4patMX4fQsinqxMA99V65FuK2eb5uNvp",
  },
};

const prod = {
  version: "2024.9.3",
  build: "#114",
  api: "https://api.cashifybot.com",
  rtm: "wss://api.cashifybot.com",
  firebaseConfig: {
    apiKey: "AIzaSyDghq8hDI_o4A9yKVN_xNKQROSS_I0PKJ4",
    authDomain: "cashifybot-production.firebaseapp.com",
    projectId: "cashifybot-production",
    storageBucket: "cashifybot-production.appspot.com",
    messagingSenderId: "497032393056",
    appId: "1:497032393056:web:cf4b0ec970f5fb01f4ee05",
    measurementId: "G-WD2LCYM9Y9",
  },
  zexcore: {
    apiKey: "337b342f76baa27b2b066dcefe6b3889dea6884bab578919d6",
    projectId: "465153977470685184",
    endpoint: "wss://core-api.zexware.com",
  },
  discord: {
    webhook:
      "https://discord.com/api/webhooks/1195002258824364122/iL7JQmnY_716f8ssH1P7Lp87aIt59wHMvZiJ4patMX4fQsinqxMA99V65FuK2eb5uNvp",
  },
};

const DEBUG = process.env.REACT_APP_DEBUG === "true";

export const AppConfig = DEBUG ? debug : prod;
