import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { IC_ALERT, IC_PAID } from "../assets/ui";

import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import { rtmGetAffiliateProfile, rtmRequestPayout } from "../core/api/user";
import { rtmGetProfile } from "../core/api";
import { Affiliate } from "../types/Affiliation/Affiliate";

export default function RequestPayoutDialog(
  props: DialogProps<{ account: "affiliation" | "primary" }>
) {
  const [affiliate, setAffiliate] = useState<Affiliate>();
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const theme = useTheme();

  async function confirm() {
    try {
      setBusy(true);
      const r = await rtmRequestPayout(props.data?.account!);
      if (r) {
        enqueueSnackbar("Request submitted successfully!", {
          variant: "success",
        });
        props.closeHandler(true);
      } else {
        enqueueSnackbar("Error processing your request. ", {
          variant: "error",
        });
      }
    } catch (err: any) {
      enqueueSnackbar("Error requesting payout. Please try later.", {
        variant: "error",
      });
      console.error("Error requesting payout. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      const prof = await rtmGetProfile();
      const aff = await rtmGetAffiliateProfile();
      if (aff) {
        setAffiliate(aff);
      }
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try later. ", {
        variant: "error",
      });
      console.error("Error loading payout dialog. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <SvgIcon>
            <IC_PAID />
          </SvgIcon>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Confirm Payout
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Confirm the payout request to withdraw your funds.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="16px" sx={{ p: "32px", pb: "32px" }}>
          {/* Here we show the two custom radios with Stripe Connect, and Cryptocurrency  */}
          <Typography>Please verify your payout details below.</Typography>
          <Stack
            spacing="8px"
            sx={{
              transition: "all .3s",
              cursor: "pointer",
              py: "12px",
              px: "10px",
              borderRadius: "8px",
              border: `2px solid ${theme.palette.primary.main}`,
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing="8px"
              sx={{ width: "100%" }}
            >
              <Typography>Network: </Typography>
              <Typography color={"primary"}>
                {profile?.business?.crypto?.network?.toUpperCase()}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing="8px"
              sx={{ width: "100%" }}
            >
              <Typography>Currency: </Typography>
              <Typography color={"primary"}>
                {profile?.business?.crypto?.currency?.toUpperCase()}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing="8px"
              sx={{ width: "100%" }}
            >
              <Typography>Wallet address: </Typography>
              <Typography color={"primary"}>
                {profile?.business?.crypto?.wallet?.toUpperCase()}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing="8px"
              sx={{ width: "100%" }}
            >
              <Typography>Amount: </Typography>
              <Typography color={"primary"}>
                {getCurrencySymbol(
                  props.data?.account === "primary" ? profile?.currency! : "usd"
                )}
                {props.data?.account === "affiliation"
                  ? `${parseStripeAmount(affiliate?.balance || 0).dollars}.${
                      parseStripeAmount(affiliate?.balance || 0).cents
                    }`
                  : `${
                      parseStripeAmount(profile?.business?.crypto?.balance || 0)
                        .dollars
                    }.${
                      parseStripeAmount(profile?.business?.crypto?.balance || 0)
                        .cents
                    }`}
              </Typography>
            </Stack>

            {/* The info will be used from profile if exists, or the user will be asked to enter it.  */}
            <Alert color="warning" icon={<IC_ALERT />}>
              <AlertTitle>Note</AlertTitle>
              <Typography>
                Network fees will be deducted from your final payout.
              </Typography>
            </Alert>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={"row"}
          sx={{ width: "100%", px: "32px" }}
          spacing="12px"
        >
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={busy}
            size="large"
            fullWidth
            onClick={confirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
