import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import { useNavigate } from "react-router";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import { LOGO_SIDEBAR } from "../../assets/brand";
import { IC_ALERT, IC_EMAIL, IC_PASSWORD } from "../../assets/ui";
import { Player } from "@lottiefiles/react-lottie-player";

export default function PageLogin() {
  const [password, setPassword] = useState("");
  const desktop = useMediaQuery("(min-width: 700px)");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  async function signin(fe: any) {
    fe?.preventDefault?.();
    setError("");
    setBusy(false);
    // Make sure all fields are valid...
    if (!email) {
      setError("email");
      return;
    }
    if (!password) {
      setError("password");
      return;
    }
    try {
      setBusy(true);
      // Sign in using email and pass
      const r = await signInWithEmailAndPassword(FbAuth, email, password);
      if (r && !r.user.emailVerified) {
        // Don't login.
        enqueueSnackbar(
          "Please confirm your account by clicking the link sent to your email.",
          { variant: "error" }
        );
        await signOut(FbAuth);
      } else {
        navigate("/dashboard");
      }
    } catch (err: any) {
      if (
        err.code &&
        (err.code === "auth/wrong-password" ||
          err.code === "auth/user-not-found" ||
          err.code === "auth/invalid-login-credentials")
      ) {
        enqueueSnackbar("Invalid username or password. Please try again.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error logging in. Please contact administrators.", {
          variant: "error",
        });
        console.log(err);
      }
    }
    setBusy(false);
  }

  useEffect(() => {
    if (FbAuth.currentUser) {
      // Navigate to /
      navigate("/dashboard");
    }
  }, []);

  if (!desktop) {
    return (
      <Stack sx={{ minHeight: "100vh", py: "12px" }}>
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <img
            style={{
              height: "64px",
              objectFit: "contain",
            }}
            alt="Cashify Logo"
            onClick={() => window.open("/")}
            src={LOGO_SIDEBAR}
          />
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"20px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Sign in to your account
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ fontSize: "14px", color: "#94969C" }}
            >
              Enter your details to sign in
            </Typography>
          </Stack>
          <Stack
            spacing="8px"
            sx={{ mt: "24px", width: "90%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <PFMInput
              text={email}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              placeholder="Enter your email "
              startIcon={IC_EMAIL}
              errorText={error === "email" ? "Invalid email address." : ""}
            />
            <PFMInput
              password
              text={password}
              onUpdate={(t) => setPassword(t)}
              label="Password"
              important
              placeholder="Enter your password"
              startIcon={IC_PASSWORD}
              errorText={error === "password" ? "Invalid password." : ""}
              onReturn={signin}
            />
            <Stack spacing={"2px"} sx={{ alignSelf: "start" }}>
              <Link href="/recovery" underline="none">
                Forgot your password?
              </Link>
            </Stack>
            <Button
              onClick={signin}
              disabled={busy}
              size="large"
              type="submit"
              sx={{ mt: "4px", height: "42px" }}
              variant="contained"
            >
              {busy && (
                <Player
                  autoplay
                  loop
                  src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                  style={{ height: "100px" }}
                />
              )}
              {!busy && "Sign in"}
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Don't have an account?
              </Typography>
              <Link fontSize={"14px"} href="/signup" underline="none">
                Sign up
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ minHeight: "100vh", transition: "all .2s", opacity: 1 }}>
      <img
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "100px",
        }}
        alt="Cashify Logo"
        src={LOGO_SIDEBAR}
      />
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
          <Typography
            fontSize={"30px"}
            fontWeight={"600"}
            sx={{ color: "#F5F5F6" }}
          >
            Sign in to your account
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
            Enter your details to sign in
          </Typography>
        </Stack>
        <FormGroup onSubmit={signin}>
          <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
            <PFMInput
              text={email}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              placeholder="Enter your email "
              startIcon={IC_EMAIL}
              errorText={error === "email" ? "Invalid email address." : ""}
            />
            <PFMInput
              password
              text={password}
              onUpdate={(t) => setPassword(t)}
              label="Password"
              important
              placeholder="Enter your password"
              startIcon={IC_PASSWORD}
              errorText={error === "password" ? "Invalid password." : ""}
              onReturn={signin}
            />
            <Stack spacing={"2px"}>
              <Link href="/recovery" underline="none">
                Forgot your password?
              </Link>
            </Stack>
            <Button
              onClick={signin}
              disabled={busy}
              size="large"
              type="submit"
              sx={{ mt: "4px", height: "42px" }}
              variant="contained"
            >
              {busy && (
                <Player
                  autoplay
                  loop
                  src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                  style={{ height: "100px" }}
                />
              )}
              {!busy && "Sign in"}
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Don't have an account?
              </Typography>
              <Link fontSize={"14px"} href="/signup" underline="none">
                Sign up
              </Link>
            </Stack>
          </Stack>
        </FormGroup>
      </Stack>
    </Stack>
  );
}
