import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import PFMCheckbox from "../../components/PFMCheckbox";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FbAnalytics, FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useModal } from "mui-modal-provider";
import { RestPostSignup } from "../../core/rest";
import { LOGO_SIDEBAR } from "../../assets/brand";
import {
  IC_EMAIL,
  IC_LINK,
  IC_PASSWORD,
  IC_UESR as IC_USER,
} from "../../assets/ui";
import { getCookie, setCookie } from "../../core/helper";
import { Player } from "@lottiefiles/react-lottie-player";
import ReactPixel from "react-facebook-pixel";

export default function PageSignup() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [created, setCreated] = useState(false);
  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);

  const loc = useLocation(); // We get the referral code and save it in storage in case user does not type it.
  const parms = new URLSearchParams(loc.search);
  const _referrerCode = parms.get("r")?.toString() || getCookie("referrer");
  if (_referrerCode) {
    // We save it in cookies/storage
    setCookie("referrer", _referrerCode, 7);
  }
  const [referralCode, setReferralCode] = useState(_referrerCode);

  const theme = useTheme();
  const navigate = useNavigate();
  const { showModal } = useModal();

  async function signup(fe: any) {
    fe?.preventDefault?.();
    setError("");
    // Make sure all fields are valid...
    if (!name) {
      setError("name");
      return;
    }
    if (!email) {
      setError("email");
      return;
    }
    if (!password || !passwordHasEnoughCharacters || !passwordHasSymbol) {
      setError("password");
      return;
    }

    await continueSignup();
  }

  async function continueSignup() {
    setBusy(false);
    try {
      setBusy(true);
      // Create account on firebase.
      const acc = await createUserWithEmailAndPassword(FbAuth, email, password);
      if (acc.user) {
        // Update the user id
        await updateProfile(acc.user, {
          displayName: name,
        });
        await RestPostSignup(referralCode || _referrerCode);
        // Prompt for verification.
        await signOut(FbAuth);
        setCreated(true);
        enqueueSnackbar("Please check your email to confirm your account.");
        // Get src cookie
        const _trafficSrc = getCookie("traffic_utm_");
        if (_trafficSrc === "facebook") {
          ReactPixel?.track?.("Lead");
        } else if (_trafficSrc === "tiktok") {
          (window as any).ttq?.identify?.({});
          (window as any).ttq?.track?.("CompleteRegistration", {
            contents: [{ content_name: "Sign up" }],
            description: "User signed up on Cashifybot.",
          });
        } else if (_trafficSrc === "google") {
          (window as any).gtag?.("event", "conversion", {
            send_to: "AW-394810679/eb3hCK60t8gZELeqobwB",
          });
        } else if (_trafficSrc === "reddit") {
          (window as any).rdt?.("track", "SignUp");
        }
      }
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/weak-password") {
          enqueueSnackbar("Password must be atleast 6 characters long.", {
            variant: "error",
          });
        } else if (err.code === "auth/email-already-in-use") {
          // 2. Try signing in. If we succeed, we then re-send email verification. Otherwise, we login the user.
          try {
            const creds = await signInWithEmailAndPassword(
              FbAuth,
              email,
              password
            );
            if (creds && creds.user.emailVerified) {
              // Login
              navigate("/dashboard");
            } else if (creds && !creds.user.emailVerified) {
              // Send verification email
              await RestPostSignup(referralCode || _referrerCode);
              signOut(FbAuth);
              setCreated(true);
              enqueueSnackbar(
                "Please check your email to confirm your account."
              );
            }
          } catch (err: any) {
            // Invalid password?
            signOut(FbAuth);
            if (err.code && err.code === "auth/internal-error") {
              enqueueSnackbar("Too many attempts, please try again later. ", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(
                "Error sending confirmation email. Please try again in a few minutes.",
                { variant: "error" }
              );
            }
          }
        } else if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many tries. Please try again later. ", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            "Error signing up. Please try again or contact administrators.",
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar(
          "Unknown error signing up. Please try again or contact administrators.",
          { variant: "error" }
        );
      }
    }
    setBusy(false);
    await signOut(FbAuth);
  }

  useEffect(() => {
    if (FbAuth.currentUser) {
      // Navigate to /
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  if (!desktop) {
    return (
      <Stack sx={{ minHeight: "100vh", py: "12px" }}>
        <img
          style={{
            height: "64px",
            objectFit: "contain",
          }}
          alt="Cashify Logo"
          onClick={() => window.open("/")}
          src={LOGO_SIDEBAR}
        />
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"20px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Create Your Account
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ fontSize: "14px", color: "#94969C" }}
            >
              Ready to get started? Create your account now.
            </Typography>
          </Stack>
          <Stack
            spacing="8px"
            sx={{ mt: "24px", width: "90%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <PFMInput
              text={name}
              label="Name"
              errorText={error === "name" ? "Please enter your name." : ""}
              onUpdate={(t) => setName(t)}
              important
              disabled={busy}
              placeholder="Enter your full name"
              startIcon={IC_USER}
            />
            <PFMInput
              text={email}
              errorText={error === "email" ? "Please enter your email." : ""}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              disabled={busy}
              placeholder="Enter your email"
              startIcon={IC_EMAIL}
            />

            {/* <PFMInput
              text={referralCode}
              disabled={busy}
              onUpdate={(t) => setReferralCode(t)}
              label="Referral code"
              placeholder="Enter your referral code"
              startIcon={IC_LINK}
            /> */}
            <PFMInput
              text={password}
              errorText={
                error === "password" ? "Please enter a valid password." : ""
              }
              onUpdate={(t) => setPassword(t)}
              label="Password"
              disabled={busy}
              important
              password
              placeholder="Create a password"
              startIcon={IC_PASSWORD}
              onReturn={signup}
            />
            <Stack spacing={"2px"} sx={{ alignSelf: "start" }}>
              <PFMCheckbox
                value={passwordHasEnoughCharacters}
                label="Must be at least 8 characters"
              />
              <PFMCheckbox
                value={passwordHasSymbol}
                label="Must contain one special character"
              />
            </Stack>

            <Typography fontSize={10}>
              By signing up, you agree to our friendly{" "}
              <Link href="/privacy">Privacy Policy</Link> &{" "}
              <Link href="/terms">Terms and Conditions</Link>.
            </Typography>

            <Button
              size="large"
              disabled={busy}
              sx={{ mt: "4px", height: "42px" }}
              onClick={signup}
              variant="contained"
            >
              {busy && (
                <Player
                  autoplay
                  loop
                  src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                  style={{ height: "100px" }}
                />
              )}
              {!busy && "Create Account"}
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Already have an account?
              </Typography>
              <Link href="/login" underline="none" fontSize={"14px"}>
                Login
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <img
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "100px",
        }}
        alt="Cashify Logo"
        src={LOGO_SIDEBAR}
      />
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
          <Typography
            fontSize={"30px"}
            fontWeight={"600"}
            sx={{ color: "#F5F5F6" }}
          >
            Create Your Account
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
            Ready to get started? Create your account now.
          </Typography>
        </Stack>
        <FormGroup>
          <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
            <PFMInput
              text={name}
              label="Name"
              errorText={error === "name" ? "Please enter your name." : ""}
              onUpdate={(t) => setName(t)}
              important
              disabled={busy}
              placeholder="Enter your full name"
              startIcon={IC_USER}
            />
            <PFMInput
              text={email}
              errorText={error === "email" ? "Please enter your email." : ""}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              disabled={busy}
              placeholder="Enter your email"
              startIcon={IC_EMAIL}
            />

            {/* <PFMInput
              text={referralCode}
              disabled={busy}
              onUpdate={(t) => setReferralCode(t)}
              label="Referral code"
              placeholder="Enter your referral code"
              startIcon={IC_LINK}
            /> */}
            <PFMInput
              text={password}
              errorText={
                error === "password" ? "Please enter a valid password." : ""
              }
              onUpdate={(t) => setPassword(t)}
              label="Password"
              disabled={busy}
              important
              password
              placeholder="Create a password"
              startIcon={IC_PASSWORD}
              onReturn={signup}
            />
            <Stack spacing={"2px"}>
              <PFMCheckbox
                value={passwordHasEnoughCharacters}
                label="Must be at least 8 characters"
              />
              <PFMCheckbox
                value={passwordHasSymbol}
                label="Must contain one special character"
              />
            </Stack>

            <Typography fontSize={12}>
              By signing up, you agree to our friendly{" "}
              <Link href="/privacy">Privacy Policy</Link> &{" "}
              <Link href="/terms">Terms and Conditions</Link>.
            </Typography>
            <Button
              size="large"
              disabled={busy}
              sx={{ mt: "4px", height: "42px" }}
              onClick={signup}
              variant="contained"
            >
              {busy && (
                <Player
                  autoplay
                  loop
                  src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                  style={{ height: "100px" }}
                />
              )}
              {!busy && "Create Account"}
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Already have an account?
              </Typography>
              <Link href="/login" underline="none" fontSize={"14px"}>
                Login
              </Link>
            </Stack>
          </Stack>
        </FormGroup>
      </Stack>
    </Stack>
  );
}
